import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/js/all';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';

import history from './history';
import * as serviceWorker from './serviceWorker';
import App from './js/components/App';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter history={history}>
      <Route path="/" component={App} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
