import './CreateBreachForm.css';

import * as React from 'react';
import {
  Form,
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { Multiselect } from 'multiselect-react-dropdown';

import UploadImage from '../UploadImage';

import axios from '../../../utils/axios';

const dataClassList = [
  'Access tokens',
  'Chat logs',
  'Credit cards',
  'Dates of birth',
  'Device information',
  'Email addresses',
  'Ethnicities',
  'Genders',
  'Geographic locations',
  'Historical passwords',
  'Instant messenger identities',
  'IP addresses',
  'License plate numbers',
  'Names',
  'Password hints',
  'Passwords',
  'Payment histories',
  'Phone numbers',
  'Physical addresses',
  'Private messages',
  'Security questions and answers',
  'Sexual orientations',
  'Social connections',
  'Social media profiles',
  'Spoken languages',
  'Time zones',
  'User statuses',
  'User website URLs',
  'Usernames',
  'Website activity',
];

function createBreach(postData) {
  return new Promise((resolve, reject) => {
    axios.post('https://api.tat.wgtl.io/api/v1/dws/breach/add', postData)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data.id);
        }
        return reject(new Error('Error creating breach'));
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          return reject(error.response.data);
        }
        return reject(error);
      });
  });
}

class CreateBreachForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      name: '',
      title: '',
      description: '',
      domain: '',
      breachDate: '',
      disclosureDate: '',
      logoPath: '',
      dataPath: '',
      errorMessage: null,
      dataClasses: [],
      isVerified: true,
      isSensitive: false,
      isRetired: false,
      isSpam: false,
      isCollection: false,
    };
    this.changeName = this.changeName.bind(this);
    this.changeTitle = this.changeTitle.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeBreachDate = this.changeBreachDate.bind(this);
    this.changeDisclosureDate = this.changeDisclosureDate.bind(this);
    this.changeDomain = this.changeDomain.bind(this);
    this.imageUploaded = this.imageUploaded.bind(this);
    this.changeDataPath = this.changeDataPath.bind(this);
    this.changeDataClasses = this.changeDataClasses.bind(this);
    this.toggleIsVerified = this.toggleIsVerified.bind(this);
    this.toggleIsSensitive = this.toggleIsSensitive.bind(this);
    this.toggleIsRetired = this.toggleIsRetired.bind(this);
    this.toggleIsSpam = this.toggleIsSpam.bind(this);
    this.toggleIsCollection = this.toggleIsCollection.bind(this);
    this.submitBreach = this.submitBreach.bind(this);
  }

  changeName(event) {
    this.setState({
      name: event.target.value,
    });
  }

  changeTitle(event) {
    this.setState({
      title: event.target.value,
      name: event.target.value.replace(/ /g, ''),
    });
  }

  changeDescription(event) {
    this.setState({
      description: event.target.value,
    });
  }

  changeDomain(event) {
    this.setState({
      domain: event.target.value,
    });
  }

  changeBreachDate(event) {
    this.setState({
      breachDate: event.target.value,
    });
  }

  changeDisclosureDate(event) {
    this.setState({
      disclosureDate: event.target.value,
    });
  }

  imageUploaded(url) {
    this.setState({
      logoPath: url,
    });
  }

  changeDataPath(event) {
    this.setState({
      dataPath: event.target.value,
    });
  }

  changeDataClasses(t) {
    this.setState({
      dataClasses: t,
    });
  }

  toggleIsVerified(checked) {
    this.setState({ isVerified: checked });
  }

  toggleIsSensitive(checked) {
    this.setState({ isSensitive: checked });
  }

  toggleIsRetired(checked) {
    this.setState({ isRetired: checked });
  }

  toggleIsSpam(checked) {
    this.setState({ isSpam: checked });
  }

  toggleIsCollection(checked) {
    this.setState({ isCollection: checked });
  }

  submitBreach(event) {
    const {
      name,
      title,
      description,
      domain,
      breachDate,
      disclosureDate,
      logoPath,
      dataPath,
      dataClasses,
      isVerified,
      isSensitive,
      isRetired,
      isSpam,
      isCollection,
    } = this.state;

    if (event) {
      event.preventDefault();
    }

    this.setState({
      isSubmitting: true,
      errorMessage: null,
    });

    const postData = {
      name,
      title,
      description,
      domain,
      breach_date: breachDate,
      disclosure_date: disclosureDate,
      logo_path: logoPath,
      data_path: dataPath,
      data_classes: dataClasses,
      is_verified: isVerified,
      is_sensitive: isSensitive,
      is_retired: isRetired,
      is_spam: isSpam,
      is_collection: isCollection,
    };

    createBreach(postData)
      .then((breachId) => {
        const { history } = this.props;
        history.push(`/DarkWebScan/Breach/${breachId}`);
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.message,
          isSubmitting: false,
        });
      });
  }

  render() {
    const {
      isSubmitting,
      name,
      title,
      description,
      domain,
      breachDate,
      disclosureDate,
      dataPath,
      dataClasses,
      errorMessage,
      isVerified,
      isSensitive,
      isRetired,
      isSpam,
      isCollection,
      logoPath,
    } = this.state;
    return (
      <div id="CreateBreachForm">
        <Row>
          <Col
            xs={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
            className="form-section"
          >
            <Form onSubmit={this.submitAnalysis}>
              <Form.Row>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 5, offset: 1 }}
                  className="form-col"
                >
                  <Form.Group>
                    <Form.Label htmlFor="breach-title">
                      Title
                    </Form.Label>
                    <Form.Control
                      id="breach-title"
                      type="text"
                      name="title"
                      placeholder="Title"
                      className="text-field"
                      value={title}
                      onChange={this.changeTitle}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 5, offset: 0 }}
                  className="form-col"
                >
                  <Form.Group>
                    <Form.Label htmlFor="breach-name">
                      Name
                    </Form.Label>
                    <Form.Control
                      id="breach-name"
                      type="text"
                      name="name"
                      placeholder="Name"
                      className="text-field"
                      value={name}
                      onChange={this.changeName}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={{ span: 10, offset: 1 }} className="form-col">
                  <Form.Group>
                    <Form.Label htmlFor="breach-description">
                      Description
                    </Form.Label>
                    <Form.Control
                      id="breach-description"
                      as="textarea"
                      name="description"
                      placeholder="Description"
                      className="description-field"
                      value={description}
                      onChange={this.changeDescription}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={{ span: 10, offset: 1 }} className="form-col">
                  <Form.Group>
                    <Form.Label htmlFor="breach-domain">
                      Domain
                    </Form.Label>
                    <Form.Control
                      id="breach-domain"
                      type="text"
                      name="domain"
                      placeholder="(eg: https://breachedsite.com)"
                      className="text-field"
                      value={domain}
                      onChange={this.changeDomain}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 5, offset: 1 }}
                  className="form-col"
                >
                  <Form.Group>
                    <Form.Label htmlFor="breach-breach-date">
                      Breach Date
                    </Form.Label>
                    <Form.Control
                      id="breach-breach-date"
                      type="date"
                      name="breachDate"
                      placeholder="Breach Date"
                      className="date-field"
                      value={breachDate}
                      onChange={this.changeBreachDate}
                    />
                  </Form.Group>
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 5, offset: 0 }}
                  className="form-col"
                >
                  <Form.Group>
                    <Form.Label htmlFor="breach-disclosure-date">
                      Disclosure Date
                    </Form.Label>
                    <Form.Control
                      id="breach-disclosure-date"
                      type="date"
                      name="disclosureDate"
                      placeholder="Disclosure Date"
                      className="date-field"
                      value={disclosureDate}
                      onChange={this.changeDisclosureDate}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={{ span: 10, offset: 1 }} className="form-col">
                  <Form.Label htmlFor="breach-logo">
                    Breach Image
                  </Form.Label>
                  <UploadImage
                    logoPath={logoPath}
                    imageUploaded={this.imageUploaded}
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={{ span: 10, offset: 1 }} className="form-col">
                  <Form.Group>
                    <Form.Label htmlFor="breach-data">
                      Data S3 URL
                    </Form.Label>
                    <Form.Control
                      id="breach-data"
                      type="text"
                      name="dataPath"
                      placeholder="(eg: darkwebscan-data/parsed/<breachname>"
                      className="text-field"
                      value={dataPath}
                      onChange={this.changeDataPath}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={{ span: 10, offset: 1 }} className="form-col">
                  <Form.Label htmlFor="breach-data-classes">
                    Data Classes
                  </Form.Label>
                  <Multiselect
                    options={dataClassList}
                    placeholder="Data classes"
                    selectedValues={dataClasses}
                    onSelect={this.changeDataClasses}
                    onRemove={this.changeDataClasses}
                    isObject={false}
                    avoidHighlightFirstOption
                    hidePlaceholder
                  />
                </Col>
              </Form.Row>
              <Form.Row className="switch-row">
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 2, offset: 1 }}
                  className="form-col switch-col"
                >
                  <span>Verified</span>
                  <Switch
                    onChange={this.toggleIsVerified}
                    checked={isVerified}
                    className="settings-switch"
                    onColor="#00cc00"
                    height={20}
                    width={40}
                    activeBoxShadow="0 0 2px 3px #00cc00"
                  />
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 2, offset: 0 }}
                  className="form-col switch-col"
                >
                  <span>Sensitive</span>
                  <Switch
                    onChange={this.toggleIsSensitive}
                    checked={isSensitive}
                    className="settings-switch"
                    onColor="#00cc00"
                    height={20}
                    width={40}
                    activeBoxShadow="0 0 2px 3px #00cc00"
                  />
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 2, offset: 0 }}
                  className="form-col switch-col"
                >
                  <span>Retired</span>
                  <Switch
                    onChange={this.toggleIsRetired}
                    checked={isRetired}
                    className="settings-switch"
                    onColor="#00cc00"
                    height={20}
                    width={40}
                    activeBoxShadow="0 0 2px 3px #00cc00"
                  />
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 2, offset: 0 }}
                  className="form-col switch-col"
                >
                  <span>Spam</span>
                  <Switch
                    onChange={this.toggleIsSpam}
                    checked={isSpam}
                    className="settings-switch"
                    onColor="#00cc00"
                    height={20}
                    width={40}
                    activeBoxShadow="0 0 2px 3px #00cc00"
                  />
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 2, offset: 0 }}
                  className="form-col switch-col"
                >
                  <span>Collection</span>
                  <Switch
                    onChange={this.toggleIsCollection}
                    checked={isCollection}
                    className="settings-switch"
                    onColor="#00cc00"
                    height={20}
                    width={40}
                    activeBoxShadow="0 0 2px 3px #00cc00"
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={{ span: 'auto', offset: 1 }} className="form-col">
                  <Button onClick={this.submitBreach} type="submit" id="submit-breach">Submit</Button>
                  {isSubmitting
                    ? (
                      <div className="submit-pending">
                        <i className="fas fa-spinner fa-spin submit-pending-icon" />
                      </div>
                    )
                    : ''}
                  {errorMessage
                    ? (
                      <div className="error-message">
                        {errorMessage}
                      </div>
                    )
                    : ''}
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

CreateBreachForm.propTypes = {
  history: PropTypes.object.isRequired,
};

export default CreateBreachForm;
