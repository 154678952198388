import './CreateAnalysis.css';

import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Col,
  Row,
  Button,
} from 'react-bootstrap';

import axios from '../../../utils/axios';

function uploadAnalysis(threatId, publicAnalysis, references) {
  return new Promise((resolve, reject) => {
    const postData = {
      threatId,
      publicAnalysis,
      references,
    };
    axios.post('https://api.tat.wgtl.io/api/v1/threat/analysis/create', postData)
      .then((res) => {
        if (res.status === 200) {
          return resolve({ success: true });
        }
        return reject(new Error('Error creating analysis'));
      })
      .catch((error) => reject(error));
  });
}

function splitReferences(referenceText) {
  const referenceLinks = referenceText.split('\n');
  return referenceLinks;
}

class CreateAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: '',
      references: '',
      isSubmitting: false,
    };
    this.changeDescription = this.changeDescription.bind(this);
    this.changeReferences = this.changeReferences.bind(this);
    this.submitAnalysis = this.submitAnalysis.bind(this);
  }

  changeDescription(event) {
    this.setState({
      description: event.target.value,
    });
  }

  changeReferences(event) {
    this.setState({
      references: event.target.value,
    });
  }

  submitAnalysis(event) {
    const {
      threatId,
      analysisCreated,
    } = this.props;
    const {
      description,
      references,
    } = this.state;
    if (event) {
      event.preventDefault();
    }
    this.setState({
      isSubmitting: true,
    });

    const referenceLinks = splitReferences(references);

    uploadAnalysis(threatId, description, referenceLinks)
      .then(() => {
        analysisCreated()
          .then(() => {
            this.setState({
              isSubmitting: false,
              description: '',
              references: '',
            });
          })
          .catch(() => {
            this.setState({
              isSubmitting: false,
              description: '',
            });
          });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isSubmitting: false,
        });
      });
  }

  render() {
    const {
      isSubmitting,
      description,
      references,
    } = this.state;
    return (
      <div id="CreateAnalysis">
        <Row>
          <Col xs={12} className="search-section">
            <Form onSubmit={this.submitAnalysis}>
              <Form.Row className="align-items-center">
                <Col xs={{ span: 10, offset: 1 }} className="form-col">
                  <Form.Group>
                    <Form.Label htmlFor="analysis-description" srOnly>
                      Description
                    </Form.Label>
                    <Form.Control
                      id="analysis-description"
                      as="textarea"
                      name="description"
                      placeholder="Public analysis"
                      className="description-field"
                      value={description}
                      onChange={this.changeDescription}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="align-items-center">
                <Col xs={{ span: 10, offset: 1 }} className="form-col">
                  <Form.Group>
                    <Form.Label htmlFor="analysis-references" srOnly>
                      Reference Links
                    </Form.Label>
                    <Form.Control
                      id="analysis-references"
                      as="textarea"
                      name="references"
                      placeholder="Public Reference Links..."
                      className="references-field"
                      value={references}
                      onChange={this.changeReferences}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={{ span: 'auto', offset: 1 }} className="form-col">
                  <Button type="submit" id="submit-analysis-button">Submit</Button>
                  {isSubmitting
                    ? (
                      <div className="submit-pending">
                        <i className="fas fa-spinner fa-spin submit-pending-icon" />
                      </div>
                    )
                    : ''}
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

CreateAnalysis.propTypes = {
  threatId: PropTypes.string.isRequired,
  analysisCreated: PropTypes.func.isRequired,
};

export default CreateAnalysis;
