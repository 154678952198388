import './MalwareThreatDetails.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import LoadingOverlay from '../../Overlays/LoadingOverlay';
import ViewAnalyses from '../../Analysis/ViewAnalyses';
import MalwareIncidentTimeline from '../../MalwareIncidentTimeline';
import MalwareHashes from '../../MalwareHashes';
import MalwareCountries from '../../MalwareCountries';

import ConfirmOverlay from '../../Overlays/ConfirmOverlay';

import axios from '../../../utils/axios';

function getThreatDetails(threatId) {
  return new Promise((resolve, reject) => {
    axios.get(`https://api.tat.wgtl.io/api/v1/threat/malware/${threatId}`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for threats'));
      })
      .catch(() => reject(new Error('Error searching for threats')));
  });
}

function disableAnalysis(analysisId) {
  return new Promise((resolve, reject) => {
    const postData = {
      analysisId,
    };
    axios.post('https://api.tat.wgtl.io/api/v1/threat/analysis/disable', postData)
      .then((res) => {
        if (res.status === 200) {
          return resolve({ success: true });
        }
        return reject(new Error('Error creating analysis'));
      })
      .catch((error) => reject(error));
  });
}

class MalwareThreatDetails extends React.Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      threatId: match.params.threatId,
      isSearching: true,
      threat: null,
      confirmDelete: null,
    };
    this.analysisCreated = this.analysisCreated.bind(this);
    this.deleteAnalysis = this.deleteAnalysis.bind(this);
    this.confirmDeleteAnalysis = this.confirmDeleteAnalysis.bind(this);
    this.rejectDeleteAnalysis = this.rejectDeleteAnalysis.bind(this);
  }

  componentDidMount() {
    const {
      threatId,
    } = this.state;
    getThreatDetails(threatId)
      .then((result) => {
        console.log(result.threatDetails.analyses);
        if (result.threatDetails.analyses && result.threatDetails.analyses.length > 0) {
          console.log('Sort');
          result.threatDetails.analyses.sort((a, b) => new Date(b.date) - new Date(a.date));
        }
        console.log(result.threatDetails.analyses);
        this.setState({
          isSearching: false,
          threat: result.threatDetails,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isSearching: false,
        });
      });
  }

  analysisCreated() {
    const {
      threatId,
    } = this.state;
    return new Promise((resolve) => {
      getThreatDetails(threatId)
        .then((result) => {
          if (result.threatDetails.analyses && result.threatDetails.analyses.length > 0) {
            result.threatDetails.analyses.sort((a, b) => new Date(b.date) - new Date(a.date));
          }
          this.setState({
            isSearching: false,
            threat: result.threatDetails,
          }, resolve());
        })
        .catch((error) => {
          console.log(error.message);
          this.setState({
            isSearching: false,
          }, resolve());
        });
    });
  }

  deleteAnalysis(analysisId) {
    this.setState({
      confirmDelete: analysisId,
    });
  }

  confirmDeleteAnalysis() {
    const { confirmDelete, threatId } = this.state;
    disableAnalysis(confirmDelete)
      .then(() => {
        this.setState({
          confirmDelete: false,
        });
        getThreatDetails(threatId)
          .then((result) => {
            if (result.threatDetails.analyses && result.threatDetails.analyses.length > 0) {
              result.threatDetails.analyses.sort((a, b) => new Date(b.date) - new Date(a.date));
            }
            this.setState({
              isSearching: false,
              threat: result.threatDetails,
            });
          })
          .catch((error) => {
            console.log(error.message);
            this.setState({
              isSearching: false,
            });
          });
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  rejectDeleteAnalysis() {
    this.setState({
      confirmDelete: null,
    });
  }

  render() {
    const {
      confirmDelete,
      isSearching,
      threatId,
      threat,
    } = this.state;
    const { user } = this.props;

    function formatTime(d) {
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ];
      const date1 = new Date();
      const date2 = new Date(d);
      const diff = Math.floor(date1.getTime() - date2.getTime());
      const day = 1000 * 60 * 60 * 24;
      const minute = 1000 * 60;
      const minutes = Math.floor(diff / minute);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(diff / day);
      const months = Math.floor(days / 31);
      const years = Math.floor(months / 12);
      let message = '';
      if (years > 0 || months > 0) {
        message = `${monthNames[date2.getMonth()]} ${date2.getDate()}, ${date2.getFullYear()}`;
      } else if (days > 0) {
        message += days;
        if (days > 1) {
          message += ' days ago';
        } else {
          message += ' day ago';
        }
      } else if (hours > 0) {
        message += hours;
        if (hours > 1) {
          message += ' hours ago';
        } else {
          message += ' hour ago';
        }
      } else if (minutes > 0) {
        message += minutes;
        if (minutes > 1) {
          message += ' minutes ago';
        } else {
          message += ' minute ago';
        }
      }
      return message;
    }

    return (
      <div id="MalwareThreatDetails">
        {isSearching
          ? <LoadingOverlay />
          : ''}
        {confirmDelete
          ? (
            <ConfirmOverlay
              accept={this.confirmDeleteAnalysis}
              reject={this.rejectDeleteAnalysis}
              title="Disable Analysis"
              message="Are you sure you want to disable this analysis?"
            />
          )
          : ''}
        {threat
          ? (
            <div>
              <Row>
                <Col xs={12} className="threat-title">
                  <h1>{threat.name}</h1>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="threat-stats-overview">
                  <Row>
                    <Col xs={12} lg={3} className="malware-threat-stat">
                      <span className="stat-label">First Seen: </span>
                      {' '}
                      {formatTime(threat.firstSeen)}
                    </Col>
                    <Col xs={12} lg={3} className="malware-threat-stat">
                      <span className="stat-label">Last Seen: </span>
                      {' '}
                      {formatTime(threat.lastSeen)}
                    </Col>
                    <Col xs={12} lg={3} className="malware-threat-stat">
                      <span className="stat-label">Incidents: </span>
                      {' '}
                      {threat.incidentCount}
                    </Col>
                    <Col xs={12} lg={3} className="malware-threat-stat">
                      <span className="stat-label">Unique Victims: </span>
                      {' '}
                      {threat.uniqueVictims}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="threat-details-section">
                  <h4>Detection Map</h4>
                  <MalwareCountries threatId={threatId} />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="threat-details-section">
                  <h3>Analysis</h3>
                  <ViewAnalyses
                    user={user}
                    analyses={threat.analyses}
                    deleteAnalysis={this.deleteAnalysis}
                    threatId={threat._id}
                    analysisCreated={this.analysisCreated}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="threat-details-section">
                  <h4>Incident Timeline</h4>
                  <MalwareIncidentTimeline threatId={threatId} />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="threat-details-section">
                  <h4>Top MD5s</h4>
                  <MalwareHashes threatId={threatId} />
                </Col>
              </Row>
            </div>
          )
          : ''}
      </div>
    );
  }
}

MalwareThreatDetails.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object,
};

MalwareThreatDetails.defaultProps = {
  user: null,
};

export default MalwareThreatDetails;
