import './SelectProductFamily.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Multiselect } from 'multiselect-react-dropdown';

export default function SelectProductFamily(props) {
  const {
    productFamilies,
    selected,
    onChange,
    nextStage,
    prevStage,
  } = props;

  return (
    <div id="SelectProductFamily">
      <div id="SelectProductFamilyContainer">
        <h3>
          Which Type of Products Are Affected?
        </h3>
        <Multiselect
          options={productFamilies}
          placeholder="Product Families"
          selectedValues={selected}
          onSelect={onChange}
          onRemove={onChange}
          isObject={false}
          avoidHighlightFirstOption
          hidePlaceholder
        />
        <div className="stage-control">
          <Button
            onClick={prevStage}
            type="submit"
            id="prev-stage"
            className="stage-control-button"
          >
            Cancel
          </Button>
          <Button
            onClick={nextStage}
            type="submit"
            id="next-stage"
            className="stage-control-button"
            disabled={selected.length === 0}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

SelectProductFamily.propTypes = {
  productFamilies: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  nextStage: PropTypes.func.isRequired,
  prevStage: PropTypes.func.isRequired,
};
