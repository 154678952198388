import React from 'react';
import PropTypes from 'prop-types';
import './Header.css';

import HeaderNavMenu from '../Menus/HeaderNavMenu';
import AuthBar from '../Menus/AuthBar';
import ProfileDropdown from '../Menus/ProfileDropdown';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileDropdownOpened: false,
    };
    this.openProfileDropdown = this.openProfileDropdown.bind(this);
    this.closeProfileDropdown = this.closeProfileDropdown.bind(this);
  }

  // <<< Profile Menu Start >>>
  openProfileDropdown() {
    const { profileDropdownOpened } = this.state;
    this.setState({
      profileDropdownOpened: !profileDropdownOpened,
    });
  }

  closeProfileDropdown() {
    this.setState({
      profileDropdownOpened: false,
    });
  }
  // <<< Profile Menu End >>>

  render() {
    const {
      auth,
      history,
      user,
    } = this.props;
    const { profileDropdownOpened } = this.state;
    return (
      auth.isAuthenticated()
        ? (
          <div id="Header">
            <HeaderNavMenu user={user} />
            {profileDropdownOpened
              ? (
                <ProfileDropdown
                  auth={auth}
                  history={history}
                  user={user}
                  handleClose={this.closeProfileDropdown}
                />
              )
              : ''}
            <div className="header-title" />
            <AuthBar
              auth={auth}
              user={user}
              openProfileDropdown={this.openProfileDropdown}
            />
          </div>
        )
        : ''
    );
  }
}

Header.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
};

Header.defaultProps = {
  user: null,
};

export default Header;
