import './AuthBar.css';

import * as React from 'react';
import PropTypes from 'prop-types';

function AuthBar(props) {
  const {
    openProfileDropdown,
    user,
  } = props;
  return (
    <div id="AuthBar">
      {user
        ? (
          <div className="authenticated-bar">
            <div
              role="button"
              tabIndex="0"
              className="auth-user-icon"
              onClick={openProfileDropdown}
              onKeyPress={openProfileDropdown}
            >
              <img alt="User avatar" src={user.avatar} id="authbar-user-avatar" className="authbar-user-avatar" />
            </div>
          </div>
        )
        : ''}
    </div>
  );
}

AuthBar.propTypes = {
  user: PropTypes.object,
  openProfileDropdown: PropTypes.func.isRequired,
};

AuthBar.defaultProps = {
  user: null,
};

export default AuthBar;
