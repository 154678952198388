import './AddProduct.css';

import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Col,
} from 'react-bootstrap';

import axios from '../../../../utils/axios';
import PrimaryButton from '../../../Buttons/PrimaryButton';

function sendProduct(postData) {
  return new Promise((resolve, reject) => {
    axios.post('https://api.tat.wgtl.io/api/v1/psirt/product/add', postData)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        return reject(new Error('Error saving product'));
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          return reject(error.response.data);
        }
        return reject(error);
      });
  });
}

class AddProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productFamily: '',
      productFamilyError: null,
      productBranch: '',
      productBranchError: null,
      productList: '',
      isWorking: false,
      error: null,
    };

    this.changeProductFamily = this.changeProductFamily.bind(this);
    this.changeProductBranch = this.changeProductBranch.bind(this);
    this.changeProductList = this.changeProductList.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  changeProductFamily(e) {
    const {
      products,
    } = this.props;

    let productFamilyError = null;

    if (!(products.some((o) => o.product_family === e.target.value))) {
      productFamilyError = "This product family doesn't exist yet. Are you sure you want to create it?";
    }
    this.setState({
      productFamily: e.target.value,
      productFamilyError,
    });
  }

  changeProductBranch(e) {
    const {
      products,
    } = this.props;

    let productBranchError = null;

    if (products.some((o) => o.product_branch === e.target.value)) {
      productBranchError = 'This product branch already exists.';
    }
    this.setState({
      productBranch: e.target.value,
      productBranchError,
    });
  }

  changeProductList(e) {
    this.setState({
      productList: e.target.value,
    });
  }

  submitForm(e) {
    const {
      productFamily,
      productBranch,
      productList,
    } = this.state;

    const {
      productAdded,
    } = this.props;

    e.preventDefault();

    this.setState({
      isWorking: true,
    });

    const productListSplit = productList.split(',').map((o) => o.trim());
    const postData = {
      family: productFamily,
      branch: productBranch,
      list: productListSplit,
    };
    sendProduct(postData)
      .then(() => productAdded())
      .catch((error) => {
        this.setState({
          error: error.message,
        });
      });
  }

  render() {
    const {
      productFamily,
      productFamilyError,
      productBranch,
      productBranchError,
      productList,
      isWorking,
      error,
    } = this.state;

    const {
      close,
    } = this.props;

    return (
      <div id="AddProduct">
        <div id="confirm-content">
          <Form>
            <Form.Row>
              <Col xs={{ span: 10, offset: 1 }} className="form-col">
                <Form.Group>
                  <Form.Label htmlFor="product-family">
                    Product Family
                  </Form.Label>
                  <Form.Control
                    id="product-family"
                    type="text"
                    name="productFamily"
                    placeholder=""
                    className="text-field"
                    value={productFamily}
                    onChange={this.changeProductFamily}
                    autoComplete="off"
                  />
                  <Form.Text id="descriptionHelpText">
                    {productFamilyError}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={{ span: 10, offset: 1 }} className="form-col">
                <Form.Group>
                  <Form.Label htmlFor="product-branch">
                    Product Branch
                  </Form.Label>
                  <Form.Control
                    id="product-branch"
                    type="text"
                    name="productBranch"
                    placeholder=""
                    className="text-field"
                    value={productBranch}
                    onChange={this.changeProductBranch}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Text id="descriptionHelpText">
                  {productBranchError}
                </Form.Text>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={{ span: 10, offset: 1 }} className="form-col">
                <Form.Group>
                  <Form.Label htmlFor="product-list">
                    Product List
                  </Form.Label>
                  <Form.Control
                    id="product-list"
                    type="text"
                    name="productList"
                    placeholder=""
                    className="text-field"
                    value={productList}
                    onChange={this.changeProductList}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
          <div className="error-message">
            {error}
          </div>
          {isWorking
            ? (
              <div className="working-spinner">
                <i className="fas fa-spinner fa-spin searching-spinner" />
              </div>
            )
            : ''}
          <div className="confirm-controls">
            <PrimaryButton buttonText="Add" onClick={this.submitForm} disabled={isWorking} />
            <PrimaryButton buttonText="Cancel" onClick={close} disabled={isWorking} />
          </div>
        </div>
      </div>
    );
  }
}

AddProduct.propTypes = {
  products: PropTypes.array.isRequired,
  productAdded: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default AddProduct;
