import './EditAdvisoryDetails.css';

import React from 'react';
import {
  Form,
  Row, Col,
  Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import axios from '../../../../utils/axios';

const statusOptions = [
  'Investigating',
  'Acknowledged',
  'Resolved',
  'Not Applicable',
];

const impactOptions = [
  'Critical',
  'High',
  'Medium',
  'Low',
  'Not Applicable',
];

function saveAdvisory(postData) {
  return new Promise((resolve, reject) => {
    axios.post('https://api.tat.wgtl.io/api/v1/psirt/advisory/save', postData)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data.advisory);
        }
        return reject(new Error('Error saving advisory'));
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          return reject(error.response.data);
        }
        return reject(error);
      });
  });
}

class EditAdvisoryDetails extends React.Component {
  constructor(props) {
    super(props);

    const {
      title,
      cve,
      impact,
      status,
      cvssScore,
      cvssVector,
      summary,
      affected,
      resolution,
      workaround,
      credits,
      references,
    } = this.props;

    this.state = {
      isWorking: false,
      errorMessage: null,
      title,
      cve: cve.join(', '),
      impact,
      status,
      cvssScore,
      cvssVector,
      summary,
      affected,
      resolution,
      workaround,
      credits: credits.join(', '),
      references: references.join(', '),
      summaryPreviewOpened: false,
      workaroundPreviewOpened: false,
      affectedPreviewOpened: false,
      resolutionPreviewOpened: false,
    };

    this.submitAdvisory = this.submitAdvisory.bind(this);
    this.changeTitle = this.changeTitle.bind(this);
    this.changeCve = this.changeCve.bind(this);
    this.changeImpact = this.changeImpact.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.changeScore = this.changeScore.bind(this);
    this.changeVector = this.changeVector.bind(this);
    this.changeSummary = this.changeSummary.bind(this);
    this.changeWorkaround = this.changeWorkaround.bind(this);
    this.changeCredits = this.changeCredits.bind(this);
    this.changeReferences = this.changeReferences.bind(this);
    this.toggleSummaryPreview = this.toggleSummaryPreview.bind(this);
    this.changeAffected = this.changeAffected.bind(this);
    this.changeResolution = this.changeResolution.bind(this);
    this.toggleWorkaroundPreview = this.toggleWorkaroundPreview.bind(this);
    this.toggleAffectedPreview = this.toggleAffectedPreview.bind(this);
    this.toggleResolutionPreview = this.toggleResolutionPreview.bind(this);
  }

  submitAdvisory() {
    this.setState({
      isWorking: true,
    });

    const {
      id,
      products,
    } = this.props;

    const {
      title,
      summary,
      affected,
      resolution,
      impact,
      status,
      workaround,
      cve,
      credits,
      references,
      cvssScore,
      cvssVector,
    } = this.state;

    const hasWorkaround = workaround !== '';

    const cveArray = (cve && cve.trim() !== '') ? cve.trim().split(', ') : [];
    const creditsArray = (credits && credits.trim() !== '') ? credits.trim().split(', ') : [];
    const referencesArray = (references && references.trim() !== '') ? references.trim().split(', ') : [];

    const advisory = {
      _id: id,
      title,
      summary,
      affected: affected || '',
      resolution: resolution || '',
      cve: cveArray,
      impact,
      status,
      cvss_score: cvssScore,
      cvss_vector: cvssVector,
      has_workaround: hasWorkaround,
      workaround: workaround || '',
      products,
      credits: creditsArray,
      references: referencesArray,
    };

    return saveAdvisory(advisory)
      .then((response) => {
        const { advisorySaved } = this.props;
        advisorySaved(response);
      })
      .catch((error) => {
        this.setState({
          isWorking: false,
          errorMessage: error.message,
        });
      });
  }

  changeTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  changeCve(e) {
    this.setState({
      cve: e.target.value,
    });
  }

  changeImpact(e) {
    this.setState({
      impact: e.target.value,
    });
  }

  changeStatus(e) {
    this.setState({
      status: e.target.value,
    });
  }

  changeScore(e) {
    this.setState({
      cvssScore: e.target.value,
    });
  }

  changeVector(e) {
    this.setState({
      cvssVector: e.target.value,
    });
  }

  changeSummary(e) {
    this.setState({
      summary: e.target.value,
    });
  }

  changeWorkaround(e) {
    this.setState({
      workaround: e.target.value,
    });
  }

  changeCredits(e) {
    this.setState({
      credits: e.target.value,
    });
  }

  changeReferences(e) {
    this.setState({
      references: e.target.value,
    });
  }

  changeAffected(e) {
    this.setState({
      affected: e.target.value,
    });
  }

  changeResolution(e) {
    this.setState({
      resolution: e.target.value,
    });
  }

  toggleSummaryPreview() {
    this.setState((prevState) => ({ summaryPreviewOpened: !prevState.summaryPreviewOpened }));
  }

  toggleWorkaroundPreview() {
    this.setState((prevState) => ({ workaroundPreviewOpened: !prevState.workaroundPreviewOpened }));
  }

  toggleAffectedPreview() {
    this.setState((prevState) => ({ affectedPreviewOpened: !prevState.affectedPreviewOpened }));
  }

  toggleResolutionPreview() {
    this.setState((prevState) => ({ resolutionPreviewOpened: !prevState.resolutionPreviewOpened }));
  }

  render() {
    const {
      title,
      cve,
      impact,
      status,
      cvssScore,
      cvssVector,
      summary,
      affected,
      resolution,
      workaround,
      credits,
      references,
      summaryPreviewOpened,
      workaroundPreviewOpened,
      affectedPreviewOpened,
      resolutionPreviewOpened,
      errorMessage,
      isWorking,
    } = this.state;

    const {
      close,
    } = this.props;

    return (
      <div id="EditAdvisoryDetails">
        <div id="EditAdvisoryDetailsContainer">
          <h3>
            Edit Advisory Details
          </h3>
          <Form>
            <Form.Row>
              <Col
                xs={{ span: 10, offset: 1 }}
                className="form-col"
              >
                <Form.Group>
                  <Form.Label htmlFor="advisory-title">
                    Title
                  </Form.Label>
                  <Form.Control
                    id="advisory-title"
                    type="text"
                    name="title"
                    placeholder="Title"
                    className="text-field"
                    value={title}
                    onChange={this.changeTitle}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col
                xs={{ span: 10, offset: 1 }}
                md={{ span: 5, offset: 1 }}
                className="form-col"
              >
                <Form.Group>
                  <Form.Label htmlFor="advisory-status">
                    Status
                  </Form.Label>
                  <Form.Control
                    id="advisory-status"
                    as="select"
                    name="status"
                    className="text-field"
                    value={status}
                    onChange={this.changeStatus}
                    autoComplete="off"
                  >
                    {statusOptions.map((o) => (
                      <option key={o} value={o}>{o}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col
                xs={{ span: 10, offset: 1 }}
                md={{ span: 5, offset: 0 }}
                className="form-col"
              >
                <Form.Group>
                  <Form.Label htmlFor="advisory-impact">
                    Impact
                  </Form.Label>
                  <Form.Control
                    id="advisory-impact"
                    as="select"
                    name="impact"
                    className="text-field"
                    value={impact}
                    onChange={this.changeImpact}
                    autoComplete="off"
                  >
                    {impactOptions.map((o) => (
                      <option key={o} value={o}>{o}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col
                xs={{ span: 10, offset: 1 }}
                className="form-col"
              >
                <Form.Group>
                  <Form.Label htmlFor="advisory-cve">
                    CVE(s)
                  </Form.Label>
                  <Form.Control
                    id="advisory-cve"
                    type="text"
                    name="cve"
                    placeholder="CVE(s)"
                    className="text-field"
                    value={cve}
                    onChange={this.changeCve}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col
                xs={{ span: 10, offset: 1 }}
                md={{ span: 5, offset: 1 }}
                className="form-col"
              >
                <Form.Group>
                  <Form.Label htmlFor="advisory-cvss-score">
                    CVSS Score
                  </Form.Label>
                  <Form.Control
                    id="advisory-cvss-score"
                    type="text"
                    name="cvssScore"
                    placeholder="CVSS Score"
                    className="text-field"
                    value={cvssScore}
                    onChange={this.changeScore}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
              <Col
                xs={{ span: 10, offset: 1 }}
                md={{ span: 5, offset: 0 }}
                className="form-col"
              >
                <Form.Group>
                  <Form.Label htmlFor="advisory-cvss-vector">
                    CVSS Vector
                  </Form.Label>
                  <Form.Control
                    id="advisory-cvss-vector"
                    type="text"
                    name="cvssVector"
                    placeholder="CVSS Vector"
                    className="text-field"
                    value={cvssVector}
                    onChange={this.changeVector}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={{ span: 10, offset: 1 }} className="form-col">
                <Form.Group>
                  <Form.Label htmlFor="disclosure-summary">
                    Summary
                    {' '}
                    {!summaryPreviewOpened
                      ? (
                        <span
                          className="label-preview"
                          role="button"
                          tabIndex="0"
                          onClick={this.toggleSummaryPreview}
                          onKeyPress={this.toggleSummaryPreview}
                        >
                          (preview)
                        </span>
                      )
                      : ''}
                  </Form.Label>
                  <Form.Control
                    id="disclosure-summary"
                    as="textarea"
                    name="summary"
                    className="description-field"
                    value={summary}
                    onChange={this.changeSummary}
                    autoComplete="off"
                  />
                  <Form.Text id="descriptionHelpText">
                    This field supports supports
                    {' '}
                    <a
                      href="https://guides.github.com/features/mastering-markdown/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      markdown
                    </a>
                    {' '}
                    formatting
                  </Form.Text>
                </Form.Group>
              </Col>
            </Form.Row>
            {summaryPreviewOpened
              ? (
                <Row>
                  <Col xs={{ span: 10, offset: 1 }} className="preview-section">
                    <h5>
                      Summary Preview
                      {' '}
                      <span
                        className="label-preview"
                        role="button"
                        tabIndex="0"
                        onClick={this.toggleSummaryPreview}
                        onKeyPress={this.toggleSummaryPreview}
                      >
                        (close)
                      </span>
                    </h5>
                    <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
                      {summary}
                    </ReactMarkdown>
                  </Col>
                </Row>
              )
              : ''}
            <Form.Row>
              <Col xs={{ span: 10, offset: 1 }} className="form-col">
                <Form.Group>
                  <Form.Label htmlFor="disclosure-affected">
                    Affected Versions
                    {' '}
                    {!summaryPreviewOpened
                      ? (
                        <span
                          className="label-preview"
                          role="button"
                          tabIndex="0"
                          onClick={this.toggleAffectedPreview}
                          onKeyPress={this.toggleAffectedPreview}
                        >
                          (preview)
                        </span>
                      )
                      : ''}
                  </Form.Label>
                  <Form.Control
                    id="disclosure-affected"
                    as="textarea"
                    name="affected"
                    className="description-field"
                    value={affected}
                    onChange={this.changeAffected}
                    autoComplete="off"
                  />
                  <Form.Text id="descriptionHelpText">
                    This field supports supports
                    {' '}
                    <a
                      href="https://guides.github.com/features/mastering-markdown/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      markdown
                    </a>
                    {' '}
                    formatting
                  </Form.Text>
                </Form.Group>
              </Col>
            </Form.Row>
            {affectedPreviewOpened
              ? (
                <Row>
                  <Col xs={{ span: 10, offset: 1 }} className="preview-section">
                    <h5>
                      Affected Preview
                      {' '}
                      <span
                        className="label-preview"
                        role="button"
                        tabIndex="0"
                        onClick={this.toggleAffectedPreview}
                        onKeyPress={this.toggleAffectedPreview}
                      >
                        (close)
                      </span>
                    </h5>
                    <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
                      {affected}
                    </ReactMarkdown>
                  </Col>
                </Row>
              )
              : ''}
            <Form.Row>
              <Col xs={{ span: 10, offset: 1 }} className="form-col">
                <Form.Group>
                  <Form.Label htmlFor="disclosure-resolution">
                    Resolution
                    {' '}
                    {!summaryPreviewOpened
                      ? (
                        <span
                          className="label-preview"
                          role="button"
                          tabIndex="0"
                          onClick={this.toggleResolutionPreview}
                          onKeyPress={this.toggleResolutionPreview}
                        >
                          (preview)
                        </span>
                      )
                      : ''}
                  </Form.Label>
                  <Form.Control
                    id="disclosure-resolution"
                    as="textarea"
                    name="resolution"
                    className="description-field"
                    value={resolution}
                    onChange={this.changeResolution}
                    autoComplete="off"
                  />
                  <Form.Text id="descriptionHelpText">
                    This field supports supports
                    {' '}
                    <a
                      href="https://guides.github.com/features/mastering-markdown/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      markdown
                    </a>
                    {' '}
                    formatting
                  </Form.Text>
                </Form.Group>
              </Col>
            </Form.Row>
            {resolutionPreviewOpened
              ? (
                <Row>
                  <Col xs={{ span: 10, offset: 1 }} className="preview-section">
                    <h5>
                      Resolution Preview
                      {' '}
                      <span
                        className="label-preview"
                        role="button"
                        tabIndex="0"
                        onClick={this.toggleResolutionPreview}
                        onKeyPress={this.toggleResolutionPreview}
                      >
                        (close)
                      </span>
                    </h5>
                    <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
                      {resolution}
                    </ReactMarkdown>
                  </Col>
                </Row>
              )
              : ''}
            <Form.Row>
              <Col xs={{ span: 10, offset: 1 }} className="form-col">
                <Form.Group>
                  <Form.Label htmlFor="disclosure-workaround">
                    Workaround
                    {' '}
                    {!workaroundPreviewOpened
                      ? (
                        <span
                          className="label-preview"
                          role="button"
                          tabIndex="0"
                          onClick={this.toggleWorkaroundPreview}
                          onKeyPress={this.toggleWorkaroundPreview}
                        >
                          (preview)
                        </span>
                      )
                      : ''}
                  </Form.Label>
                  <Form.Control
                    id="disclosure-workaround"
                    as="textarea"
                    name="workaround"
                    className="description-field"
                    value={workaround}
                    onChange={this.changeWorkaround}
                    autoComplete="off"
                  />
                  <Form.Text id="descriptionHelpText">
                    This field supports supports
                    {' '}
                    <a
                      href="https://guides.github.com/features/mastering-markdown/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      markdown
                    </a>
                    {' '}
                    formatting
                  </Form.Text>
                </Form.Group>
              </Col>
            </Form.Row>
            {workaroundPreviewOpened
              ? (
                <Row>
                  <Col xs={{ span: 10, offset: 1 }} className="preview-section">
                    <h5>
                      Workaround Preview
                      {' '}
                      <span
                        className="label-preview"
                        role="button"
                        tabIndex="0"
                        onClick={this.toggleWorkaroundPreview}
                        onKeyPress={this.toggleWorkaroundPreview}
                      >
                        (close)
                      </span>
                    </h5>
                    <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
                      {workaround}
                    </ReactMarkdown>
                  </Col>
                </Row>
              )
              : ''}
            <Form.Row>
              <Col xs={{ span: 10, offset: 1 }} className="form-col">
                <Form.Group>
                  <Form.Label htmlFor="disclosure-credits">
                    Credits
                  </Form.Label>
                  <Form.Control
                    id="disclosure-credits"
                    as="textarea"
                    name="credits"
                    placeholder="Comma-separated list of credits"
                    className="description-field"
                    value={credits}
                    onChange={this.changeCredits}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={{ span: 10, offset: 1 }} className="form-col">
                <Form.Group>
                  <Form.Label htmlFor="disclosure-references">
                    References
                  </Form.Label>
                  <Form.Control
                    id="disclosure-references"
                    as="textarea"
                    name="references"
                    placeholder="Comma-separated list of links"
                    className="description-field"
                    value={references}
                    onChange={this.changeReferences}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
          {isWorking
            ? (
              <div className="searching"><i className="fas fa-spinner fa-spin searching-spinner" /></div>
            )
            : ''}
          <div className="error-message-container">
            {errorMessage}
          </div>
          <div className="stage-control">
            <Button
              onClick={close}
              type="submit"
              id="prev-stage"
              className="stage-control-button"
            >
              Back
            </Button>
            <Button
              onClick={this.submitAdvisory}
              type="submit"
              id="next-stage"
              className="stage-control-button"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

EditAdvisoryDetails.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cve: PropTypes.array.isRequired,
  impact: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  cvssScore: PropTypes.string.isRequired,
  cvssVector: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  affected: PropTypes.string.isRequired,
  resolution: PropTypes.string.isRequired,
  workaround: PropTypes.string.isRequired,
  credits: PropTypes.array.isRequired,
  references: PropTypes.array.isRequired,
  products: PropTypes.object.isRequired,
  advisorySaved: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default EditAdvisoryDetails;
