import './AdvisoryList.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import IncidentTable from '../../IncidentTable';

import axios from '../../../utils/axios';

function getAdvisoryList() {
  return new Promise((resolve, reject) => {
    axios.get('https://api.tat.wgtl.io/api/v1/psirt/advisory/all')
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for advisories'));
      })
      .catch(() => reject(new Error('Error searching for advisories')));
  });
}

class AdvisoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: true,
      errorMessage: '',
      advisories: [],
    };
  }

  componentDidMount() {
    getAdvisoryList()
      .then((advisories) => {
        advisories.sort((a, b) => a._id - b._id);
        this.setState({
          isSearching: false,
          advisories,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isSearching: false,
          errorMessage: error.message,
        });
      });
  }

  render() {
    const {
      isSearching,
      advisories,
      errorMessage,
    } = this.state;

    const defaultSorted = [{
      dataField: 'advisory_id',
      order: 'desc',
    }];

    const columns = [{
      dataField: '_id',
      hidden: true,
      text: 'id',
    }, {
      dataField: 'visibility',
      text: 'Visibility',
      formatter: (cell) => {
        if (cell === 'public') {
          return <span className="status-icon public"><i className="fas fa-circle" /></span>;
        }
        if (cell === 'staged') {
          return <span className="status-icon staged"><i className="fas fa-circle" /></span>;
        }
        if (cell === 'private') {
          return <span className="status-icon private"><i className="fas fa-circle" /></span>;
        }
        return <span className="status-icon archived"><i className="fas fa-circle" /></span>;
      },
    }, {
      dataField: 'advisory_id',
      text: 'Advisory',
      formatter: (cell, row) => (
        <Link className="table-link" to={`/PSIRT/Advisory/WGSA-${row.advisory_id.year}-${row.advisory_id.id}`}>
          WGSA-
          {`${cell.year}-${cell.id}`}
        </Link>
      ),
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          if (a.year !== b.year) {
            return a.year - b.year;
          }
          return a.id - b.id;
        }

        if (a.year !== b.year) {
          return b.year - a.year;
        }
        return b.id - a.id;
      },
    }, {
      dataField: 'title',
      text: 'Title',
      formatter: (cell, row) => (
        <Link className="table-link" to={`/PSIRT/Advisory/WGSA-${row.advisory_id.year}-${row.advisory_id.id}`}>{cell}</Link>
      ),
    }, {
      dataField: 'updated_date',
      text: 'Last Update',
      formatter: (cell) => {
        const d = new Date(cell);
        return d.toISOString().substring(0, 10);
      },
    }];

    const noTableData = () => {
      if (isSearching) {
        return (<div className="loading">Loading</div>);
      }
      if (errorMessage) {
        return (<div className="no-data-label">{errorMessage}</div>);
      }
      return (<div className="no-data-label">No advisories found </div>);
    };

    return (

      <div id="AdvisoryView">
        <Row>
          <Col xs={12} className="dashboard-item">
            <div id="AdvisoryList">
              {isSearching
                ? (
                  <div className="searching"><i className="fas fa-spinner fa-spin searching-spinner" /></div>
                )
                : ''}
              <div>
                <Row>
                  <Col xs={12}>
                    <IncidentTable
                      incidents={advisories}
                      columns={columns}
                      keyField="_id"
                      defaultSorted={defaultSorted}
                      noTableData={noTableData}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AdvisoryList;
