import './HeaderNavMenu.css';

import * as React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function HeaderNavMenu(props) {
  const {
    user,
  } = props;

  return (
    <div id="HeaderNavMenu">
      <div className="nav-menu-bar">
        <Link className="nav-link" to="/Dashboard">Dashboard</Link>
        {user && user.accountType && [1, 2].includes(user.accountType)
          ? <Link className="nav-link" to="/Analysis/Queue">Queue</Link>
          : ''}
        <Link className="nav-link" to="/Search">Search</Link>
        {user && user.accountType && [1, 2, 3, 4].includes(user.accountType)
          ? <Link className="nav-link" to="/DarkWebScan">Dark Web Scan</Link>
          : ''}
        {user && user.accountType && [1, 2, 3, 5, 6].includes(user.accountType)
          ? <Link className="nav-link" to="/PSIRT">PSIRT</Link>
          : ''}
      </div>
    </div>
  );
}

HeaderNavMenu.propTypes = {
  user: PropTypes.object,
};

HeaderNavMenu.defaultProps = {
  user: null,
};

export default HeaderNavMenu;
