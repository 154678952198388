import './BreachDatabaseList.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import IncidentTable from '../IncidentTable';

import axios from '../../utils/axios';

function getBreachDatabaseList() {
  return new Promise((resolve, reject) => {
    axios.get('https://api.tat.wgtl.io/api/v1/dws/breach/list')
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data.breaches);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for breaches'));
      })
      .catch(() => reject(new Error('Error searching for breaches')));
  });
}

class BreachDatabaseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: true,
      breaches: [],
    };
  }

  componentDidMount() {
    getBreachDatabaseList()
      .then((breaches) => {
        breaches.sort((a, b) => new Date(b.breach_date) - new Date(a.breach_date));
        this.setState({
          isSearching: false,
          breaches,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isSearching: false,
        });
      });
  }

  render() {
    const {
      isSearching,
      breaches,
    } = this.state;

    const columns = [{
      dataField: '_id',
      hidden: true,
      text: 'id',
    }, {
      dataField: 'status',
      isDummyField: true,
      text: 'Status',
      formatter: (cell, row) => {
        if (row.is_enabled) {
          return <span className="status-icon enabled"><i className="fas fa-circle" /></span>;
        }
        return <span className="status-icon error"><i className="fas fa-circle" /></span>;
      },
    }, {
      dataField: 'title',
      text: 'Title',
      formatter: (cell, row) => (
        <Link className="table-link" to={`/DarkWebScan/Breach/${row._id}`}>{cell}</Link>
      ),
    }, {
      dataField: 'record_count',
      text: 'Records',
    }, {
    }, {
      dataField: 'breach_date',
      text: 'Breach Date',
      formatter: (cell) => {
        const d = new Date(cell);
        return d.toISOString().substring(0, 10);
      },
    }];

    const noTableData = () => {
      if (isSearching) {
        return (<div className="loading">Loading</div>);
      }
      return (<div className="no-data-label">No breaches found</div>);
    };

    return (

      <div id="BreachDatabaseView">
        <Row>
          <Col xs={12} className="dashboard-item">
            <div id="BreachDatabaseList">
              {isSearching
                ? (
                  <div className="searching"><i className="fas fa-spinner fa-spin searching-spinner" /></div>
                )
                : ''}
              <div>
                <Row>
                  <Col xs={12}>
                    <IncidentTable
                      incidents={breaches}
                      columns={columns}
                      keyField="_id"
                      noTableData={noTableData}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BreachDatabaseList;
