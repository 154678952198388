import './TrendingMalware.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import IncidentTable from '../IncidentTable';

import axios from '../../utils/axios';

function getTrendingMalware() {
  return new Promise((resolve, reject) => {
    axios.get('https://api.tat.wgtl.io/api/v1/threat/malware/trending')
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for threats'));
      })
      .catch(() => reject(new Error('Error searching for threats')));
  });
}

class TrendingMalware extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: true,
      trending: null,
    };
  }

  componentDidMount() {
    getTrendingMalware()
      .then((results) => {
        this.setState({
          isSearching: false,
          trending: results.threats,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isSearching: false,
        });
      });
  }

  render() {
    const {
      isSearching,
      trending,
    } = this.state;

    const columns = [{
      dataField: 'threatId',
      hidden: true,
      text: 'id',
    }, {
      dataField: 'name',
      text: 'Name',
      formatter: (cell, row) => (
        <Link className="table-link" to={`/Threat/Malware/${row.threatId}`}>{cell}</Link>
      ),
    }, {
      dataField: 'percentIncrease',
      text: 'Increase',
    }, {
      dataField: 'incidentCount',
      text: 'Incidents',
    }, {
      dataField: 'uniqueVictims',
      text: 'Victims',
    }];

    const noTableData = () => {
      if (isSearching) {
        return (<div className="loading">Loading</div>);
      }
      return (<div className="no-data-label">No incidents found</div>);
    };

    return (
      <div id="TrendingMalware">
        {isSearching
          ? (
            <div className="searching"><i className="fas fa-spinner fa-spin searching-spinner" /></div>
          )
          : ''}
        {trending
          ? (
            <div>
              <Row>
                <Col xs={12}>
                  <IncidentTable
                    incidents={trending}
                    columns={columns}
                    keyField="threatId"
                    noTableData={noTableData}
                  />
                </Col>
              </Row>
            </div>
          )
          : ''}
      </div>
    );
  }
}

export default TrendingMalware;
