import './MalwareIncidentTimeline.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DarkUnica from 'highcharts/themes/dark-unica';

import axios from '../../utils/axios';

DarkUnica(Highcharts);

function getIncidents(threatId) {
  return new Promise((resolve, reject) => {
    axios.get(`https://api.tat.wgtl.io/api/v1/threat/malware/${threatId}/history`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for threats'));
      })
      .catch(() => reject(new Error('Error searching for threats')));
  });
}

class MalwareIncidentTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: true,
      incidents: null,
    };
  }

  componentDidMount() {
    const {
      threatId,
    } = this.props;
    getIncidents(threatId)
      .then((result) => {
        const hashes = { TOTAL: [] };
        result.forEach((d) => {
          const time = (new Date(d.date)).getTime();
          hashes.TOTAL.push([time, d.total]);
          /*
          Object.entries(d.byMD5).forEach(([hash, count]) => {
            if (hash === '') {
              if (!('UNKN' in hashes)) {
                hashes.UNKN = [];
              }
              hashes.UNKN.push([time, count]);
            } else {
              if (!(hash in hashes)) {
                hashes[hash] = [];
              }
              hashes[hash].push([time, count]);
            }
          });
          */
        });
        this.setState({
          isSearching: false,
          incidents: hashes,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isSearching: false,
        });
      });
  }

  render() {
    const {
      isSearching,
      incidents,
    } = this.state;

    const options = {
      title: {
        text: '',
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: 'Count',
        },
      },
      legend: {
        enabled: false,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
      },
      chart: {
        zoomType: 'x',
        type: 'area',
      },
      colors: [
        '#d32f2f',
      ],
      scrollbar: {
        liveRedraw: false,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, 'rgba(147, 31, 31, 1)'],
              [1, 'rgba(211, 49, 49, 0.2)'],
            ],
          },
          marker: {
            radius: 2,
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 2,
            },
          },
          threshold: null,
        },
      },
      series: [],
    };
    if (incidents) {
      Object.entries(incidents).forEach(([hash, count]) => {
        options.series.push({
          name: hash,
          type: 'area',
          data: count,
          color: '#d32f2f',
        });
      });
    }
    return (
      <div id="MalwareIncidentTimeline">
        {isSearching
          ? (
            <div className="searching"><i className="fas fa-spinner fa-spin searching-spinner" /></div>
          )
          : ''}
        {incidents
          ? (
            <div>
              <Row>
                <Col xs={12}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                  />
                </Col>
              </Row>
            </div>
          )
          : ''}
      </div>
    );
  }
}

MalwareIncidentTimeline.propTypes = {
  threatId: PropTypes.string.isRequired,
};

export default MalwareIncidentTimeline;
