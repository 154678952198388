import './AddBreachInstructions.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function AddBreachInstructions() {
  return (
    <div id="AddBreachInstructions">
      <Row>
        <Col xs={12} className="page-title">
          <h1>How to Add Breach</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="dashboard-item">
          <h2>Preparation</h2>
          <p>
            There are a few things you&apos;ll need to take care of first before adding a new breach
            to the database.
          </p>
          <ol>
            <li>
              Create a 500x500 pixel PNG image on a transparent background for the breach that is
              visible on a white background. If the breach involves an established company, the
              company logo on a transparent background is usually sufficient. If the breach is from
              a smaller organization like a niche forum, a relevant logo is sufficient.
            </li>
            <li>
              Pre-parse the breached-data using the password-matchmaker script and save it to a
              unique directory in the darkwebscan-data/parsed/ S3 bucket location.
            </li>
          </ol>
          <p>
            After completing those steps, you can use the form linked below to add a pending breach
            record to the database.
          </p>
        </Col>
      </Row>
      <Row>
        <Link className="nav-link" to="/DarkWebScan/Add">Create Breach Record</Link>
      </Row>
    </div>
  );
}

export default AddBreachInstructions;
