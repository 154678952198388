import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Form, FormGroup, FormControl, Button, Spinner,
} from 'react-bootstrap';
import './ChangePassword.css';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      password: '',
      confirmPassword: '',
      working: {
        password: true,
        confirmPassword: true,
      },
      timeout: {
        password: 0,
        confirmPassword: 0,
      },
      errors: {
        password: null,
        confirmPassword: null,
      },
      error: '',
      formValid: false,
      changePasswordPending: false,
    };
    this.validatePassword = this.validatePassword.bind(this);
    this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
    this.handleChangeOldPassword = this.handleChangeOldPassword.bind(this);
    this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillUnmount() {
    const { timeout } = this.state;
    clearTimeout(timeout.password);
    clearTimeout(timeout.confirmPassword);
  }

  handleChangeConfirmPassword(e) {
    const self = this;
    const { timeout, working } = this.state;
    working.confirmPassword = true;
    if (timeout.confirmPassword) {
      clearTimeout(timeout.confirmPassword);
    }
    timeout.confirmPassword = setTimeout(() => {
      self.validateConfirmPassword();
    }, 700);
    this.setState({
      confirmPassword: e.target.value,
      working,
      timeout,
    });
  }

  handleChangePassword(e) {
    const self = this;
    const { timeout, working } = this.state;
    working.password = true;
    if (timeout.password) {
      clearTimeout(timeout.password);
    }
    timeout.password = setTimeout(() => {
      self.validatePassword();
    }, 700);
    this.setState({
      password: e.target.value,
      working,
      timeout,
    });
  }

  handleChangeOldPassword(e) {
    this.setState({
      oldPassword: e.target.value,
    });
  }

  onSubmit(e) {
    const { auth, history } = this.props;
    const {
      oldPassword,
      password,
    } = this.state;
    this.setState({
      error: '',
      changePasswordPending: true,
    });
    e.preventDefault();
    auth.changePassword(oldPassword, password)
      .then((result) => {
        if (result.success) {
          history.push('/Dashboard');
        } else {
          this.setState({
            error: result.message,
            changePasswordPending: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          error: err.message,
          changePasswordPending: false,
        });
      });
  }

  validatePassword() {
    const {
      errors,
      password,
      working,
    } = this.state;
    if (password.length >= 11) {
      working.password = false;
      errors.password = null;
      this.setState({
        working,
        errors,
      });
    } else {
      working.password = false;
      errors.password = 'Password must be at least 11 characters';
      this.setState({
        working,
        errors,
      });
    }
  }

  validateConfirmPassword() {
    const {
      confirmPassword,
      errors,
      password,
      working,
    } = this.state;
    if (confirmPassword === password) {
      working.confirmPassword = false;
      errors.confirmPassword = null;
      this.setState({
        working,
        errors,
      });
    } else {
      working.confirmPassword = false;
      errors.confirmPassword = 'Passwords must match';
      this.setState({
        working,
        errors,
      });
    }
  }

  render() {
    const {
      changePasswordPending,
      confirmPassword,
      error,
      errors,
      formValid,
      oldPassword,
      password,
      working,
    } = this.state;
    return (
      <div className="ChangePassword">
        <Row className="Content">
          <Col
            xs={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
            lg={{ span: 8, offset: 2 }}
          >
            <Row>
              <Col xs={12} className="change-password-form">
                <Row>
                  <Col xs={12} className="change-password-form-title">
                    <h2>Change Password</h2>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="change-password-form-body">
                    <Form>
                      <FormGroup>
                        <FormControl
                          id="oldPassword"
                          name="password"
                          type="password"
                          placeholder="Old Password"
                          autoComplete="off"
                          value={oldPassword}
                          isValid={password.length > 0}
                          onChange={this.handleChangeOldPassword}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              this.onSubmit(event);
                            }
                          }}
                        />
                        <Form.Text id="oldPassword" muted>
                          {errors.oldPassword}
                        </Form.Text>
                        <FormControl
                          id="Password"
                          name="password"
                          type="password"
                          placeholder="Password"
                          autoComplete="off"
                          value={password}
                          isValid={password.length > 0 && !working.password && !errors.password}
                          isInvalid={password.length > 0 && !working.password && errors.password}
                          onChange={this.handleChangePassword}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              this.onSubmit(event);
                            }
                          }}
                        />
                        <Form.Text id="Password" muted>
                          {errors.password}
                        </Form.Text>
                        <FormControl
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          autoComplete="off"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          isValid={confirmPassword.length > 0
                            && !working.confirmPassword
                            && !errors.confirmPassword}
                          isInvalid={confirmPassword.length > 0
                            && !working.confirmPassword
                            && errors.confirmPassword}
                          onChange={this.handleChangeConfirmPassword}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              this.onSubmit(event);
                            }
                          }}
                        />
                        <Form.Text id="confirmPassword" muted>
                          {errors.confirmPassword}
                        </Form.Text>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col x={12} className="change-password-form-spinner">
                    {changePasswordPending
                      ? (
                        <Spinner animation="border" size="sm" variant="light" role="status" className="change-password-pending-spinner" id="change-password-pending-spinner">
                          <span className="sr-only">Waiting...</span>
                        </Spinner>
                      )
                      : ''}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="change-password-form-error">
                    {error}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="change-password-form-submit">
                    <Button
                      onClick={this.onSubmit}
                      className="change-password-form-submit"
                      disabled={formValid}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default ChangePassword;
