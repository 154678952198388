import './PSIRT.css';

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AdvisoryList from './AdvisoryList';

export default function PSIRT(props) {
  const { user } = props;
  return (
    <div id="PSIRT">
      <Row>
        <Col xs={12} className="page-title">
          <h1>PSIRT Management</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="management-links">
          {user && [1, 2, 6].includes(user.accountType)
            ? (
              <Button className="add-breach-button" href="/PSIRT/Add">Add Advisory</Button>
            )
            : ''}
          {user && [1, 2, 6].includes(user.accountType)
            ? (
              <Button className="add-breach-button" href="/PSIRT/Products">Manage Products</Button>
            )
            : ''}
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="dashboard-item">
          <AdvisoryList />
        </Col>
      </Row>
    </div>
  );
}

PSIRT.propTypes = {
  user: PropTypes.object,
};

PSIRT.defaultProps = {
  user: null,
};
