import './NotificationInstructions.css';

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

function NotificationInstructions(props) {
  const {
    isNotifyingWGC,
    isNotifyingDome,
    isWorking,
    toggleBreach,
    management,
    sendDomeNotification,
    sendWGCNotification,
  } = props;
  return (
    <div id="NotificationInstructions">
      <Row>
        <Col xs={12} className="dashboard-item">
          <h5>Next Steps: Send Notifications</h5>
          <p>
            The breach is enabled and searchable within the dark web scan database. If you need to
            quickly disable the breach, hit the disable button below. Next, you need to trigger
            notifications to both WatchGuard Cloud and Panda Dome notification subscribers. To
            send notifications, hit the buttons below.
          </p>
          <Button
            disabled={isNotifyingWGC || management.notification.wgc.started}
            onClick={sendWGCNotification}
            type="submit"
            id="send-wgc-notify"
            className="breach-control-button"
          >
            {management.notification.wgc.completed
              ? 'WGC Notification Complete'
              : ''}
            {management.notification.wgc.started
              && !management.notification.wgc.completed
              ? 'WGC Notification Started'
              : ''}
            {!management.notification.wgc.started
              && !management.notification.wgc.completed
              ? 'Send WGC Notification'
              : ''}
          </Button>
          <Button
            disabled={isNotifyingDome || management.notification.dome.started}
            onClick={sendDomeNotification}
            type="submit"
            id="send-dome-notify"
            className="breach-control-button"
          >
            {management.notification.dome.completed
              ? 'Dome Notification Complete'
              : ''}
            {management.notification.dome.started
              && !management.notification.dome.completed
              ? 'Dome Notification Started'
              : ''}
            {!management.notification.dome.started
              && !management.notification.dome.completed
              ? 'Send Dome Notification'
              : ''}
          </Button>
          <Button
            disabled={isNotifyingWGC || isNotifyingDome}
            onClick={toggleBreach}
            type="submit"
            id="toggle-breach"
            className="breach-control-button"
          >
            Disable
          </Button>
          {isWorking || isNotifyingWGC || isNotifyingDome
            ? (
              <div className="submit-pending">
                <i className="fas fa-spinner fa-spin submit-pending-icon" />
              </div>
            )
            : ''}
        </Col>
      </Row>

    </div>
  );
}

NotificationInstructions.propTypes = {
  isWorking: PropTypes.bool.isRequired,
  isNotifyingDome: PropTypes.bool.isRequired,
  isNotifyingWGC: PropTypes.bool.isRequired,
  management: PropTypes.object.isRequired,
  toggleBreach: PropTypes.func.isRequired,
  sendDomeNotification: PropTypes.func.isRequired,
  sendWGCNotification: PropTypes.func.isRequired,
};

export default NotificationInstructions;
