import './EditAdvisoryProducts.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import axios from '../../../../utils/axios';

import LoadingMessage from './LoadingMessage';
import SelectProductFamily from './SelectProductFamily';
import SelectProducts from './SelectProducts';

function getProductList() {
  return new Promise((resolve, reject) => {
    axios.get('https://api.tat.wgtl.io/api/v1/psirt/product/all')
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for products'));
      })
      .catch(() => reject(new Error('Error searching for products')));
  });
}

function saveAdvisory(postData) {
  return new Promise((resolve, reject) => {
    axios.post('https://api.tat.wgtl.io/api/v1/psirt/advisory/save', postData)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        return reject(new Error('Error saving advisory'));
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          return reject(error.response.data);
        }
        return reject(error);
      });
  });
}

class EditAdvisoryProducts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stage: 0,
      isLoading: true,
      errorMessage: '',
      products: null,
      productFamilies: [],
      selectedProductFamilies: [],
      selectedProducts: {},
    };

    this.changeProductFamily = this.changeProductFamily.bind(this);
    this.changeSelectedProducts = this.changeSelectedProducts.bind(this);
    this.nextStage = this.nextStage.bind(this);
    this.prevStage = this.prevStage.bind(this);
    this.submitAdvisory = this.submitAdvisory.bind(this);
  }

  componentDidMount() {
    const {
      advisory,
    } = this.props;
    console.log(advisory);
    getProductList()
      .then((products) => {
        products.sort((a, b) => a.product_platform - b.product_platform);
        let productFamilies = [];
        if (products.length > 0) {
          productFamilies = [...new Set(products.map((e) => e.product_family))];
        }
        const selectedProducts = Object.fromEntries(productFamilies.map((e) => [e, []]));

        this.setState({
          isLoading: false,
          stage: 1,
          products,
          productFamilies,
          selectedProducts,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isLoading: false,
          errorMessage: error.message,
        });
      });
  }

  changeProductFamily(t) {
    this.setState({
      selectedProductFamilies: t,
    });
  }

  changeSelectedProducts(productFamily, newList) {
    this.setState((prevState) => {
      const selectedProducts = { ...prevState.selectedProducts };
      selectedProducts[productFamily] = newList;
      return { selectedProducts };
    });
  }

  buildProductArray() {
    const {
      products,
      selectedProducts,
    } = this.state;

    const productArray = [];

    Object.keys(selectedProducts).forEach((family) => {
      selectedProducts[family].forEach((p) => {
        const product = products.find((o) => o.product_family === family && o.product_branch === p);
        if (product) {
          productArray.push(product);
        }
      });
    });

    return productArray;
  }

  submitAdvisory() {
    const {
      advisory,
    } = this.props;

    const productArray = this.buildProductArray();

    const advisoryContent = {
      _id: advisory._id,
      title: advisory.title,
      summary: advisory.summary,
      affected: advisory.affected,
      resolution: advisory.resolution,
      cve: advisory.cve,
      impact: advisory.impact,
      status: advisory.status,
      cvss_score: advisory.cvss_score,
      cvss_vector: advisory.cvss_vector,
      products: productArray,
      has_workaround: advisory.workaround_available,
      workaround: advisory.workaround,
      credits: advisory.credits,
      references: advisory.references,
    };

    return saveAdvisory(advisoryContent)
      .then((response) => {
        const { advisorySaved } = this.props;
        advisorySaved(response.advisory);
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.message,
        });
      });
  }

  nextStage(event) {
    event.preventDefault();
    this.setState((prevState) => {
      const stage = prevState.stage + 1;

      if (prevState.stage === 2) {
        return this.submitAdvisory();
      }

      return {
        stage,
      };
    });
  }

  prevStage() {
    const {
      close,
    } = this.props;

    this.setState((prevState) => {
      if (prevState.stage === 1) {
        return close();
      }

      const stage = prevState.stage - 1;

      return {
        stage,
      };
    });
  }

  render() {
    const {
      isLoading,
      products,
      stage,
      productFamilies,
      selectedProductFamilies,
      selectedProducts,
      errorMessage,
    } = this.state;

    return (
      <div id="EditAdvisoryProducts">
        <Row>
          <Col xs={12} className="page-title">
            <h1>Edit Affected Products</h1>
          </Col>
        </Row>
        {errorMessage
          ? <div className="error-message">{errorMessage}</div>
          : ''}
        {isLoading && stage === 0
          ? <LoadingMessage />
          : ''}
        {stage === 1
          ? (
            <SelectProductFamily
              productFamilies={productFamilies}
              selected={selectedProductFamilies}
              onChange={this.changeProductFamily}
              nextStage={this.nextStage}
              prevStage={this.prevStage}
            />
          )
          : ''}
        {stage === 2
          ? (
            <SelectProducts
              productFamilies={selectedProductFamilies}
              products={products}
              selected={selectedProducts}
              onChange={this.changeSelectedProducts}
              nextStage={this.submitAdvisory}
              prevStage={this.prevStage}
            />
          )
          : ''}
      </div>
    );
  }
}

EditAdvisoryProducts.propTypes = {
  close: PropTypes.func.isRequired,
  advisorySaved: PropTypes.func.isRequired,
  advisory: PropTypes.object.isRequired,
};

export default EditAdvisoryProducts;
