import './Manage.css';

import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
// import { Link } from 'react-router-dom';

import IncidentTable from '../../IncidentTable';
import AddUser from '../../Forms/AddUser';

import axios from '../../../utils/axios';

function getUsers() {
  return new Promise((resolve, reject) => {
    axios.get('https://id.wgtl.io/users/')
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for users'));
      })
      .catch(() => reject(new Error('Error searching for users')));
  });
}

function disableUser(userId) {
  return new Promise((resolve, reject) => {
    const postData = {
      userId,
    };
    axios.post('https://id.wgtl.io/disableuser', postData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            return resolve({ success: true });
          }
          return reject(new Error(res.data.message));
        }
        return reject(new Error('Error disabling user'));
      })
      .catch((error) => reject(error));
  });
}

function enableUser(userId) {
  return new Promise((resolve, reject) => {
    const postData = {
      userId,
    };
    axios.post('https://id.wgtl.io/enableuser', postData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            return resolve({ success: true });
          }
          return reject(new Error(res.data.message));
        }
        return reject(new Error('Error enabling user'));
      })
      .catch((error) => reject(error));
  });
}

function changeUserRole(userId, accountType) {
  return new Promise((resolve, reject) => {
    const postData = {
      userId,
      accountType,
    };
    axios.post('https://id.wgtl.io/changerole', postData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            return resolve({ success: true });
          }
          return reject(new Error(res.data.message));
        }
        return reject(new Error('Error changing user role'));
      })
      .catch((error) => reject(error));
  });
}

class Manage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: true,
      users: null,
      errorMessage: null,
      updating: [],
    };
    this.userCreated = this.userCreated.bind(this);
    this.disableUser = this.disableUser.bind(this);
    this.enableUser = this.enableUser.bind(this);
    this.toggleUserRole = this.toggleUserRole.bind(this);
    this.handleCompleteAction = this.handleCompleteAction.bind(this);
  }

  componentDidMount() {
    getUsers()
      .then((results) => {
        results.sort(this.sortUserList);
        this.setState({
          isSearching: false,
          users: results,
        });
      })
      .catch((error) => {
        this.setState({
          isSearching: false,
          errorMessage: error.message,
        });
      });
  }

  handleCompleteAction(userId, error) {
    if (!error) {
      getUsers()
        .then((results) => {
          results.sort(this.sortUserList);
          const { updating } = this.state;
          const newUpdating = updating.filter((o) => o !== userId);
          this.setState({
            isSearching: false,
            users: results,
            updating: newUpdating,
          });
        })
        .catch((updateError) => {
          const { updating } = this.state;
          const newUpdating = updating.filter((o) => o !== userId);
          this.setState({
            isSearching: false,
            errorMessage: updateError.message,
            updating: newUpdating,
          });
        });
    } else {
      const { updating } = this.state;
      const newUpdating = updating.filter((o) => o !== userId);
      this.setState({
        errorMessage: error.message,
        updating: newUpdating,
      });
    }
  }

  disableUser(userId) {
    const { updating } = this.state;
    const newUpdating = updating.slice();
    newUpdating.splice(0, 0, userId);
    this.setState({
      updating: newUpdating,
    });
    disableUser(userId)
      .then(() => {
        this.handleCompleteAction(userId);
      })
      .catch((error) => {
        this.handleCompleteAction(userId, error);
      });
  }

  enableUser(userId) {
    const { updating } = this.state;
    const newUpdating = updating.slice();
    newUpdating.splice(0, 0, userId);
    this.setState({
      updating: newUpdating,
    });
    enableUser(userId)
      .then(() => {
        this.handleCompleteAction(userId);
      })
      .catch((error) => {
        this.handleCompleteAction(userId, error);
      });
  }

  toggleUserRole(event, userId) {
    const { updating } = this.state;
    const newUpdating = updating.slice();
    newUpdating.splice(0, 0, userId);
    this.setState({
      updating: newUpdating,
    });
    changeUserRole(userId, parseInt(event.target.value, 10))
      .then(() => {
        this.handleCompleteAction(userId);
      })
      .catch((error) => {
        this.handleCompleteAction(userId, error);
      });
  }

  userCreated() {
    return new Promise((resolve, reject) => {
      getUsers()
        .then((results) => {
          this.setState({
            isSearching: false,
            users: results,
          }, resolve());
        })
        .catch((error) => {
          this.setState({
            isSearching: false,
          }, reject(error));
        });
    });
  }

  sortUserList(a, b) {
    if (a.enabled && b.enabled) {
      return a.accountType - b.accountType;
    }
    if (a.enabled) {
      return -1;
    }
    return 1;
  }

  render() {
    const {
      isSearching,
      users,
      errorMessage,
      updating,
    } = this.state;

    function getSelectedRole(accountType) {
      switch (accountType) {
        case 1:
          return '1';
        case 2:
          return '2';
        case 3:
          return '3';
        default:
          return '10';
      }
    }

    const columns = [{
      dataField: '_id',
      hidden: true,
      text: 'id',
    }, {
      dataField: 'username',
      text: 'Username',
    }, {
      dataField: 'firstName',
      text: 'First Name',
    }, {
      dataField: 'lastName',
      text: 'Last Name',
    }, {
      dataField: 'email',
      text: 'Email',
    }, {
      dataField: 'role',
      isDummyField: true,
      text: 'Role',
      formatter: (cell, row) => (
        <Form>
          <Form.Control
            as="select"
            className="role-select"
            onChange={(event) => this.toggleUserRole(event, row._id)}
            defaultValue={getSelectedRole(row.accountType)}
          >
            <option value={1}>Admin</option>
            <option value={2}>Analyst</option>
            <option value={3}>Technician</option>
            <option value={4}>Dark Web Read</option>
            <option value={5}>PSIRT Read</option>
            <option value={6}>PSIRT Publish</option>
          </Form.Control>
        </Form>
      ),
    }, {
      dataField: 'disable',
      isDummyField: true,
      text: 'Disable',
      formatExtraData: updating,
      formatter: (cell, row, rowIndex, updatingList) => {
        if (updatingList.includes(row._id)) {
          return (
            <span className="updating">
              <i className="fas fa-spinner fa-spin searching-spinner" />
            </span>
          );
        }
        if (row.enabled) {
          return (
            <div
              className="toggle-account-status"
              role="button"
              onClick={() => this.disableUser(row._id)}
              onKeyPress={() => this.disableUser(row._id)}
              tabIndex="0"
            >
              Disable
            </div>
          );
        }
        return (
          <div
            className="toggle-account-status"
            role="button"
            onClick={() => this.enableUser(row._id)}
            onKeyPress={() => this.enableUser(row._id)}
            tabIndex="0"
          >
            Enable
          </div>
        );
      },
    }];

    const noTableData = () => {
      if (isSearching) {
        return (<div className="loading">Loading</div>);
      }
      return (<div className="no-data-label">No users found</div>);
    };

    return (
      <div id="ManageUsers">
        {isSearching
          ? (
            <div className="searching"><i className="fas fa-spinner fa-spin searching-spinner" /></div>
          )
          : ''}
        {users
          ? (
            <div>
              <Row>
                <Col xs={12}>
                  <IncidentTable
                    incidents={users}
                    columns={columns}
                    keyField="_id"
                    noTableData={noTableData}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <AddUser userCreated={this.userCreated} />
                </Col>
              </Row>
            </div>
          )
          : ''}
        {errorMessage
          ? (
            <Row>
              <Col xs={12} className="error-message">
                {errorMessage}
              </Col>
            </Row>
          )
          : ''}
      </div>
    );
  }
}

export default Manage;
