import './SelectProducts.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Multiselect } from 'multiselect-react-dropdown';

export default function SelectProducts(props) {
  const {
    productFamilies,
    products,
    selected,
    onChange,
    nextStage,
    prevStage,
  } = props;
  console.log(selected);

  const splitProducts = {};

  productFamilies.forEach((e) => {
    splitProducts[e] = [];
  });

  products.forEach((e) => {
    if (productFamilies.includes(e.product_family)) {
      splitProducts[e.product_family].push(e.product_branch);
    }
  });

  let readyToSubmit = true;
  productFamilies.forEach((p) => {
    if (selected[p].length === 0) readyToSubmit = false;
  });

  return (
    <div id="SelectProducts">
      <div id="SelectProductsContainer">
        <h3>
          Which Products Are Affected?
        </h3>
        {productFamilies && productFamilies.map((p) => (
          <div
            className="SelectProductSubContainer"
            key={p}
          >
            <h3 className="ProductFamilyName">{p}</h3>
            <Multiselect
              options={splitProducts[p]}
              placeholder="Products"
              selectedValues={selected[p]}
              onSelect={(newList) => onChange(p, newList)}
              onRemove={(newList) => onChange(p, newList)}
              isObject={false}
              avoidHighlightFirstOption
              hidePlaceholder
            />
          </div>
        ))}
        <div className="stage-control">
          <Button
            onClick={prevStage}
            type="submit"
            id="prev-stage"
            className="stage-control-button"
          >
            Back
          </Button>
          <Button
            onClick={nextStage}
            type="submit"
            id="next-stage"
            className="stage-control-button"
            disabled={!readyToSubmit}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

SelectProducts.propTypes = {
  productFamilies: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  nextStage: PropTypes.func.isRequired,
  prevStage: PropTypes.func.isRequired,
};
