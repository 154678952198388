import './ThreatSearchForm.css';

import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import queryString from 'query-string';

import axios from '../../../utils/axios';

function searchThreats(searchParams) {
  return new Promise((resolve, reject) => {
    axios.get(`https://api.tat.wgtl.io/api/v1/search?${searchParams}`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for threats'));
      })
      .catch(() => reject(new Error('Error searching for threats')));
  });
}

class ThreatSearchForm extends React.Component {
  constructor(props) {
    super(props);
    const {
      searchFilter,
    } = this.props;
    this.state = {
      searchValue: searchFilter,
      isSearching: false,
      advancedOpen: false,
      sort: 0,
      order: 0,
    };
    this.toggleAdvancedControls = this.toggleAdvancedControls.bind(this);
    this.changeSearchValue = this.changeSearchValue.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.changeSortOrder = this.changeSortOrder.bind(this);
    this.submitSearchForm = this.submitSearchForm.bind(this);
    this.handleSearchResults = this.handleSearchResults.bind(this);
  }

  handleSearchResults(searchValue, results) {
    const {
      searchResultsReceived,
    } = this.props;
    this.setState({
      isSearching: false,
    });
    searchResultsReceived({
      search: searchValue,
      results: results.results,
    });
  }

  changeSearchValue(event) {
    this.setState({
      searchValue: event.target.value,
    });
  }

  changeSort(event) {
    const {
      searchValue,
    } = this.state;
    this.setState({
      sort: event.target.value,
    }, () => {
      if (searchValue !== '') {
        this.submitSearchForm();
      }
    });
  }

  changeSortOrder(event) {
    const {
      searchValue,
    } = this.state;
    this.setState({
      order: event.target.value,
    }, () => {
      if (searchValue !== '') {
        this.submitSearchForm();
      }
    });
  }

  toggleAdvancedControls() {
    const {
      advancedOpen,
    } = this.state;
    this.setState({
      advancedOpen: !advancedOpen,
    });
  }

  submitSearchForm(event) {
    const {
      searchValue,
      sort,
      order,
    } = this.state;
    if (event) {
      event.preventDefault();
    }
    const queryParams = {
      q: searchValue,
    };
    if (sort !== 0) {
      queryParams.s = sort;
    }
    if (order !== 0) {
      queryParams.o = order;
    }
    const search = queryString.stringify(queryParams);

    this.setState({
      isSearching: true,
    });
    searchThreats(search)
      .then((results) => {
        this.handleSearchResults(searchValue, results);
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isSearching: false,
        });
      });
  }

  render() {
    const {
      searchValue,
      isSearching,
      advancedOpen,
    } = this.state;
    return (
      <div id="ThreatSearchForm">
        <Row>
          <Col xs={12} className="search-section">
            <Form onSubmit={this.submitSearchForm}>
              <Form.Row className="align-items-center">
                <Col xs={{ span: 8, offset: 1 }} md={{ span: 9, offset: 1 }} className="form-col">
                  <Form.Group>
                    <Form.Label htmlFor="threat-search" srOnly>
                      Search
                    </Form.Label>
                    <Form.Control
                      id="threat-search"
                      type="text"
                      name="search"
                      placeholder="search"
                      className="search-field"
                      value={searchValue || ''}
                      onChange={this.changeSearchValue}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
                <Col xs="auto" className="form-col">
                  <Button type="submit">Search</Button>
                  {isSearching
                    ? (
                      <div className="search-pending">
                        <i className="fas fa-spinner fa-spin search-pending-icon" />
                      </div>
                    )
                    : ''}
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </Row>
        {advancedOpen
          ? (
            <Row>
              <Col xs={12} className="advanced-search-section">
                <Form>
                  <Form.Row>
                    <Col xs={4} className="form-col">
                      <Form.Group as={Row} className="advanced-search-controls">
                        <Form.Label column xs={12} lg={4} htmlFor="threat-search-advanced">
                          Sort Field
                        </Form.Label>
                        <Col xs={12} lg={8}>
                          <Form.Control as="select" onChange={this.changeSort}>
                            <option value={0}>Last Seen</option>
                            <option value={1}>First Seen</option>
                            <option value={2}>Incidents</option>
                            <option value={3}>Unique Victims</option>
                            <option value={4}>Name</option>
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={4} className="form-col">
                      <Form.Group as={Row} className="advanced-search-controls">
                        <Form.Label column xs={12} lg={4} htmlFor="threat-search-advanced">
                          Sort Type
                        </Form.Label>
                        <Col xs={12} lg={8}>
                          <Form.Control as="select" onChange={this.changeSortOrder}>
                            <option value={0}>Desc</option>
                            <option value={1}>Asc</option>
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={4} className="form-col">
                      <Form.Group as={Row} className="advanced-search-controls">
                        <Form.Label column xs={12} lg={3} htmlFor="threat-search-advanced">
                          Threat
                        </Form.Label>
                        <Col xs={12} lg={8}>
                          <Form.Control as="select">
                            <option>All</option>
                            <option>Malware</option>
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Form>
              </Col>
            </Row>
          )
          : ''}
        <Row>
          <Col xs={12} className="advanced-toggle">
            <div
              className="advanced-toggle-button"
              role="button"
              tabIndex="0"
              onClick={this.toggleAdvancedControls}
              onKeyPress={this.toggleAdvancedControls}
            >
              ...
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ThreatSearchForm.propTypes = {
  searchResultsReceived: PropTypes.func.isRequired,
  searchFilter: PropTypes.string,
};

ThreatSearchForm.defaultProps = {
  searchFilter: '',
};

export default ThreatSearchForm;
