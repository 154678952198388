import './SearchResultCategory.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import MalwareThreatTile from '../MalwareThreatTile';
import FileThreatTile from '../FileThreatTile';

function SearchResultCategory(props) {
  const {
    searchTerm,
    categoryName,
    searchResult,
  } = props;
  return (
    <div id={`search-result-${categoryName}`} className="SearchResultCategory">
      <Row>
        <Col xs={12} className="search-result-category-title">
          <h1>{categoryName}</h1>
        </Col>
      </Row>
      {categoryName === 'Malware'
        ? (
          <Row>
            <Col xs={12} className="search-result-category-content">
              {searchResult && searchResult.map((result) => (
                <div key={result._id} className="search-result-item">
                  <MalwareThreatTile threat={result} />
                </div>
              ))}
              {!searchResult || searchResult.length === 0
                ? (
                  <div className="search-result-item">
                    No matches for malware names
                  </div>
                )
                : ''}
            </Col>
          </Row>
        )
        : ''}
      {categoryName === 'File'
        ? (
          <Row>
            <Col xs={12} className="search-result-category-content">
              <div className="search-result-item">
                <FileThreatTile hash={searchTerm} threat={searchResult} />
              </div>
            </Col>
          </Row>
        )
        : ''}
    </div>
  );
}

SearchResultCategory.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  searchResult: PropTypes.any,
};

SearchResultCategory.defaultProps = {
  searchResult: [],
};

export default SearchResultCategory;
