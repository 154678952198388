import './UploadImage.css';

import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'react-bootstrap';

import config from '../../../utils/config.json';
import axios from '../../../utils/axios';

function uploadImageFile(image) {
  return new Promise((resolve, reject) => {
    const postData = {
      image,
    };
    axios.post(`${config.apiUrl}/dws/breach/image`, postData)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        return reject(new Error('Error saving image'));
      })
      .catch(() => reject(new Error('Error saving image')));
  });
}

function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class UploadImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      errorMessage: null,
    };
    this.onSelectImage = this.onSelectImage.bind(this);
  }

  onSelectImage(event) {
    const {
      imageUploaded,
    } = this.props;
    const acceptedTypes = [
      'image/png',
      'image/jpeg',
    ];
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (file && !acceptedTypes.includes(file.type)) {
      this.setState({
        errorMessage: 'Image must be a jpg or png',
      });
    } else if (file && file.size > 3000000) {
      this.setState({
        errorMessage: 'Image must be less than 3MB',
      });
    } else {
      this.setState({
        isUploading: true,
        errorMessage: null,
      });
      toBase64(file)
        .then((base64Image) => {
          const imgData = base64Image.split(',')[1];
          uploadImageFile(imgData)
            .then((response) => {
              this.setState({
                isUploading: false,
              });
              imageUploaded(response.url);
            })
            .catch((error) => {
              this.setState({
                isUploading: false,
                errorMessage: error.message,
              });
            });
        });
    }
  }

  render() {
    const {
      errorMessage,
      isUploading,
    } = this.state;

    const {
      logoPath,
    } = this.props;

    return (
      <div id="UploadImage">
        <div id="UploadImageContent">
          <img className="breach-image" src={logoPath || 'https://images.darkwebscan.wgtl.io/default.png'} alt="Breach logo" />
          <div className="upload-image-message">
            Select a JPEG or PNG less than 3MB in size and with a square aspect ratio
          </div>
          <div className="upload-image-controls">
            <Button
              className="upload-image-button"
              type="button"
              tabIndex="0"
              onClick={() => { this.upload.click(); }}
              onKeyPress={() => { this.upload.click(); }}
            >
              Upload Image
            </Button>
            <input
              id="ImageUpload"
              type="file"
              ref={(el) => { this.upload = el; }}
              style={{ display: 'none' }}
              onChange={this.onSelectImage}
            />
          </div>
          {isUploading
            ? (
              <div className="loading-spinner">
                <i className="fas fa-spinner fa-spin spinner-icon" />
              </div>
            )
            : ''}
          <div className="upload-image-error">
            {errorMessage}
          </div>
        </div>
      </div>
    );
  }
}

UploadImage.propTypes = {
  logoPath: PropTypes.string,
  imageUploaded: PropTypes.func.isRequired,
};

UploadImage.defaultProps = {
  logoPath: null,
};

export default UploadImage;
