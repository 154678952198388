import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Form, FormGroup, FormControl, Button, Spinner,
} from 'react-bootstrap';
import qs from 'qs';
import './Login.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      remember: false,
      error: '',
      formValid: false,
      loginPending: false,
      redirect: '/',
    };
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeRemember = this.handleChangeRemember.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const { redirect } = qs.parse(location.search, { ignoreQueryPrefix: true });
    console.log(redirect);
    if (redirect && redirect.charAt(0) === '/') {
      this.setState({
        redirect,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { user, history } = this.props;
    if (user && user !== prevProps.user) {
      const { redirect } = this.state;
      history.push(redirect);
    }
  }

  handleChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  handleChangeRemember() {
    const { remember } = this.state;
    this.setState({
      remember: !remember,
    });
  }

  onSubmit(e) {
    const {
      auth,
      history,
      updateProfile,
    } = this.props;
    const {
      email,
      password,
      redirect,
      remember,
    } = this.state;
    e.preventDefault();
    this.setState({
      error: '',
      loginPending: true,
    });
    auth.login(email, password, remember)
      .then((result) => {
        if (result.success) {
          updateProfile()
            .then(() => {
              history.push(redirect);
            });
        }
      })
      .catch((err) => {
        this.setState({
          error: err.message,
          loginPending: false,
        });
      });
  }

  render() {
    const {
      email,
      error,
      formValid,
      loginPending,
      password,
      remember,
    } = this.state;
    return (
      <div className="Login">
        <Row className="Content">
          <Col
            xs={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
            lg={{ span: 8, offset: 2 }}
          >
            <Row>
              <Col xs={12} className="login-form">
                <Row>
                  <Col xs={12} className="login-form-title">
                    <h2>Log In</h2>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="login-form-body">
                    <Form>
                      <FormGroup>
                        <Form.Label htmlFor="loginEmail">Email</Form.Label>
                        <FormControl
                          id="loginEmail"
                          name="email"
                          type="email"
                          value={email}
                          onChange={this.handleChangeEmail}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              this.onSubmit(event);
                            }
                          }}
                        />
                        <Form.Text id="loginEmail" muted />
                        <Form.Label htmlFor="loginPassword">Password</Form.Label>
                        <FormControl
                          id="loginPassword"
                          name="password"
                          type="password"
                          autoComplete="off"
                          value={password}
                          onChange={this.handleChangePassword}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              this.onSubmit(event);
                            }
                          }}
                        />
                        <Form.Text id="loginPassword" muted />
                        <Form.Check
                          id="loginRemember"
                          name="remember"
                          type="checkbox"
                          label="Remember Me"
                          checked={remember}
                          onChange={this.handleChangeRemember}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              this.onSubmit(event);
                            }
                          }}
                        />
                        <Form.Text id="loginRemember" muted />

                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="login-form-spinner">
                    {loginPending
                      ? (
                        <Spinner animation="border" size="sm" variant="light" role="status" className="login-pending-spinner" id="login-pending-spinner">
                          <span className="sr-only">Waiting...</span>
                        </Spinner>
                      )
                      : ''}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="login-form-error">
                    {error}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="login-form-submit">
                    <Button
                      onClick={this.onSubmit}
                      className="login-form-submit"
                      disabled={formValid}
                    >
                      Log In
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  updateProfile: PropTypes.func.isRequired,
};

Login.defaultProps = {
  user: null,
};

export default Login;
