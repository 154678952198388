import './ViewAnalyses.css';

import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import CreateAnalysis from '../../Forms/CreateAnalysis';

function ViewAnalyses(props) {
  const {
    analyses,
    deleteAnalysis,
    threatId,
    analysisCreated,
    user,
  } = props;

  function formatTime(d) {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ];
    const date1 = new Date();
    const date2 = new Date(d);
    const diff = Math.floor(date1.getTime() - date2.getTime());
    const day = 1000 * 60 * 60 * 24;
    const minute = 1000 * 60;
    const minutes = Math.floor(diff / minute);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(diff / day);
    const months = Math.floor(days / 31);
    const years = Math.floor(months / 12);
    let message = '';
    if (years > 0 || months > 0) {
      message = `${monthNames[date2.getMonth()]} ${date2.getDate()}, ${date2.getFullYear()}`;
    } else if (days > 0) {
      message += days;
      if (days > 1) {
        message += ' days ago';
      } else {
        message += ' day ago';
      }
    } else if (hours > 0) {
      message += hours;
      if (hours > 1) {
        message += ' hours ago';
      } else {
        message += ' hour ago';
      }
    } else if (minutes > 0) {
      message += minutes;
      if (minutes > 1) {
        message += ' minutes ago';
      } else {
        message += ' minute ago';
      }
    } else {
      message = 'moments ago';
    }
    return message;
  }
  return (
    <div id="ViewAnalyses">
      {!analyses || analyses.length === 0
        ? ''
        : (
          <h3>Current</h3>
        )}
      {analyses && analyses.length > 0
        ? (
          <Row key={analyses[0]._id}>
            <Col xs={12} id="current-analysis">
              <div className="analysis-details">
                Created
                {' '}
                {formatTime(analyses[0].date)}
                {' '}
                by
                {' '}
                {analyses[0].createdBy}
              </div>
              <div className="disable" role="button" onClick={() => deleteAnalysis(analyses[0]._id)} onKeyPress={deleteAnalysis} tabIndex="0">
                <i className="fas fa-times-circle disable-button" />
              </div>
              <div className="analysis-content">
                {analyses[0].publicAnalysis}
              </div>
            </Col>
          </Row>
        )
        : ''}
      {analyses && analyses.length > 1
        ? (
          <h3>Previous</h3>
        )
        : ''}
      {analyses && analyses.map((analysis, index) => {
        if (index > 0) {
          return (
            <Row key={analysis._id}>
              <Col xs={12} className="previous-analysis">
                <div className="analysis-details">
                  Created
                  {' '}
                  {formatTime(analysis.date)}
                  {' '}
                  by
                  {' '}
                  {analysis.createdBy}
                </div>
                <div className="disable" role="button" onClick={() => deleteAnalysis(analysis._id)} onKeyPress={deleteAnalysis} tabIndex="0">
                  <i className="fas fa-times-circle disable-button" />
                </div>
                <div className="analysis-content">
                  {analyses[0].publicAnalysis}
                </div>
              </Col>
            </Row>
          );
        }
        return '';
      })}
      {user && user.accountType <= 2
        ? (
          <Row>
            <Col xs={12} className="create-analysis">
              <CreateAnalysis threatId={threatId} analysisCreated={analysisCreated} />
            </Col>
          </Row>
        )
        : ''}
    </div>
  );
}

ViewAnalyses.propTypes = {
  analyses: PropTypes.array,
  user: PropTypes.object,
  deleteAnalysis: PropTypes.func.isRequired,
  threatId: PropTypes.string.isRequired,
  analysisCreated: PropTypes.func.isRequired,
};

ViewAnalyses.defaultProps = {
  analyses: [],
  user: null,
};

export default ViewAnalyses;
