import './BreachStatusBlock.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

function BreachStatusBlock(props) {
  const {
    management,
    enabled,
  } = props;

  function getUploadBlock() {
    if (!management || !management.parsing) {
      return (
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 2, offset: 1 }}
          className="status-icon error"
        >
          Error Processing
        </Col>
      );
    }
    if (management.parsing.completed && management.parsing.error > 0) {
      return (
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 2, offset: 1 }}
          className="status-icon error"
        >
          Error Processing
        </Col>
      );
    }
    if (management.parsing.completed) {
      return (
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 2, offset: 1 }}
          className="status-icon completed"
        >
          Processed
        </Col>
      );
    }
    if (management.parsing.started) {
      return (
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 2, offset: 1 }}
          className="status-icon pending"
        >
          Processing
        </Col>
      );
    }
    return (
      <Col
        xs={{ span: 10, offset: 1 }}
        md={{ span: 2, offset: 1 }}
        className="status-icon incomplete"
      >
        Awaiting Processing
      </Col>
    );
  }

  function getEnabledBlock() {
    if (enabled) {
      return (
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 2, offset: 1 }}
          className="status-icon completed"
        >
          Enabled
        </Col>
      );
    }
    if (management && management.parsing && management.parsing.completed) {
      return (
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 2, offset: 1 }}
          className="status-icon error"
        >
          Not Enabled
        </Col>
      );
    }
    return (
      <Col
        xs={{ span: 10, offset: 1 }}
        md={{ span: 2, offset: 1 }}
        className="status-icon incomplete"
      >
        Not Enabled
      </Col>
    );
  }

  function getWGCNotificationBlock() {
    if (!management || !management.notification || !management.notification.wgc) {
      return (
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 2, offset: 1 }}
          className="status-icon error"
        >
          Notification (WGC)
        </Col>
      );
    }
    if (management.notification.wgc.completed
    && management.notification.wgc.error > 0) {
      return (
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 2, offset: 1 }}
          className="status-icon error"
        >
          Notification (WGC)
        </Col>
      );
    }
    if (management.notification.wgc.completed) {
      return (
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 2, offset: 1 }}
          className="status-icon completed"
        >
          Notification (WGC)
        </Col>
      );
    }
    if (management.notification.wgc.started) {
      return (
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 2, offset: 1 }}
          className="status-icon pending"
        >
          Notification (WGC)
        </Col>
      );
    }
    return (
      <Col
        xs={{ span: 10, offset: 1 }}
        md={{ span: 2, offset: 1 }}
        className="status-icon incomplete"
      >
        Notification (WGC)
      </Col>
    );
  }

  function getDomeNotificationBlock() {
    console.log(management);
    if (!management || !management.notification || !management.notification.dome) {
      return (
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 2, offset: 1 }}
          className="status-icon error"
        >
          Notification (Dome)
        </Col>
      );
    }
    if (management.notification.dome.completed
    && management.notification.dome.error > 0) {
      return (
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 2, offset: 1 }}
          className="status-icon error"
        >
          Notification (Dome)
        </Col>
      );
    }
    if (management.notification.dome.completed) {
      return (
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 2, offset: 1 }}
          className="status-icon completed"
        >
          Notification (Dome)
        </Col>
      );
    }
    if (management.notification.dome.started) {
      return (
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 2, offset: 1 }}
          className="status-icon pending"
        >
          Notification (Dome)
        </Col>
      );
    }
    return (
      <Col
        xs={{ span: 10, offset: 1 }}
        md={{ span: 2, offset: 1 }}
        className="status-icon incomplete"
      >
        Notification (Dome)
      </Col>
    );
  }

  return (
    <div id="BreachStatusBlock">
      <Row>
        {getUploadBlock()}
        {getEnabledBlock()}
        {getWGCNotificationBlock()}
        {getDomeNotificationBlock()}

      </Row>
    </div>
  );
}

BreachStatusBlock.propTypes = {
  management: PropTypes.object.isRequired,
  enabled: PropTypes.bool.isRequired,
};

export default BreachStatusBlock;
