import './Products.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';

import axios from '../../../utils/axios';

import ProductList from '../ProductList';
import AddProduct from './AddProduct';
import EditProduct from './EditProduct';
import PrimaryButton from '../../Buttons/PrimaryButton';

function getProductList() {
  return new Promise((resolve, reject) => {
    axios.get('https://api.tat.wgtl.io/api/v1/psirt/product/all')
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for products'));
      })
      .catch(() => reject(new Error('Error searching for products')));
  });
}

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: true,
      addProductOpened: false,
      selectedProduct: null,
      errorMessage: '',
      products: [],
    };

    this.productAdded = this.productAdded.bind(this);
    this.openAddProduct = this.openAddProduct.bind(this);
    this.closeAddProduct = this.closeAddProduct.bind(this);
    this.openEditProduct = this.openEditProduct.bind(this);
    this.closeEditProduct = this.closeEditProduct.bind(this);
  }

  componentDidMount() {
    getProductList()
      .then((products) => {
        products.sort((a, b) => a.product_family - b.product_family);
        this.setState({
          isSearching: false,
          products,
        });
      })
      .catch((error) => {
        this.setState({
          isSearching: false,
          errorMessage: error.message,
        });
      });
  }

  productAdded() {
    getProductList()
      .then((products) => {
        products.sort((a, b) => a.product_family - b.product_family);
        this.setState({
          isSearching: false,
          addProductOpened: false,
          products,
        });
      })
      .catch((error) => {
        this.setState({
          isSearching: false,
          addProductOpened: false,
          errorMessage: error.message,
        });
      });
  }

  openAddProduct() {
    this.setState({
      addProductOpened: true,
    });
  }

  closeAddProduct() {
    this.setState({
      addProductOpened: false,
    });
  }

  openEditProduct(id) {
    const {
      products,
    } = this.state;

    const selectedProduct = products.find((o) => o._id === id);

    this.setState({
      selectedProduct,
    });
  }

  closeEditProduct() {
    this.setState({
      selectedProduct: null,
    });
  }

  render() {
    const {
      isSearching,
      products,
      addProductOpened,
      errorMessage,
      selectedProduct,
    } = this.state;

    return (
      <div id="Products">
        {addProductOpened
          ? (
            <AddProduct
              products={products}
              productAdded={this.productAdded}
              close={this.closeAddProduct}
            />
          )
          : ''}
        {selectedProduct
          ? (
            <EditProduct
              products={products}
              id={selectedProduct._id}
              productFamily={selectedProduct.product_family}
              productBranch={selectedProduct.product_branch}
              productList={selectedProduct.product_list}
              productEdited={this.productAdded}
              close={this.closeEditProduct}
            />
          )
          : ''}
        <Row>
          <Col xs={12} className="page-title">
            <h1>PSIRT Product Listing</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="management-links">
            <PrimaryButton
              buttonText="Add Product"
              onClick={this.openAddProduct}
              disabled={products.length === 0}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="dashboard-item">
            <ProductList
              isSearching={isSearching}
              products={products}
              errorMessage={errorMessage}
              editProduct={this.openEditProduct}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Products;
