import './App.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import AuthUtils from '../../utils/auth';

import Header from '../Header';
import Login from '../Auth/Login';
import ChangePassword from '../Auth/ChangePassword';
import Dashboard from '../Dashboard';
import DarkWebScan from '../DarkWebScan';
import PSIRT from '../PSIRT';
import AddAdvisory from '../PSIRT/AddAdvisory';
import ViewAdvisory from '../PSIRT/ViewAdvisory';
import Products from '../PSIRT/Products';
import AddBreachInstructions from '../AddBreachInstructions';
import AddBreach from '../AddBreach';
import BreachDetails from '../BreachDetails';
import Queue from '../Queue';
import AnalysisRecent from '../AnalysisRecent';
import Search from '../Search';
import ManageUsers from '../Users/Manage';
import MalwareThreatDetails from '../ThreatDetails/MalwareThreatDetails';
import MalwareHashDetails from '../ThreatDetails/MalwareHashDetails';
import NotFoundPage from '../NotFoundPage';

const authHandler = new AuthUtils();

function PrivateRoute({ comp: Comp, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        authHandler.isAuthenticated() === true
          ? <Comp {...props} {...rest} match={props.match} />
          : (
            <Redirect to={{
              pathname: '/Login',
              search: `?redirect=${window.location.pathname}`,
              state: { from: props.location },
            }}
            />
          )
      )}
    />
  );
}

function RedirectToDashboard({ ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        authHandler.isAuthenticated() === true
          ? (
            <Redirect to={{
              pathname: '/Dashboard',
              state: { from: props.location },
            }}
            />
          )
          : (
            <Redirect to={{
              pathname: '/Login',
              search: `?redirect=${window.location.pathname}`,
              state: { from: props.location },
            }}
            />
          )
      )}
    />
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      searchResults: null,
    };
    this.searchResultsReceived = this.searchResultsReceived.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
  }

  componentDidMount() {
    if (authHandler.isAuthenticated()) {
      this.updateProfile();
    }
    window.addEventListener('storage', (event) => {
      if (event.key === 'CREDENTIALS_SHARING' && !authHandler.isAuthenticated()) {
        if (event.newValue) {
          const data = JSON.parse(event.newValue);
          authHandler.saveSharedCredentials(data)
            .then(() => {
              this.updateProfile();
            });
        }
      }
      if (event.key === 'CREDENTIALS_FLUSH' && authHandler.isAuthenticated()) {
        authHandler.logout();
      }
    });
  }

  searchResultsReceived(results) {
    const { history } = this.props;
    this.setState({
      searchResults: results,
    }, history.push('/Search'));
  }

  updateProfile() {
    return new Promise((resolve) => {
      authHandler.getProfile()
        .then((user) => {
          this.setState({
            user,
          }, resolve());
        })
        .catch(() => resolve());
    });
  }

  render() {
    const {
      user,
      searchResults,
    } = this.state;
    const {
      history,
    } = this.props;
    return (
      <Container id="App" fluid>
        <Header auth={authHandler} history={history} user={user} />
        <Switch>
          <Route path="/Login" render={(otherProps) => <Login auth={authHandler} user={user} updateProfile={this.updateProfile} {...otherProps} />} />
          <PrivateRoute path="/Dashboard" comp={Dashboard} auth={authHandler} user={user} searchResultsReceived={this.searchResultsReceived} />
          <PrivateRoute path="/DarkWebScan/Instructions" comp={AddBreachInstructions} auth={authHandler} user={user} />
          <PrivateRoute path="/DarkWebScan/Add" comp={AddBreach} auth={authHandler} user={user} />
          <PrivateRoute path="/DarkWebScan/Breach/:breachId" comp={BreachDetails} auth={authHandler} user={user} />
          <PrivateRoute path="/DarkWebScan" comp={DarkWebScan} auth={authHandler} user={user} />
          <PrivateRoute path="/PSIRT/Products" comp={Products} auth={authHandler} user={user} />
          <PrivateRoute path="/PSIRT/Add" comp={AddAdvisory} auth={authHandler} user={user} />
          <PrivateRoute path="/PSIRT/Advisory/:id" comp={ViewAdvisory} auth={authHandler} user={user} />
          <PrivateRoute path="/PSIRT" comp={PSIRT} auth={authHandler} user={user} />
          <PrivateRoute path="/Analysis/Queue" comp={Queue} auth={authHandler} user={user} />
          <PrivateRoute path="/Analysis/Recent" comp={AnalysisRecent} auth={authHandler} user={user} />
          <PrivateRoute path="/Search" comp={Search} auth={authHandler} user={user} searchResultsReceived={this.searchResultsReceived} searchResults={searchResults} />
          <PrivateRoute path="/Threat/Malware/:threatId" comp={MalwareThreatDetails} auth={authHandler} user={user} />
          <PrivateRoute path="/Threat/MD5/:md5" comp={MalwareHashDetails} auth={authHandler} user={user} />
          <PrivateRoute path="/Users/Manage" comp={ManageUsers} auth={authHandler} user={user} />
          <PrivateRoute path="/ChangePassword" comp={ChangePassword} auth={authHandler} user={user} />
          <RedirectToDashboard path="/" auth={authHandler} user={user} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Container>
    );
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired,
};

PrivateRoute.propTypes = {
  comp: PropTypes.any.isRequired,
  match: PropTypes.object,
  location: PropTypes.any,
};

PrivateRoute.defaultProps = {
  location: null,
  match: null,
};

RedirectToDashboard.propTypes = {
  location: PropTypes.any,
};

RedirectToDashboard.defaultProps = {
  location: null,
};

export default App;
