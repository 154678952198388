import './AdvisoryDetails.css';

import React from 'react';
import {
  Form,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import remarkGfm from 'remark-gfm';

const statusOptions = [
  'Investigating',
  'Acknowledged',
  'Resolved',
  'Not Applicable',
];

const impactOptions = [
  'Critical',
  'High',
  'Medium',
  'Low',
  'Not Applicable',
];

function importantFieldsFilled(props) {
  const {
    title,
    summary,
  } = props;
  return (title === '' || summary === '');
}

export default function AdvisoryDetails(props) {
  const {
    title,
    cve,
    impact,
    status,
    cvssScore,
    cvssVector,
    summary,
    affected,
    resolution,
    workaround,
    credits,
    references,
    summaryPreviewOpened,
    workaroundPreviewOpened,
    affectedPreviewOpened,
    resolutionPreviewOpened,
    changeTitle,
    changeCve,
    changeImpact,
    changeStatus,
    changeScore,
    changeVector,
    changeSummary,
    changeAffected,
    changeResolution,
    changeWorkaround,
    changeCredits,
    changeReferences,
    toggleSummaryPreview,
    toggleWorkaroundPreview,
    toggleAffectedPreview,
    toggleResolutionPreview,
    nextStage,
    prevStage,
  } = props;

  return (
    <div id="AdvisoryDetails">
      <div id="AdvisoryDetailsContainer">
        <h3>
          Advisory Details
        </h3>
        <Form>
          <Form.Row>
            <Col
              xs={{ span: 10, offset: 1 }}
              className="form-col"
            >
              <Form.Group>
                <Form.Label htmlFor="advisory-title">
                  Title
                </Form.Label>
                <Form.Control
                  id="advisory-title"
                  type="text"
                  name="title"
                  placeholder="Title"
                  className="text-field"
                  value={title}
                  onChange={changeTitle}
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col
              xs={{ span: 10, offset: 1 }}
              md={{ span: 5, offset: 1 }}
              className="form-col"
            >
              <Form.Group>
                <Form.Label htmlFor="advisory-status">
                  Status
                </Form.Label>
                <Form.Control
                  id="advisory-status"
                  as="select"
                  name="status"
                  className="text-field"
                  value={status}
                  onChange={changeStatus}
                  autoComplete="off"
                >
                  {statusOptions.map((o) => (
                    <option key={o} value={o}>{o}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col
              xs={{ span: 10, offset: 1 }}
              md={{ span: 5, offset: 0 }}
              className="form-col"
            >
              <Form.Group>
                <Form.Label htmlFor="advisory-impact">
                  Impact
                </Form.Label>
                <Form.Control
                  id="advisory-impact"
                  as="select"
                  name="impact"
                  className="text-field"
                  value={impact}
                  onChange={changeImpact}
                  autoComplete="off"
                >
                  {impactOptions.map((o) => (
                    <option key={o} value={o}>{o}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col
              xs={{ span: 10, offset: 1 }}
              className="form-col"
            >
              <Form.Group>
                <Form.Label htmlFor="advisory-cve">
                  CVE(s)
                </Form.Label>
                <Form.Control
                  id="advisory-cve"
                  type="text"
                  name="cve"
                  placeholder="CVE(s)"
                  className="text-field"
                  value={cve}
                  onChange={changeCve}
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col
              xs={{ span: 10, offset: 1 }}
              md={{ span: 5, offset: 1 }}
              className="form-col"
            >
              <Form.Group>
                <Form.Label htmlFor="advisory-cvss-score">
                  CVSS Score
                </Form.Label>
                <Form.Control
                  id="advisory-cvss-score"
                  type="text"
                  name="cvssScore"
                  placeholder="CVSS Score"
                  className="text-field"
                  value={cvssScore}
                  onChange={changeScore}
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
            <Col
              xs={{ span: 10, offset: 1 }}
              md={{ span: 5, offset: 0 }}
              className="form-col"
            >
              <Form.Group>
                <Form.Label htmlFor="advisory-cvss-vector">
                  CVSS Vector
                </Form.Label>
                <Form.Control
                  id="advisory-cvss-vector"
                  type="text"
                  name="cvssVector"
                  placeholder="CVSS Vector"
                  className="text-field"
                  value={cvssVector}
                  onChange={changeVector}
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={{ span: 10, offset: 1 }} className="form-col">
              <Form.Group>
                <Form.Label htmlFor="disclosure-summary">
                  Summary
                  {' '}
                  {!summaryPreviewOpened
                    ? (
                      <span
                        className="label-preview"
                        role="button"
                        tabIndex="0"
                        onClick={toggleSummaryPreview}
                        onKeyPress={toggleSummaryPreview}
                      >
                        (preview)
                      </span>
                    )
                    : ''}
                </Form.Label>
                <Form.Control
                  id="disclosure-summary"
                  as="textarea"
                  name="summary"
                  className="description-field"
                  value={summary}
                  onChange={changeSummary}
                  autoComplete="off"
                />
                <Form.Text id="descriptionHelpText">
                  This field supports supports
                  {' '}
                  <a
                    href="https://guides.github.com/features/mastering-markdown/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    markdown
                  </a>
                  {' '}
                  formatting
                </Form.Text>
              </Form.Group>
            </Col>
          </Form.Row>
          {summaryPreviewOpened
            ? (
              <Row>
                <Col xs={{ span: 10, offset: 1 }} className="preview-section">
                  <h5>
                    Summary Preview
                    {' '}
                    <span
                      className="label-preview"
                      role="button"
                      tabIndex="0"
                      onClick={toggleSummaryPreview}
                      onKeyPress={toggleSummaryPreview}
                    >
                      (close)
                    </span>
                  </h5>
                  <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
                    {summary}
                  </ReactMarkdown>
                </Col>
              </Row>
            )
            : ''}
          <Form.Row>
            <Col xs={{ span: 10, offset: 1 }} className="form-col">
              <Form.Group>
                <Form.Label htmlFor="disclosure-affected">
                  Affected Versions
                  {' '}
                  {!summaryPreviewOpened
                    ? (
                      <span
                        className="label-preview"
                        role="button"
                        tabIndex="0"
                        onClick={toggleAffectedPreview}
                        onKeyPress={toggleAffectedPreview}
                      >
                        (preview)
                      </span>
                    )
                    : ''}
                </Form.Label>
                <Form.Control
                  id="disclosure-affected"
                  as="textarea"
                  name="affected"
                  className="description-field"
                  value={affected}
                  onChange={changeAffected}
                  autoComplete="off"
                />
                <Form.Text id="descriptionHelpText">
                  This field supports supports
                  {' '}
                  <a
                    href="https://guides.github.com/features/mastering-markdown/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    markdown
                  </a>
                  {' '}
                  formatting
                </Form.Text>
              </Form.Group>
            </Col>
          </Form.Row>
          {affectedPreviewOpened
            ? (
              <Row>
                <Col xs={{ span: 10, offset: 1 }} className="preview-section">
                  <h5>
                    Affected Preview
                    {' '}
                    <span
                      className="label-preview"
                      role="button"
                      tabIndex="0"
                      onClick={toggleAffectedPreview}
                      onKeyPress={toggleAffectedPreview}
                    >
                      (close)
                    </span>
                  </h5>
                  <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
                    {affected}
                  </ReactMarkdown>
                </Col>
              </Row>
            )
            : ''}
          <Form.Row>
            <Col xs={{ span: 10, offset: 1 }} className="form-col">
              <Form.Group>
                <Form.Label htmlFor="disclosure-resolution">
                  Resolution
                  {' '}
                  {!summaryPreviewOpened
                    ? (
                      <span
                        className="label-preview"
                        role="button"
                        tabIndex="0"
                        onClick={toggleResolutionPreview}
                        onKeyPress={toggleResolutionPreview}
                      >
                        (preview)
                      </span>
                    )
                    : ''}
                </Form.Label>
                <Form.Control
                  id="disclosure-resolution"
                  as="textarea"
                  name="resolution"
                  className="description-field"
                  value={resolution}
                  onChange={changeResolution}
                  autoComplete="off"
                />
                <Form.Text id="descriptionHelpText">
                  This field supports supports
                  {' '}
                  <a
                    href="https://guides.github.com/features/mastering-markdown/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    markdown
                  </a>
                  {' '}
                  formatting
                </Form.Text>
              </Form.Group>
            </Col>
          </Form.Row>
          {resolutionPreviewOpened
            ? (
              <Row>
                <Col xs={{ span: 10, offset: 1 }} className="preview-section">
                  <h5>
                    Resolution Preview
                    {' '}
                    <span
                      className="label-preview"
                      role="button"
                      tabIndex="0"
                      onClick={toggleResolutionPreview}
                      onKeyPress={toggleResolutionPreview}
                    >
                      (close)
                    </span>
                  </h5>
                  <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
                    {resolution}
                  </ReactMarkdown>
                </Col>
              </Row>
            )
            : ''}
          <Form.Row>
            <Col xs={{ span: 10, offset: 1 }} className="form-col">
              <Form.Group>
                <Form.Label htmlFor="disclosure-workaround">
                  Workaround
                  {' '}
                  {!workaroundPreviewOpened
                    ? (
                      <span
                        className="label-preview"
                        role="button"
                        tabIndex="0"
                        onClick={toggleWorkaroundPreview}
                        onKeyPress={toggleWorkaroundPreview}
                      >
                        (preview)
                      </span>
                    )
                    : ''}
                </Form.Label>
                <Form.Control
                  id="disclosure-workaround"
                  as="textarea"
                  name="workaround"
                  className="description-field"
                  value={workaround}
                  onChange={changeWorkaround}
                  autoComplete="off"
                />
                <Form.Text id="descriptionHelpText">
                  This field supports supports
                  {' '}
                  <a
                    href="https://guides.github.com/features/mastering-markdown/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    markdown
                  </a>
                  {' '}
                  formatting
                </Form.Text>
              </Form.Group>
            </Col>
          </Form.Row>
          {workaroundPreviewOpened
            ? (
              <Row>
                <Col xs={{ span: 10, offset: 1 }} className="preview-section">
                  <h5>
                    Workaround Preview
                    {' '}
                    <span
                      className="label-preview"
                      role="button"
                      tabIndex="0"
                      onClick={toggleWorkaroundPreview}
                      onKeyPress={toggleWorkaroundPreview}
                    >
                      (close)
                    </span>
                  </h5>
                  <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
                    {workaround}
                  </ReactMarkdown>
                </Col>
              </Row>
            )
            : ''}
          <Form.Row>
            <Col xs={{ span: 10, offset: 1 }} className="form-col">
              <Form.Group>
                <Form.Label htmlFor="disclosure-credits">
                  Credits
                </Form.Label>
                <Form.Control
                  id="disclosure-credits"
                  as="textarea"
                  name="credits"
                  placeholder="Comma-separated list of credits"
                  className="description-field"
                  value={credits}
                  onChange={changeCredits}
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={{ span: 10, offset: 1 }} className="form-col">
              <Form.Group>
                <Form.Label htmlFor="disclosure-references">
                  References
                </Form.Label>
                <Form.Control
                  id="disclosure-references"
                  as="textarea"
                  name="references"
                  placeholder="Comma-separated list of links"
                  className="description-field"
                  value={references}
                  onChange={changeReferences}
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
        <div className="stage-control">
          <Button
            onClick={prevStage}
            type="submit"
            id="prev-stage"
            className="stage-control-button"
          >
            Back
          </Button>
          <Button
            onClick={nextStage}
            type="submit"
            id="next-stage"
            className="stage-control-button"
            disabled={importantFieldsFilled(props)}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

AdvisoryDetails.propTypes = {
  nextStage: PropTypes.func.isRequired,
  prevStage: PropTypes.func.isRequired,
  changeTitle: PropTypes.func.isRequired,
  changeCve: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  changeImpact: PropTypes.func.isRequired,
  changeScore: PropTypes.func.isRequired,
  changeVector: PropTypes.func.isRequired,
  changeSummary: PropTypes.func.isRequired,
  changeWorkaround: PropTypes.func.isRequired,
  changeCredits: PropTypes.func.isRequired,
  changeReferences: PropTypes.func.isRequired,
  changeAffected: PropTypes.func.isRequired,
  changeResolution: PropTypes.func.isRequired,
  toggleSummaryPreview: PropTypes.func.isRequired,
  toggleWorkaroundPreview: PropTypes.func.isRequired,
  toggleAffectedPreview: PropTypes.func.isRequired,
  toggleResolutionPreview: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  cve: PropTypes.string.isRequired,
  impact: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  cvssScore: PropTypes.string.isRequired,
  cvssVector: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  affected: PropTypes.string.isRequired,
  resolution: PropTypes.string.isRequired,
  workaround: PropTypes.string.isRequired,
  credits: PropTypes.string.isRequired,
  references: PropTypes.string.isRequired,
  summaryPreviewOpened: PropTypes.bool.isRequired,
  workaroundPreviewOpened: PropTypes.bool.isRequired,
  affectedPreviewOpened: PropTypes.bool.isRequired,
  resolutionPreviewOpened: PropTypes.bool.isRequired,
};
