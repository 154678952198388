import './AnalysisRecent.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import BootstrapTable from 'react-bootstrap-table-next';

import axios from '../../utils/axios';

function getAnalysisRecent() {
  return new Promise((resolve, reject) => {
    axios.get('https://api.tat.wgtl.io/api/v1/threat/analysis/recent')
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data.threats);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for threats'));
      })
      .catch(() => reject(new Error('Error searching for threats')));
  });
}

class AnalysisRecent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: true,
      analyses: [],
    };
  }

  componentDidMount() {
    getAnalysisRecent()
      .then((analyses) => {
        this.setState({
          isSearching: false,
          analyses,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isSearching: false,
        });
      });
  }

  render() {
    const {
      isSearching,
      analyses,
    } = this.state;

    const columns = [{
      dataField: '_id',
      hidden: true,
      text: 'id',
    }, {
      dataField: 'createdBy',
      text: 'Created By',
    }, {
      dataField: 'date',
      text: 'Created On',
    }, {
      dataField: 'threat',
      isdummyField: true,
      text: 'Threat',
      formatter: (cell, row) => (
        <Link className="table-link" to={`/Threat/Malware/${row.threatId._id}`}>{row.threatId.name}</Link>
      ),
    }, {
      dataField: 'publicAnalysis',
      text: 'Analysis ',
    }];

    const noTableData = () => {
      if (isSearching) {
        return (<div className="loading">Loading</div>);
      }
      return (<div className="no-data-label">No analyses found for that filter</div>);
    };

    return (
      <div id="AnalysisRecent">
        {isSearching
          ? (
            <div className="searching"><i className="fas fa-spinner fa-spin searching-spinner" /></div>
          )
          : ''}
        <div>
          <Row>
            <Col xs={12}>
              <div id="AnalysisTable">
                <BootstrapTable
                  table-responsive
                  condensed
                  keyField="_id"
                  data={analyses}
                  columns={columns}
                  bodyStyle={{ width: 500, maxWidth: 500, wordBreak: 'break-all' }}
                  headerClasses="incident-table-header"
                  rowClasses="incident-table-row"
                  noDataIndication={noTableData}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default AnalysisRecent;
