import './ScanVendorResult.css';

import React from 'react';
import PropTypes from 'prop-types';

function ScanVendorResult(props) {
  const {
    result,
  } = props;

  function getClassName() {
    if (result.result) {
      return 'result-contents malicious';
    }
    return 'result-contents';
  }

  return (
    <div id={`vendor-result-${result.scanner}`} className="ScanVendorResult">
      <div className={getClassName()}>
        <h3 className="scan-vendor">{result.scanner}</h3>
        <div className="scan-result">
          <span className="result-label">Detection:</span>
          {' '}
          {result.result || 'Clean'}
        </div>
      </div>
    </div>
  );
}

ScanVendorResult.propTypes = {
  result: PropTypes.object.isRequired,
};

export default ScanVendorResult;
