import './ConfirmDialogue.css';

import * as React from 'react';
import PropTypes from 'prop-types';

import PrimaryButton from '../Buttons/PrimaryButton';

function ConfirmDialogue(props) {
  const {
    message,
    error,
    isWorking,
    accept,
    reject,
  } = props;
  return (
    <div id="ConfirmDialogue">
      <div id="confirm-content">
        <div className="confirm-message">
          {message}
        </div>
        <div className="error-message">
          {error}
        </div>
        {isWorking
          ? (
            <div className="working-spinner">
              <i className="fas fa-spinner fa-spin searching-spinner" />
            </div>
          )
          : ''}
        <div className="confirm-controls">
          <PrimaryButton buttonText="Confirm" onClick={accept} disabled={isWorking} />
          <PrimaryButton buttonText="Cancel" onClick={reject} disabled={isWorking} />
        </div>
      </div>
    </div>
  );
}

ConfirmDialogue.propTypes = {
  message: PropTypes.string.isRequired,
  error: PropTypes.string,
  isWorking: PropTypes.bool,
  accept: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
};

ConfirmDialogue.defaultProps = {
  error: null,
  isWorking: false,
};

export default ConfirmDialogue;
