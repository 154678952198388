import './MalwareCountries.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import mapDataWorld from '@highcharts/map-collection/custom/world.geo.json';
import axios from '../../utils/axios';

function getCountries(threatId) {
  return new Promise((resolve, reject) => {
    axios.get(`https://api.tat.wgtl.io/api/v1/threat/malware/countries/${threatId}`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for threats'));
      })
      .catch(() => reject(new Error('Error searching for threats')));
  });
}

class MalwareHashes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: true,
      incidents: null,
    };
  }

  componentDidMount() {
    const {
      threatId,
    } = this.props;
    getCountries(threatId)
      .then((result) => {
        this.setState({
          isSearching: false,
          incidents: result.countries,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isSearching: false,
        });
      });
  }

  render() {
    const {
      isSearching,
      incidents,
    } = this.state;
    console.log(incidents);

    const options = {
      title: {
        text: '',
      },
      chart: {
        map: 'custom/world',
        backgroundColor: '#1d1d1d',
      },
      legend: {
        enabled: false,
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'bottom',
        },
      },
      series: [
        {
          name: 'Countries',
          color: '#d32f2f',
          nullColor: '#202020',
          enableMouseTracking: false,
          mapData: mapDataWorld,
        },
        {
          type: 'mapbubble',
          mapData: mapDataWorld,
          name: 'Detections',
          joinBy: ['iso-a2', '_id'],
          data: incidents,
          minSize: 4,
          maxSize: '12%',
          color: '#d32f2f',
          tooltip: {
            pointFormat: '{point.properties.hc-a2}: {point.z}',
          },
        },
      ],
    };

    return (
      <div id="MalwareHashes">
        {isSearching
          ? (
            <div className="searching"><i className="fas fa-spinner fa-spin searching-spinner" /></div>
          )
          : ''}
        {incidents
          ? (
            <div>
              <Row>
                <Col xs={12}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType="mapChart"
                    options={options}
                  />
                </Col>
              </Row>
            </div>
          )
          : ''}
      </div>
    );
  }
}

MalwareHashes.propTypes = {
  threatId: PropTypes.string.isRequired,
};

export default MalwareHashes;
