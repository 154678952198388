import './EditProduct.css';

import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Col,
} from 'react-bootstrap';

import axios from '../../../../utils/axios';
import PrimaryButton from '../../../Buttons/PrimaryButton';

function sendProduct(postData) {
  return new Promise((resolve, reject) => {
    axios.post('https://api.tat.wgtl.io/api/v1/psirt/product/save', postData)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        return reject(new Error('Error saving product'));
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          return reject(error.response.data);
        }
        return reject(error);
      });
  });
}

class EditProduct extends React.Component {
  constructor(props) {
    super(props);

    const {
      productFamily,
      productBranch,
      productList,
    } = this.props;

    this.state = {
      origProductFamily: productFamily,
      productFamily,
      productFamilyError: null,
      origProductBranch: productBranch,
      productBranch,
      productBranchError: null,
      productList: productList.join(', '),
      isWorking: false,
      error: null,
    };

    this.changeProductFamily = this.changeProductFamily.bind(this);
    this.changeProductBranch = this.changeProductBranch.bind(this);
    this.changeProductList = this.changeProductList.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  changeProductFamily(e) {
    const {
      products,
    } = this.props;

    this.setState((prevProps) => {
      if (prevProps.origProductFamily !== e.target.value) {
        return {
          productFamily: e.target.value,
          productFamilyError: 'Are you sure you want to change the product family?',
        };
      }

      if (!(products.some((o) => o.product_family === e.target.value))) {
        return {
          productFamily: e.target.value,
          productFamilyError: "This product family doesn't exist yet. Are you sure you want to add it?",
        };
      }

      return {
        productFamily: e.target.value,
        productFamilyError: null,
      };
    });
  }

  changeProductBranch(e) {
    const {
      products,
    } = this.props;

    this.setState((prevProps) => {
      if (prevProps.origProductBranch !== e.target.value) {
        return {
          productBranch: e.target.value,
          productBranchError: 'Are you sure you want to change the product branch name?',
        };
      }

      if (!(products.some((o) => o.product_branch === e.target.value))) {
        return {
          productBranch: e.target.value,
          productBranchError: "This product branch doesn't exist yet. Are you sure you want to add it?",
        };
      }

      return {
        productBranch: e.target.value,
        productBranchError: null,
      };
    });
  }

  changeProductList(e) {
    this.setState({
      productList: e.target.value,
    });
  }

  submitForm(e) {
    const {
      productFamily,
      productBranch,
      productList,
    } = this.state;

    const {
      productEdited,
      id,
    } = this.props;

    e.preventDefault();

    const productListSplit = productList.split(',').map((o) => o.trim());
    const postData = {
      _id: id,
      product_family: productFamily,
      product_branch: productBranch,
      product_list: productListSplit,
    };
    sendProduct(postData)
      .then(() => productEdited())
      .catch((error) => {
        this.setState({
          error,
        });
      });
  }

  render() {
    const {
      productFamily,
      productFamilyError,
      productBranch,
      productBranchError,
      productList,
      isWorking,
      error,
    } = this.state;

    const {
      close,
    } = this.props;

    return (
      <div id="EditProduct">
        <div id="confirm-content">
          <Form>
            <Form.Row>
              <Col xs={{ span: 10, offset: 1 }} className="form-col">
                <Form.Group>
                  <Form.Label htmlFor="product-family">
                    Product Family
                  </Form.Label>
                  <Form.Control
                    id="product-family"
                    type="text"
                    name="productFamily"
                    placeholder=""
                    className="text-field"
                    value={productFamily}
                    onChange={this.changeProductFamily}
                    autoComplete="off"
                  />
                  <Form.Text id="descriptionHelpText">
                    {productFamilyError}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={{ span: 10, offset: 1 }} className="form-col">
                <Form.Group>
                  <Form.Label htmlFor="product-branch">
                    Product Branch
                  </Form.Label>
                  <Form.Control
                    id="product-branch"
                    type="text"
                    name="productBranch"
                    placeholder=""
                    className="text-field"
                    value={productBranch}
                    onChange={this.changeProductBranch}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Text id="descriptionHelpText">
                  {productBranchError}
                </Form.Text>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={{ span: 10, offset: 1 }} className="form-col">
                <Form.Group>
                  <Form.Label htmlFor="product-list">
                    Product List
                  </Form.Label>
                  <Form.Control
                    id="product-list"
                    type="text"
                    name="productList"
                    placeholder=""
                    className="text-field"
                    value={productList}
                    onChange={this.changeProductList}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
          <div className="error-message">
            {error}
          </div>
          {isWorking
            ? (
              <div className="working-spinner">
                <i className="fas fa-spinner fa-spin searching-spinner" />
              </div>
            )
            : ''}
          <div className="confirm-controls">
            <PrimaryButton buttonText="Cancel" onClick={close} disabled={isWorking} />
            <PrimaryButton buttonText="Save" onClick={this.submitForm} disabled={isWorking} />
          </div>
        </div>
      </div>
    );
  }
}

EditProduct.propTypes = {
  products: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  productFamily: PropTypes.string.isRequired,
  productBranch: PropTypes.string.isRequired,
  productList: PropTypes.string.isRequired,
  productEdited: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default EditProduct;
