import './AddBreach.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import CreateBreachForm from '../Forms/CreateBreachForm';

function AddBreach(props) {
  const {
    history,
  } = props;
  return (
    <div id="AddBreach">
      <Row>
        <Col xs={12} className="page-title">
          <h1>Add Breach</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="dashboard-item">
          <CreateBreachForm history={history} />
        </Col>
      </Row>
    </div>
  );
}

AddBreach.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AddBreach;
