import './ViewAdvisory.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import axios from '../../../utils/axios';

import AdvisoryVisibilityControls from './AdvisoryVisibilityControls';
import AdvisoryDetailsView from './AdvisoryDetailsView';
import EditAdvisoryDetails from './EditAdvisoryDetails';
import EditAdvisoryProducts from './EditAdvisoryProducts';
import ConfirmDialogue from '../../ConfirmDialogue';
import PrimaryButton from '../../Buttons/PrimaryButton';

function getAdvisory(id) {
  return new Promise((resolve, reject) => {
    axios.get(`https://api.tat.wgtl.io/api/v1/psirt/advisory/${id}`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for advisory'));
      })
      .catch(() => reject(new Error('Error searching for advisory')));
  });
}

function updateAdvisoryVisibility(postData) {
  return new Promise((resolve, reject) => {
    axios.post('https://api.tat.wgtl.io/api/v1/psirt/advisory/visibility', postData)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data.advisory);
        }
        return reject(new Error('Error advisory breach'));
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          return reject(error.response.data);
        }
        return reject(error);
      });
  });
}

class ViewAdvisory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearching: true,
      isWorking: false,
      error: null,
      advisory: null,
      confirmPublish: false,
      confirmPrivate: false,
      confirmArchive: false,
      editOpened: false,
      editProductsOpened: false,
    };

    this.publishAdvisory = this.publishAdvisory.bind(this);
    this.confirmPublish = this.confirmPublish.bind(this);
    this.confirmHide = this.confirmHide.bind(this);
    this.confirmArchive = this.confirmArchive.bind(this);
    this.hideAdvisory = this.hideAdvisory.bind(this);
    this.archiveAdvisory = this.archiveAdvisory.bind(this);
    this.cancelPublish = this.cancelPublish.bind(this);
    this.advisorySaved = this.advisorySaved.bind(this);
    this.openEditAdvisory = this.openEditAdvisory.bind(this);
    this.closeEditAdvisory = this.closeEditAdvisory.bind(this);
    this.openEditAdvisoryProducts = this.openEditAdvisoryProducts.bind(this);
    this.closeEditAdvisoryProducts = this.closeEditAdvisoryProducts.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    getAdvisory(match.params.id)
      .then((advisory) => {
        this.setState({
          isSearching: false,
          advisory,
        });
      })
      .catch(() => {
        this.setState({ isSearching: false });
      });
  }

  openEditAdvisory() {
    this.setState({
      editOpened: true,
    });
  }

  closeEditAdvisory() {
    this.setState({
      editOpened: false,
    });
  }

  openEditAdvisoryProducts() {
    this.setState({
      editProductsOpened: true,
    });
  }

  closeEditAdvisoryProducts() {
    this.setState({
      editProductsOpened: false,
    });
  }

  advisorySaved(advisory) {
    this.setState({
      advisory,
      editOpened: false,
      editProductsOpened: false,
    });
  }

  publishAdvisory() {
    this.setState({
      confirmPublish: true,
    });
  }

  hideAdvisory() {
    this.setState({
      confirmPrivate: true,
    });
  }

  archiveAdvisory() {
    this.setState({
      confirmArchive: true,
    });
  }

  confirmPublish() {
    const { advisory } = this.state;
    const postData = {
      _id: advisory._id,
      visibility: 'public',
    };
    this.setState({
      isWorking: true,
    });
    updateAdvisoryVisibility(postData)
      .then((updatedAdvisory) => {
        this.setState({
          advisory: updatedAdvisory,
          confirmPublish: false,
          isWorking: false,
        });
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          isWorking: false,
        });
      });
  }

  confirmHide() {
    const { advisory } = this.state;
    const postData = {
      _id: advisory._id,
      visibility: 'private',
    };
    this.setState({
      isWorking: true,
    });
    updateAdvisoryVisibility(postData)
      .then((updatedAdvisory) => {
        this.setState({
          advisory: updatedAdvisory,
          confirmPrivate: false,
          isWorking: false,
        });
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          isWorking: false,
        });
      });
  }

  confirmArchive() {
    const { advisory } = this.state;
    const postData = {
      _id: advisory._id,
      visibility: 'archived',
    };
    this.setState({
      isWorking: true,
    });
    updateAdvisoryVisibility(postData)
      .then((updatedAdvisory) => {
        this.setState({
          advisory: updatedAdvisory,
          confirmArchive: false,
          isWorking: false,
        });
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          isWorking: false,
        });
      });
  }

  cancelPublish() {
    this.setState({
      confirmPublish: false,
      confirmPrivate: false,
      confirmArchive: false,
      error: null,
    });
  }

  render() {
    const {
      advisory,
      error,
      isSearching,
      isWorking,
      confirmPublish,
      confirmPrivate,
      confirmArchive,
      editOpened,
      editProductsOpened,
    } = this.state;

    const {
      user,
    } = this.props;

    return (
      <div id="Advisory">
        {confirmPublish
          ? (
            <ConfirmDialogue
              message="Are you sure you want to make this advisory public?"
              error={error}
              isWorking={isWorking}
              accept={this.confirmPublish}
              reject={this.cancelPublish}
            />
          )
          : ''}
        {confirmPrivate
          ? (
            <ConfirmDialogue
              message="Are you sure you want to make this advisory private?"
              error={error}
              isWorking={isWorking}
              accept={this.confirmHide}
              reject={this.cancelPublish}
            />
          )
          : ''}
        {confirmArchive
          ? (
            <ConfirmDialogue
              message="Are you sure you want to archive this advisory?"
              error={error}
              isWorking={isWorking}
              accept={this.confirmArchive}
              reject={this.cancelPublish}
            />
          )
          : ''}
        {editProductsOpened
          ? (
            <EditAdvisoryProducts
              advisory={advisory}
              close={this.closeEditAdvisoryProducts}
              advisorySaved={this.advisorySaved}
            />
          )
          : ''}
        {editOpened
          ? (
            <EditAdvisoryDetails
              id={advisory._id}
              title={advisory.title}
              cve={advisory.cve}
              impact={advisory.impact}
              status={advisory.status}
              cvssScore={advisory.cvss_score}
              cvssVector={advisory.cvss_vector}
              summary={advisory.summary}
              affected={advisory.affected}
              resolution={advisory.resolution}
              workaround={advisory.workaround}
              credits={advisory.credits}
              references={advisory.references}
              products={advisory.products}
              advisorySaved={this.advisorySaved}
              close={this.closeEditAdvisory}
            />
          )
          : ''}
        {!editOpened && !editProductsOpened
          ? (
            <Row>
              <Col xs={12} className="dashboard-item">
                <div id="AdvisoryContainer">
                  {isSearching
                    ? (
                      <div className="searching"><i className="fas fa-spinner fa-spin searching-spinner" /></div>
                    )
                    : ''}
                  {advisory
                    ? (
                      <div>
                        <Row>
                          <Col xs={12} className="advisory-title-section">
                            <h2 className="advisory-title">
                              Advisory Title:
                              {' '}
                              {advisory.title}
                            </h2>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <AdvisoryVisibilityControls
                              visibility={advisory.visibility}
                              user={user}
                              publishAdvisory={this.publishAdvisory}
                              hideAdvisory={this.hideAdvisory}
                              archiveAdvisory={this.archiveAdvisory}
                            />
                          </Col>
                          {user && [1, 2, 6].includes(user.accountType)
                            ? (
                              <div className="edit-button-container">
                                <PrimaryButton
                                  buttonText="Edit"
                                  onClick={this.openEditAdvisory}
                                />
                              </div>
                            )
                            : ''}
                        </Row>
                        <AdvisoryDetailsView
                          cve={advisory.cve}
                          impact={advisory.impact}
                          status={advisory.status}
                          cvssScore={advisory.cvss_score}
                          cvssVector={advisory.cvss_vector}
                          summary={advisory.summary}
                          affected={advisory.affected}
                          resolution={advisory.resolution}
                          workaround={advisory.workaround}
                          credits={advisory.credits}
                          references={advisory.references}
                          products={advisory.products}
                          openEditProducts={this.openEditAdvisoryProducts}
                          user={user}
                        />
                      </div>
                    )
                    : ''}
                </div>
              </Col>
            </Row>
          )
          : ''}
      </div>
    );
  }
}

ViewAdvisory.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object,
};

ViewAdvisory.defaultProps = {
  user: null,
};

export default ViewAdvisory;
