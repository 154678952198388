import './AdvisoryReview.css';

import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Row, Col, Button } from 'react-bootstrap';

export default function AdvisoryReview(props) {
  const {
    title,
    cve,
    impact,
    status,
    cvssScore,
    cvssVector,
    summary,
    workaround,
    credits,
    references,
    products,
    affected,
    resolution,
    nextStage,
    prevStage,
  } = props;

  return (
    <div id="AdvisoryReview">
      <div id="AdvisoryReviewContainer">
        <Row>
          <Col xs={12} className="advisory-title">
            <h3>
              Review:
              { ' ' }
              <span className="title-value">{title}</span>
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="advisory-details">
            <div className="advisory-details-content">
              <Row>
                <Col xs={12} md={2} className="detail-label">
                  CVE(s)
                </Col>
                <Col xs={12} md={10} className="detail-item">
                  {cve && cve.length && cve.join(', ')}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={2} className="detail-label">
                  Status
                </Col>
                <Col xs={12} md={10} className="detail-item">
                  {status}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={2} className="detail-label">
                  Impact
                </Col>
                <Col xs={12} md={10} className="detail-item">
                  {impact}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={2} className="detail-label">
                  CVSS Score
                </Col>
                <Col xs={12} md={10} className="detail-item">
                  {cvssScore}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={2} className="detail-label">
                  CVSS Vector
                </Col>
                <Col xs={12} md={10} className="detail-item">
                  {cvssVector}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={2} className="detail-label">
                  Credits
                </Col>
                <Col xs={12} md={10} className="detail-item">
                  {credits && credits.length && credits.join(', ')}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="advisory-text-block">
            <h4>Summary</h4>
            <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
              {summary}
            </ReactMarkdown>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="advisory-text-block">
            <h4>Affected Versions</h4>
            <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
              {affected}
            </ReactMarkdown>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="advisory-text-block">
            <h4>Resolution</h4>
            <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
              {resolution}
            </ReactMarkdown>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="advisory-text-block">
            <h4>Workaround</h4>
            <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
              {workaround}
            </ReactMarkdown>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="advisory-product-section">
            <h4>Affected Products</h4>
            <div className="affected-products-content">
              {products && Object.keys(products).map((f) => (
                <div className="product-family-block" key={f}>
                  <h5>{f}</h5>
                  {products[f].map((b) => (
                    <div className="product-branch-block" key={b}>
                      <h6>{b}</h6>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="advisory-references-block">
            <h4>References</h4>
            <div className="advisory-references-list">
              <ul>
                {references && references.length && references.map((e) => (
                  <li key={e}>{e}</li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
        <div className="stage-control">
          <Button
            onClick={prevStage}
            type="submit"
            id="prev-stage"
            className="stage-control-button"
          >
            Cancel
          </Button>
          <Button
            onClick={nextStage}
            type="submit"
            id="next-stage"
            className="stage-control-button"
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}

AdvisoryReview.propTypes = {
  title: PropTypes.string.isRequired,
  cve: PropTypes.array.isRequired,
  impact: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  cvssScore: PropTypes.string.isRequired,
  cvssVector: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  workaround: PropTypes.string.isRequired,
  credits: PropTypes.array.isRequired,
  references: PropTypes.array.isRequired,
  products: PropTypes.object.isRequired,
  affected: PropTypes.string.isRequired,
  resolution: PropTypes.string.isRequired,
  nextStage: PropTypes.func.isRequired,
  prevStage: PropTypes.func.isRequired,
};
