import axios from './axios';
import StorageService from './StorageService';

const storageService = StorageService.getService();

const jwt = require('jsonwebtoken');

export default class Auth {
  constructor() {
    this.state = {
      authEvent: new Date(),
    };
    this.login = this.login.bind(this);
  }

  login(email, password, remember) {
    return new Promise((resolve, reject) => {
      const browserUUID = storageService.getBrowserUUID();
      const postData = {
        email,
        password,
        uuid: browserUUID,
      };
      axios.post('https://id.wgtl.io/login', postData)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.auth) {
              storageService.setTokens(res.data, remember);
              return resolve({ success: true });
            }
            return reject(new Error(res.data.message));
          }
          return reject(new Error('Site error, contact support!'));
        })
        .catch(() => reject(new Error('Site error, contact support!')));
    });
  }

  changeEmail(email, userId) {
    return new Promise((resolve, reject) => {
      const browserUUID = storageService.getBrowserUUID();
      const postData = {
        email,
        userId,
        uuid: browserUUID,
      };
      return axios.post('https://id.wgtl.io/changeemail', postData)
        .then((res) => {
          if (res.status === 200 || res.data.success) {
            return resolve(res.data);
          }
          return reject(new Error(res.data.message));
        })
        .catch(() => reject(new Error('Error changing email')));
    });
  }

  changePassword(oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      const browserUUID = storageService.getBrowserUUID();
      const postData = {
        oldPassword,
        newPassword,
        uuid: browserUUID,
      };
      return axios.post('https://id.wgtl.io/changepassword', postData)
        .then((res) => {
          if (res.status === 200) {
            storageService.setTokens(res.data, storageService.isPersistentAuth());
            return resolve(res.data);
          }
          return reject(new Error(res.data.message));
        })
        .catch(() => reject(new Error('Error changing password.')));
    });
  }

  getProfile() {
    return new Promise((resolve, reject) => {
      if (this.isAuthenticated()) {
        return axios.get('https://id.wgtl.io/profile')
          .then((res) => {
            if (res.status === 200) {
              return resolve(res.data);
            }
            return reject(new Error(res.data.message));
          })
          .catch(() => reject(new Error('Error retrieving profile')));
      }
      return resolve(null);
    });
  }

  logout() {
    storageService.destroyTokens();
    window.location.reload(false);
  }

  isAuthenticated() {
    if (storageService.getAccessToken() !== null) {
      return true;
    }
    return false;
  }

  getUserProfile() {
    return jwt.decode(storageService.getIdToken());
  }

  saveSharedCredentials(data) {
    return new Promise((resolve) => {
      storageService.setTokens(data.tokenObj, data.remember_user);
      return resolve();
    });
  }
}
