import './Dashboard.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import ThreatSearchForm from '../Forms/ThreatSearchForm';
import AnalysisStatBlock from '../AnalysisStatBlock';
import TrendingMalware from '../TrendingMalware';
import NewMalware from '../NewMalware';

function Dashboard(props) {
  const {
    searchResultsReceived,
  } = props;
  return (
    <div id="Dashboard">
      <Row>
        <Col xs={12} className="search-title">
          <h1>WatchGuard Threat Analysis Tools</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="dashboard-item">
          <AnalysisStatBlock />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="dashboard-item">
          <ThreatSearchForm
            searchResultsReceived={searchResultsReceived}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="dashboard-item">
          <h3>Trending Malware</h3>
          <TrendingMalware />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="dashboard-item">
          <h3>New Malware</h3>
          <NewMalware />
        </Col>
      </Row>
    </div>
  );
}

Dashboard.propTypes = {
  searchResultsReceived: PropTypes.func.isRequired,
};

export default Dashboard;
