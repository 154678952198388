import './UpdateBreachForm.css';

import * as React from 'react';
import {
  Form,
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { Multiselect } from 'multiselect-react-dropdown';

import UploadImage from '../UploadImage';
import axios from '../../../utils/axios';

const dataClassList = [
  'Access tokens',
  'Chat logs',
  'Credit cards',
  'Dates of birth',
  'Device information',
  'Email addresses',
  'Ethnicities',
  'Genders',
  'Geographic locations',
  'Historical passwords',
  'Instant messenger identities',
  'IP addresses',
  'License plate numbers',
  'Names',
  'Password hints',
  'Passwords',
  'Payment histories',
  'Phone numbers',
  'Physical addresses',
  'Private messages',
  'Security questions and answers',
  'Sexual orientations',
  'Social connections',
  'Social media profiles',
  'Spoken languages',
  'Time zones',
  'User statuses',
  'User website URLs',
  'Usernames',
  'Website activity',
];

function UpdateBreach(postData) {
  return new Promise((resolve, reject) => {
    axios.post('https://api.tat.wgtl.io/api/v1/dws/breach/save', postData)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data.breach);
        }
        return reject(new Error('Error creating breach'));
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          return reject(error.response.data);
        }
        return reject(error);
      });
  });
}

class UpdateBreachForm extends React.Component {
  constructor(props) {
    super(props);
    const {
      breach,
    } = this.props;
    const {
      _id,
      name,
      title,
      description,
      domain,
      management,
    } = breach;
    const bDate = new Date(breach.breach_date);
    const dDate = new Date(breach.disclosure_date);
    const breachDate = bDate.toISOString().split('T')[0];
    const disclosureDate = dDate.toISOString().split('T')[0];
    this.state = {
      id: _id,
      isSubmitting: false,
      errorMessage: null,
      name,
      title,
      description,
      domain,
      recordCount: breach.record_count,
      breachDate,
      disclosureDate,
      logoPath: breach.logo_path,
      dataPath: management.s3_location,
      dataClasses: breach.data_classes,
      isVerified: breach.is_verified,
      isSensitive: breach.is_sensitive,
      isRetired: breach.is_retired,
      isSpam: breach.is_spam,
      isCollection: breach.is_collection,
    };
    this.changeTitle = this.changeTitle.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeBreachDate = this.changeBreachDate.bind(this);
    this.changeDisclosureDate = this.changeDisclosureDate.bind(this);
    this.changeDomain = this.changeDomain.bind(this);
    this.changeRecordCount = this.changeRecordCount.bind(this);
    this.imageUploaded = this.imageUploaded.bind(this);
    this.changeDataPath = this.changeDataPath.bind(this);
    this.changeDataClasses = this.changeDataClasses.bind(this);
    this.toggleIsVerified = this.toggleIsVerified.bind(this);
    this.toggleIsSensitive = this.toggleIsSensitive.bind(this);
    this.toggleIsRetired = this.toggleIsRetired.bind(this);
    this.toggleIsSpam = this.toggleIsSpam.bind(this);
    this.toggleIsCollection = this.toggleIsCollection.bind(this);
    this.submitBreach = this.submitBreach.bind(this);
  }

  changeTitle(event) {
    this.setState({
      title: event.target.value,
    });
  }

  changeDescription(event) {
    this.setState({
      description: event.target.value,
    });
  }

  changeDomain(event) {
    this.setState({
      domain: event.target.value,
    });
  }

  changeRecordCount(event) {
    this.setState({
      recordCount: event.target.value,
    });
  }

  changeBreachDate(event) {
    this.setState({
      breachDate: event.target.value,
    });
  }

  changeDisclosureDate(event) {
    this.setState({
      disclosureDate: event.target.value,
    });
  }

  imageUploaded(url) {
    this.setState({
      logoPath: url,
    });
  }

  changeDataPath(event) {
    this.setState({
      dataPath: event.target.value,
    });
  }

  changeDataClasses(t) {
    this.setState({
      dataClasses: t,
    });
  }

  toggleIsVerified(checked) {
    this.setState({ isVerified: checked });
  }

  toggleIsSensitive(checked) {
    this.setState({ isSensitive: checked });
  }

  toggleIsRetired(checked) {
    this.setState({ isRetired: checked });
  }

  toggleIsSpam(checked) {
    this.setState({ isSpam: checked });
  }

  toggleIsCollection(checked) {
    this.setState({ isCollection: checked });
  }

  submitBreach(event) {
    const {
      id,
      name,
      title,
      description,
      domain,
      recordCount,
      breachDate,
      disclosureDate,
      logoPath,
      dataPath,
      dataClasses,
      isVerified,
      isSensitive,
      isRetired,
      isSpam,
      isCollection,
    } = this.state;

    if (event) {
      event.preventDefault();
    }

    this.setState({
      isSubmitting: true,
    });

    const postData = {
      id,
      name,
      title,
      description,
      domain,
      record_count: recordCount,
      breach_date: breachDate,
      disclosure_date: disclosureDate,
      logo_path: logoPath,
      data_path: dataPath,
      data_classes: dataClasses,
      is_verified: isVerified,
      is_sensitive: isSensitive,
      is_retired: isRetired,
      is_spam: isSpam,
      is_collection: isCollection,
    };

    UpdateBreach(postData)
      .then((breach) => {
        const { breachUpdated } = this.props;
        this.setState({
          isSubmitting: false,
        });
        breachUpdated(breach);
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          errorMessage: error.message,
          isSubmitting: false,
        });
      });
  }

  render() {
    const {
      isSubmitting,
      errorMessage,
      name,
      title,
      description,
      domain,
      recordCount,
      breachDate,
      disclosureDate,
      dataPath,
      dataClasses,
      logoPath,
      isVerified,
      isSensitive,
      isRetired,
      isSpam,
      isCollection,
    } = this.state;
    return (
      <div id="UpdateBreachForm">
        <Row>
          <Col
            xs={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
            className="form-section"
          >
            <Form onSubmit={this.submitAnalysis}>
              <Form.Row>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 5, offset: 1 }}
                  className="form-col"
                >
                  <Form.Group>
                    <Form.Label htmlFor="breach-title">
                      Title
                    </Form.Label>
                    <Form.Control
                      id="breach-title"
                      type="text"
                      name="title"
                      placeholder="Title"
                      className="text-field"
                      value={title}
                      onChange={this.changeTitle}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 5, offset: 0 }}
                  className="form-col"
                >
                  <Form.Group>
                    <Form.Label htmlFor="breach-name">
                      Name
                    </Form.Label>
                    <Form.Control
                      id="breach-name"
                      type="text"
                      name="name"
                      placeholder="Name"
                      className="text-field"
                      value={name}
                      autoComplete="off"
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={{ span: 10, offset: 1 }} className="form-col">
                  <Form.Group>
                    <Form.Label htmlFor="breach-description">
                      Description
                    </Form.Label>
                    <Form.Control
                      id="breach-description"
                      as="textarea"
                      name="description"
                      placeholder="Description"
                      className="description-field"
                      value={description}
                      onChange={this.changeDescription}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 5, offset: 1 }}
                  className="form-col"
                >
                  <Form.Group>
                    <Form.Label htmlFor="breach-domain">
                      Domain
                    </Form.Label>
                    <Form.Control
                      id="breach-domain"
                      type="text"
                      name="domain"
                      placeholder="(eg: https://breachedsite.com)"
                      className="text-field"
                      value={domain}
                      onChange={this.changeDomain}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 5, offset: 0 }}
                  className="form-col"
                >
                  <Form.Group>
                    <Form.Label htmlFor="breach-record-count">
                      Record Count
                    </Form.Label>
                    <Form.Control
                      id="breach-record-count"
                      type="text"
                      name="recordCount"
                      placeholder="0"
                      className="text-field"
                      disabled
                      value={recordCount}
                      onChange={this.changeRecordCount}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 5, offset: 1 }}
                  className="form-col"
                >
                  <Form.Group>
                    <Form.Label htmlFor="breach-breach-date">
                      Breach Date
                    </Form.Label>
                    <Form.Control
                      id="breach-breach-date"
                      type="date"
                      name="breachDate"
                      placeholder="Breach Date"
                      className="date-field"
                      value={breachDate}
                      onChange={this.changeBreachDate}
                    />
                  </Form.Group>
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 5, offset: 0 }}
                  className="form-col"
                >
                  <Form.Group>
                    <Form.Label htmlFor="breach-disclosure-date">
                      Disclosure Date
                    </Form.Label>
                    <Form.Control
                      id="breach-disclosure-date"
                      type="date"
                      name="disclosureDate"
                      placeholder="Disclosure Date"
                      className="date-field"
                      value={disclosureDate}
                      onChange={this.changeDisclosureDate}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={{ span: 10, offset: 1 }} className="form-col">
                  <Form.Label htmlFor="breach-logo">
                    Breach Image
                  </Form.Label>
                  <UploadImage logoPath={logoPath} imageUploaded={this.imageUploaded} />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={{ span: 10, offset: 1 }} className="form-col">
                  <Form.Group>
                    <Form.Label htmlFor="breach-data">
                      Data S3 URL
                    </Form.Label>
                    <Form.Control
                      id="breach-data"
                      type="text"
                      name="dataPath"
                      placeholder="(eg: darkwebscan-data/parsed/<breachname>/"
                      className="text-field"
                      value={dataPath}
                      onChange={this.changeDataPath}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={{ span: 10, offset: 1 }} className="form-col">
                  <Form.Label htmlFor="breach-data-classes">
                    Data Classes
                  </Form.Label>
                  <Multiselect
                    options={dataClassList}
                    placeholder="Data classes"
                    selectedValues={dataClasses}
                    onSelect={this.changeDataClasses}
                    onRemove={this.changeDataClasses}
                    isObject={false}
                    avoidHighlightFirstOption
                    hidePlaceholder
                  />
                </Col>
              </Form.Row>
              <Form.Row className="switch-row">
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 2, offset: 1 }}
                  className="form-col switch-col"
                >
                  <span>Verified</span>
                  <Switch
                    onChange={this.toggleIsVerified}
                    checked={isVerified}
                    className="settings-switch"
                    onColor="#00cc00"
                    height={20}
                    width={40}
                    activeBoxShadow="0 0 2px 3px #00cc00"
                  />
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 2, offset: 0 }}
                  className="form-col switch-col"
                >
                  <span>Sensitive</span>
                  <Switch
                    onChange={this.toggleIsSensitive}
                    checked={isSensitive}
                    className="settings-switch"
                    onColor="#00cc00"
                    height={20}
                    width={40}
                    activeBoxShadow="0 0 2px 3px #00cc00"
                  />
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 2, offset: 0 }}
                  className="form-col switch-col"
                >
                  <span>Retired</span>
                  <Switch
                    onChange={this.toggleIsRetired}
                    checked={isRetired}
                    className="settings-switch"
                    onColor="#00cc00"
                    height={20}
                    width={40}
                    activeBoxShadow="0 0 2px 3px #00cc00"
                  />
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 2, offset: 0 }}
                  className="form-col switch-col"
                >
                  <span>Spam</span>
                  <Switch
                    onChange={this.toggleIsSpam}
                    checked={isSpam}
                    className="settings-switch"
                    onColor="#00cc00"
                    height={20}
                    width={40}
                    activeBoxShadow="0 0 2px 3px #00cc00"
                  />
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 2, offset: 0 }}
                  className="form-col switch-col"
                >
                  <span>Collection</span>
                  <Switch
                    onChange={this.toggleIsCollection}
                    checked={isCollection}
                    className="settings-switch"
                    onColor="#00cc00"
                    height={20}
                    width={40}
                    activeBoxShadow="0 0 2px 3px #00cc00"
                  />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={{ span: 'auto', offset: 1 }} className="form-col">
                  <Button onClick={this.submitBreach} type="submit" id="submit-breach">Save</Button>
                  {isSubmitting
                    ? (
                      <div className="submit-pending">
                        <i className="fas fa-spinner fa-spin submit-pending-icon" />
                      </div>
                    )
                    : ''}
                  {errorMessage
                    ? (
                      <div className="error-message">
                        {errorMessage}
                      </div>
                    )
                    : ''}
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

UpdateBreachForm.propTypes = {
  breach: PropTypes.object.isRequired,
  breachUpdated: PropTypes.func.isRequired,
};

export default UpdateBreachForm;
