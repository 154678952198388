import './AddAdvisory.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import axios from '../../../utils/axios';

import LoadingMessage from './LoadingMessage';
import SelectProductFamily from './SelectProductFamily';
import SelectProducts from './SelectProducts';
import AdvisoryDetails from './AdvisoryDetails';
import AdvisoryReview from './AdvisoryReview';
// import CreateAdvisoryForm from '../Forms/CreateAdvisoryForm';

function getProductList() {
  return new Promise((resolve, reject) => {
    axios.get('https://api.tat.wgtl.io/api/v1/psirt/product/all')
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for products'));
      })
      .catch(() => reject(new Error('Error searching for products')));
  });
}

function sendAdvisory(postData) {
  return new Promise((resolve, reject) => {
    axios.post('https://api.tat.wgtl.io/api/v1/psirt/advisory/add', postData)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        return reject(new Error('Error saving advisory'));
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          return reject(error.response.data);
        }
        return reject(error);
      });
  });
}

class AddAdvisory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      isLoading: true,
      errorMessage: '',
      products: [],
      productFamilies: [],
      selectedProductFamilies: [],
      selectedProducts: {},
      title: '',
      cve: '',
      impact: 'Critical',
      status: 'Investigating',
      cvssScore: '',
      cvssVector: '',
      summary: '',
      workaround: '',
      credits: '',
      references: '',
      affected: '',
      resolution: '',
      creditsArray: [],
      referencesArray: [],
      cveArray: [],
      summaryPreviewOpened: false,
      workaroundPreviewOpened: false,
      affectedPreviewOpened: false,
      resolutionPreviewOpened: false,
    };

    this.changeProductFamily = this.changeProductFamily.bind(this);
    this.changeSelectedProducts = this.changeSelectedProducts.bind(this);
    this.changeTitle = this.changeTitle.bind(this);
    this.changeCve = this.changeCve.bind(this);
    this.changeImpact = this.changeImpact.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.changeScore = this.changeScore.bind(this);
    this.changeVector = this.changeVector.bind(this);
    this.changeSummary = this.changeSummary.bind(this);
    this.changeWorkaround = this.changeWorkaround.bind(this);
    this.changeCredits = this.changeCredits.bind(this);
    this.changeReferences = this.changeReferences.bind(this);
    this.toggleSummaryPreview = this.toggleSummaryPreview.bind(this);
    this.changeAffected = this.changeAffected.bind(this);
    this.changeResolution = this.changeResolution.bind(this);
    this.toggleWorkaroundPreview = this.toggleWorkaroundPreview.bind(this);
    this.toggleAffectedPreview = this.toggleAffectedPreview.bind(this);
    this.toggleResolutionPreview = this.toggleResolutionPreview.bind(this);
    this.submitAdvisory = this.submitAdvisory.bind(this);
    this.nextStage = this.nextStage.bind(this);
    this.prevStage = this.prevStage.bind(this);
  }

  componentDidMount() {
    getProductList()
      .then((products) => {
        products.sort((a, b) => a.product_platform - b.product_platform);
        let productFamilies = [];
        if (products.length > 0) {
          productFamilies = [...new Set(products.map((e) => e.product_family))];
        }
        const selectedProducts = Object.fromEntries(productFamilies.map((e) => [e, []]));

        this.setState({
          isLoading: false,
          stage: 1,
          products,
          productFamilies,
          selectedProducts,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isLoading: false,
          errorMessage: error.message,
        });
      });
  }

  changeProductFamily(t) {
    this.setState({
      selectedProductFamilies: t,
    });
  }

  changeSelectedProducts(productFamily, newList) {
    this.setState((prevState) => {
      const selectedProducts = { ...prevState.selectedProducts };
      selectedProducts[productFamily] = newList;
      return { selectedProducts };
    });
  }

  changeTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  changeCve(e) {
    this.setState({
      cve: e.target.value,
    });
  }

  changeImpact(e) {
    this.setState({
      impact: e.target.value,
    });
  }

  changeStatus(e) {
    this.setState({
      status: e.target.value,
    });
  }

  changeScore(e) {
    this.setState({
      cvssScore: e.target.value,
    });
  }

  changeVector(e) {
    this.setState({
      cvssVector: e.target.value,
    });
  }

  changeSummary(e) {
    this.setState({
      summary: e.target.value,
    });
  }

  changeWorkaround(e) {
    this.setState({
      workaround: e.target.value,
    });
  }

  changeCredits(e) {
    this.setState({
      credits: e.target.value,
    });
  }

  changeReferences(e) {
    this.setState({
      references: e.target.value,
    });
  }

  changeAffected(e) {
    this.setState({
      affected: e.target.value,
    });
  }

  changeResolution(e) {
    this.setState({
      resolution: e.target.value,
    });
  }

  toggleSummaryPreview() {
    this.setState((prevState) => ({ summaryPreviewOpened: !prevState.summaryPreviewOpened }));
  }

  toggleWorkaroundPreview() {
    this.setState((prevState) => ({ workaroundPreviewOpened: !prevState.workaroundPreviewOpened }));
  }

  toggleAffectedPreview() {
    this.setState((prevState) => ({ affectedPreviewOpened: !prevState.affectedPreviewOpened }));
  }

  toggleResolutionPreview() {
    this.setState((prevState) => ({ resolutionPreviewOpened: !prevState.resolutionPreviewOpened }));
  }

  buildProductArray() {
    const {
      products,
      selectedProducts,
    } = this.state;

    const productArray = [];

    Object.keys(selectedProducts).forEach((family) => {
      selectedProducts[family].forEach((p) => {
        const product = products.find((o) => o.product_family === family && o.product_branch === p);
        if (product) {
          productArray.push(product);
        }
      });
    });

    return productArray;
  }

  submitAdvisory() {
    const {
      title,
      summary,
      affected,
      resolution,
      impact,
      status,
      workaround,
      cveArray,
      creditsArray,
      referencesArray,
      cvssScore,
      cvssVector,
    } = this.state;

    const productArray = this.buildProductArray();
    const hasWorkaround = workaround !== '';
    const advisory = {
      title,
      summary,
      affected: affected || '',
      resolution: resolution || '',
      cve: cveArray,
      impact,
      status,
      cvss_score: cvssScore,
      cvss_vector: cvssVector,
      products: productArray,
      has_workaround: hasWorkaround,
      workaround: workaround || '',
      credits: creditsArray,
      references: referencesArray,
    };

    return sendAdvisory(advisory)
      .then((response) => {
        const { history } = this.props;
        history.push(`/PSIRT/Advisory/WGSA-${response.advisory_id.year}-${response.advisory_id.id}`);
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.message,
        });
      });
  }

  nextStage(event) {
    event.preventDefault();
    this.setState((prevState) => {
      const stage = prevState.stage + 1;

      if (prevState.stage === 3) {
        let cveArray = [];
        let creditsArray = [];
        let referencesArray = [];

        if (prevState.cve.trim() !== '') {
          cveArray = prevState.cve.split(',');
        }
        if (cveArray.length) cveArray = cveArray.map((e) => e.trim());

        if (prevState.credits.trim() !== '') {
          creditsArray = prevState.credits.split(',');
          if (creditsArray.length) creditsArray = creditsArray.map((e) => e.trim());
        }

        if (prevState.references.trim() !== '') {
          referencesArray = prevState.references.split(',');
          if (referencesArray.length) referencesArray = referencesArray.map((e) => e.trim());
        }

        return {
          cveArray,
          creditsArray,
          referencesArray,
          stage,
        };
      }
      if (prevState.stage === 4) {
        return this.submitAdvisory();
      }
      return {
        stage,
      };
    });
  }

  prevStage() {
    const { history } = this.props;
    this.setState((prevState) => {
      if (prevState.stage === 1) {
        return history.push('/PSIRT');
      }

      const stage = prevState.stage - 1;
      if (prevState.stage === 2) {
        const selectedProducts = Object.fromEntries(prevState.productFamilies.map((e) => [e, []]));
        return {
          selectedProducts,
          stage,
        };
      }
      if (prevState.stage === 3) {
        return {
          title: '',
          cve: '',
          impact: 'Critical',
          status: 'Investigating',
          cvssScore: '',
          cvssVector: '',
          summary: '',
          workaround: '',
          stage,
        };
      }
      return {
        stage,
      };
    });
  }

  render() {
    const {
      isLoading,
      products,
      productFamilies,
      selectedProductFamilies,
      selectedProducts,
      title,
      cve,
      impact,
      status,
      cvssScore,
      cvssVector,
      summary,
      workaround,
      affected,
      resolution,
      credits,
      references,
      creditsArray,
      referencesArray,
      cveArray,
      stage,
      summaryPreviewOpened,
      workaroundPreviewOpened,
      affectedPreviewOpened,
      resolutionPreviewOpened,
      errorMessage,
    } = this.state;

    return (
      <div id="AddAdvisory">
        <Row>
          <Col xs={12} className="page-title">
            <h1>Add Advisory</h1>
          </Col>
        </Row>
        {errorMessage
          ? <div className="error-message">{errorMessage}</div>
          : ''}
        {stage === 0 && isLoading
          ? <LoadingMessage />
          : ''}
        {stage === 1
          ? (
            <SelectProductFamily
              productFamilies={productFamilies}
              selected={selectedProductFamilies}
              onChange={this.changeProductFamily}
              nextStage={this.nextStage}
              prevStage={this.prevStage}
            />
          )
          : ''}
        {stage === 2
          ? (
            <SelectProducts
              productFamilies={selectedProductFamilies}
              products={products}
              selected={selectedProducts}
              onChange={this.changeSelectedProducts}
              nextStage={this.nextStage}
              prevStage={this.prevStage}
            />
          )
          : ''}
        {stage === 3
          ? (
            <AdvisoryDetails
              title={title}
              cve={cve}
              impact={impact}
              status={status}
              cvssScore={cvssScore}
              cvssVector={cvssVector}
              summary={summary}
              workaround={workaround}
              affected={affected}
              resolution={resolution}
              credits={credits}
              references={references}
              summaryPreviewOpened={summaryPreviewOpened}
              workaroundPreviewOpened={workaroundPreviewOpened}
              affectedPreviewOpened={affectedPreviewOpened}
              resolutionPreviewOpened={resolutionPreviewOpened}
              changeTitle={this.changeTitle}
              changeCve={this.changeCve}
              changeImpact={this.changeImpact}
              changeStatus={this.changeStatus}
              changeScore={this.changeScore}
              changeVector={this.changeVector}
              changeSummary={this.changeSummary}
              changeWorkaround={this.changeWorkaround}
              changeCredits={this.changeCredits}
              changeReferences={this.changeReferences}
              changeAffected={this.changeAffected}
              changeResolution={this.changeResolution}
              toggleSummaryPreview={this.toggleSummaryPreview}
              toggleWorkaroundPreview={this.toggleWorkaroundPreview}
              toggleAffectedPreview={this.toggleAffectedPreview}
              toggleResolutionPreview={this.toggleResolutionPreview}
              nextStage={this.nextStage}
              prevStage={this.prevStage}
            />
          )
          : ''}
        {stage === 4
          ? (
            <AdvisoryReview
              title={title}
              cve={cveArray}
              impact={impact}
              status={status}
              cvssScore={cvssScore}
              cvssVector={cvssVector}
              summary={summary}
              affected={affected}
              resolution={resolution}
              workaround={workaround}
              credits={creditsArray}
              references={referencesArray}
              products={selectedProducts}
              nextStage={this.submitAdvisory}
              prevStage={this.prevStage}
            />
          )
          : ''}
      </div>
    );
  }
}

AddAdvisory.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AddAdvisory;
