import './BreachDetails.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import BreachStatusBlock from '../BreachStatusBlock';
import UpdateBreachForm from '../Forms/UpdateBreachForm';

import ProcessBreachInstructions from '../BreachInstructions/ProcessBreachInstructions';
import EnableBreachInstructions from '../BreachInstructions/EnableBreachInstructions';
import NotificationInstructions from '../BreachInstructions/NotificationInstructions';
import DisableBreachInstructions from '../BreachInstructions/DisableBreachInstructions';

import axios from '../../utils/axios';

function getBreachDetails(id) {
  return new Promise((resolve, reject) => {
    axios.get(`https://api.tat.wgtl.io/api/v1/dws/breach/get/${id}`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data.breach);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for breach'));
      })
      .catch(() => reject(new Error('Error searching for breach')));
  });
}

function toggleBreach(postData) {
  return new Promise((resolve, reject) => {
    axios.post('https://api.tat.wgtl.io/api/v1/dws/breach/toggle', postData)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data.breach);
        }
        return reject(new Error('Error toggling breach'));
      })
      .catch((error) => reject(error));
  });
}

function sendWGCNotification(postData) {
  return new Promise((resolve, reject) => {
    axios.post('https://api.tat.wgtl.io/api/v1/dws/breach/notify/wgc', postData)
      .then((res) => {
        if (res.status === 200) {
          return resolve();
        }
        return reject(new Error('Error sending WGC notification'));
      })
      .catch((error) => reject(error));
  });
}

function sendDomeNotification(postData) {
  return new Promise((resolve, reject) => {
    axios.post('https://api.tat.wgtl.io/api/v1/dws/breach/notify/dome', postData)
      .then((res) => {
        if (res.status === 200) {
          return resolve();
        }
        return reject(new Error('Error sending Dome notification'));
      })
      .catch((error) => reject(error));
  });
}

class BreachDetails extends React.Component {
  constructor(props) {
    super(props);
    const { match } = this.props;

    this.state = {
      isSearching: true,
      isWorking: false,
      isNotifyingDome: false,
      isNotifyingWGC: false,
      breachId: match.params.breachId,
      title: null,
      management: null,
      enabled: null,
      breach: null,
    };

    this.toggleBreach = this.toggleBreach.bind(this);
    this.breachUpdated = this.breachUpdated.bind(this);
    this.refreshBreachStatus = this.refreshBreachStatus.bind(this);
    this.sendWGCNotification = this.sendWGCNotification.bind(this);
    this.sendDomeNotification = this.sendDomeNotification.bind(this);
  }

  componentDidMount() {
    const { breachId } = this.state;
    getBreachDetails(breachId)
      .then((breach) => {
        this.setState({
          isSearching: false,
          breach,
          title: breach.title,
          management: breach.management,
          enabled: breach.is_enabled,
        });
        if (!breach.management.parsing.started || !breach.management.parsing.completed) {
          this.statusCheck = setInterval(this.refreshBreachStatus, 30000);
        }
      })
      .catch(() => {
        this.setState({ isSearching: false });
      });
  }

  componentWillUnmount() {
    if (this.statusCheck != null) {
      clearInterval(this.statusCheck);
    }
  }

  refreshBreachStatus() {
    const { breachId, management } = this.state;
    getBreachDetails(breachId)
      .then((breach) => {
        if (breach.management !== management) {
          this.setState({
            management: breach.management,
          });
        }
      })
      .catch(() => {
        this.setState({ isSearching: false });
      });
  }

  sendWGCNotification(event) {
    const {
      breachId,
    } = this.state;

    if (event) {
      event.preventDefault();
    }

    const postData = {
      id: breachId,
    };

    this.setState({
      isNotifyingWGC: true,
    });

    sendWGCNotification(postData)
      .then(() => {
        this.setState({ isNotifyingWGC: false });
        this.refreshBreachStatus();
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isNotifyingWGC: false });
      });
  }

  sendDomeNotification(event) {
    const {
      breachId,
    } = this.state;

    if (event) {
      event.preventDefault();
    }

    const postData = {
      id: breachId,
    };

    this.setState({
      isNotifyingDome: true,
    });

    sendDomeNotification(postData)
      .then(() => {
        this.setState({ isNotifyingDome: false });
        this.refreshBreachStatus();
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isNotifyingDome: false });
      });
  }

  toggleBreach(event) {
    const {
      breachId,
      enabled,
    } = this.state;

    if (event) {
      event.preventDefault();
    }

    const postData = {
      id: breachId,
      enabled: !enabled,
    };

    this.setState({
      isWorking: true,
    });

    toggleBreach(postData)
      .then((breach) => {
        this.setState({
          isWorking: false,
          breach,
          enabled: breach.is_enabled,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isWorking: false,
        });
      });
  }

  breachUpdated(breach) {
    this.setState({
      management: breach.management,
      title: breach.title,
      enabled: breach.is_enabled,
    });
  }

  render() {
    const {
      breach,
      breachId,
      isSearching,
      isWorking,
      isNotifyingDome,
      isNotifyingWGC,
      title,
      management,
      enabled,
    } = this.state;

    return (
      <div id="BreachDatabaseView">
        <Row>
          <Col xs={12} className="dashboard-item">
            <div id="BreachDatabaseList">
              {isSearching
                ? (
                  <div className="searching"><i className="fas fa-spinner fa-spin searching-spinner" /></div>
                )
                : ''}
              {title
                ? (
                  <div>
                    <Row>
                      <Col xs={12} className="breach-title-section">
                        <h2 className="breach-title">
                          Breach Name:
                          {' '}
                          {title}
                        </h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={{ span: 8, offset: 2 }} className="breach-status-section">
                        <BreachStatusBlock
                          management={management}
                          enabled={enabled}
                        />
                      </Col>
                    </Row>
                    {management && management.parsing && !management.parsing.completed
                      ? (
                        <Row>
                          <Col xs={{ span: 8, offset: 2 }} className="breach-status-section">
                            <ProcessBreachInstructions breachId={breachId} />
                          </Col>
                        </Row>
                      )
                      : ''}
                    {management && management.parsing && management.parsing.completed && !enabled
                      ? (
                        <Row>
                          <Col xs={{ span: 8, offset: 2 }} className="breach-status-section">
                            <EnableBreachInstructions
                              isWorking={isWorking}
                              toggleBreach={this.toggleBreach}
                            />
                          </Col>
                        </Row>
                      )
                      : ''}
                    {management && management.parsing && management.parsing.completed && enabled
                      && (!management.notification.wgc.completed
                      || !management.notification.dome.completed)
                      ? (
                        <Row>
                          <Col xs={{ span: 8, offset: 2 }} className="breach-status-section">
                            <NotificationInstructions
                              isWorking={isNotifyingWGC}
                              isNotifyingWGC={isNotifyingWGC}
                              isNotifyingDome={isNotifyingDome}
                              management={management}
                              toggleBreach={this.toggleBreach}
                              sendDomeNotification={this.sendDomeNotification}
                              sendWGCNotification={this.sendWGCNotification}
                            />
                          </Col>
                        </Row>
                      )
                      : ''}
                    {management && management.parsing && management.parsing.completed && enabled
                      && management.notification.wgc.completed
                      && management.notification.dome.completed
                      ? (
                        <Row>
                          <Col xs={{ span: 8, offset: 2 }} className="breach-status-section">
                            <DisableBreachInstructions
                              isWorking={isWorking}
                              toggleBreach={this.toggleBreach}
                            />
                          </Col>
                        </Row>
                      )
                      : ''}
                    <UpdateBreachForm breachUpdated={this.breachUpdated} breach={breach} />
                  </div>
                )
                : ''}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

BreachDetails.propTypes = {
  match: PropTypes.object.isRequired,
};

export default BreachDetails;
