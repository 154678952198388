import './IncidentTable.css';

import * as React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';

function IncidentTable(props) {
  const {
    incidents,
    columns,
    keyField,
    defaultSorted,
    noTableData,
  } = props;
  return (
    <div id="IncidentTable">
      <BootstrapTable
        table-responsive
        condensed
        keyField={keyField}
        data={incidents}
        columns={columns}
        defaultSorted={defaultSorted}
        headerClasses="incident-table-header"
        rowClasses="incident-table-row"
        noDataIndication={noTableData}
      />
    </div>
  );
}

IncidentTable.propTypes = {
  incidents: PropTypes.array,
  columns: PropTypes.array,
  keyField: PropTypes.string.isRequired,
  noTableData: PropTypes.func.isRequired,
  defaultSorted: PropTypes.array,
};

IncidentTable.defaultProps = {
  incidents: [],
  columns: [],
  defaultSorted: [],
};

export default IncidentTable;
