import './MalwareHashes.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import IncidentTable from '../IncidentTable';

import axios from '../../utils/axios';

function getHashes(threatId) {
  return new Promise((resolve, reject) => {
    axios.get(`https://api.tat.wgtl.io/api/v1/threat/malware/hashes/${threatId}`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for threats'));
      })
      .catch(() => reject(new Error('Error searching for threats')));
  });
}

class MalwareHashes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: true,
      incidents: null,
    };
  }

  componentDidMount() {
    const {
      threatId,
    } = this.props;
    getHashes(threatId)
      .then((result) => {
        this.setState({
          isSearching: false,
          incidents: result,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isSearching: false,
        });
      });
  }

  render() {
    const {
      isSearching,
      incidents,
    } = this.state;

    const columns = [{
      dataField: '_id',
      text: 'MD5',
      formatter: (cell) => (
        <Link className="table-link" to={`/Threat/MD5/${cell}`}>{cell}</Link>
      ),
    }, {
      dataField: 'total',
      text: 'Count',
    }, {
      dataField: 'hasReport',
      text: 'Report',
      formatter: (cell, row) => {
        if (row.hasReport) {
          if (row.malwarePresence === 'MALICIOUS') {
            return (
              <div>
                <div data-tip data-for={`report-status-${row._id}`}>
                  <i className="fas fa-circle report-found" />
                </div>
                <ReactTooltip id={`report-status-${row._id}`}>
                  <span>Reportorted as malicious</span>
                </ReactTooltip>
              </div>
            );
          }
          if (row.malwarePresence === 'KNOWN') {
            return (
              <div>
                <div data-tip data-for={`report-status-${row._id}`}>
                  <i className="fas fa-circle report-clean" />
                </div>
                <ReactTooltip id={`report-status-${row._id}`}>
                  <span>Reported as clean</span>
                </ReactTooltip>
              </div>
            );
          }
          return (
            <div>
              <div data-tip data-for={`report-status-${row._id}`}>
                <i className="fas fa-circle report-empty" />
              </div>
              <ReactTooltip id={`report-status-${row._id}`}>
                <span>No report available</span>
              </ReactTooltip>
            </div>
          );
        }
        return (
          <div>
            <div data-tip data-for={`report-status-${row._id}`}>
              <i className="fas fa-circle report-none" />
            </div>
            <ReactTooltip id={`report-status-${row._id}`}>
              <span>No report attempted</span>
            </ReactTooltip>
          </div>
        );
      },
    }, {
      dataField: 'threatInfoPlatform',
      isDummyField: true,
      text: 'Platform',
      formatter: (cell, row) => {
        if (row.threatInfo) {
          return <span>{row.threatInfo.platform}</span>;
        }
        return '';
      },
    }, {
      dataField: 'threatInfoSub',
      isDummyField: true,
      text: 'Subplatform',
      formatter: (cell, row) => {
        if (row.threatInfo) {
          return <span>{row.threatInfo.subplatform}</span>;
        }
        return '';
      },
    }, {
      dataField: 'threatInfoType',
      isDummyField: true,
      text: 'Type',
      formatter: (cell, row) => {
        if (row.threatInfo) {
          return <span>{row.threatInfo.threatType}</span>;
        }
        return '';
      },
    }, {
      dataField: 'threatInfoFamily',
      isDummyField: true,
      text: 'Family',
      formatter: (cell, row) => {
        if (row.threatInfo) {
          return <span>{row.threatInfo.familyName}</span>;
        }
        return '';
      },
    }, {
      dataField: 'download',
      isDummyField: true,
      text: 'Download',
      formatter: (cell, row) => {
        if (row.downloadUrl) {
          return (
            <a href={row.downloadUrl}>
              <i className="fas fa-cloud-download-alt download-icon" />
            </a>
          );
        }
        return '';
      },
    }];

    const noTableData = () => {
      if (isSearching) {
        return (<div className="loading">Loading</div>);
      }
      return (<div className="no-data-label">No incidents found</div>);
    };

    return (
      <div id="MalwareHashes">
        {isSearching
          ? (
            <div className="searching"><i className="fas fa-spinner fa-spin searching-spinner" /></div>
          )
          : ''}
        {incidents
          ? (
            <div>
              <Row>
                <Col xs={12}>
                  <IncidentTable
                    incidents={incidents}
                    columns={columns}
                    keyField="_id"
                    noTableData={noTableData}
                  />
                </Col>
              </Row>
            </div>
          )
          : ''}
      </div>
    );
  }
}

MalwareHashes.propTypes = {
  threatId: PropTypes.string.isRequired,
};

export default MalwareHashes;
