import './MalwareThreatTile.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

function MalwareThreatTile(props) {
  const {
    threat,
  } = props;
  function formatTime(d) {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ];
    const date1 = new Date();
    const date2 = new Date(d);
    const diff = Math.floor(date1.getTime() - date2.getTime());
    const day = 1000 * 60 * 60 * 24;
    const minute = 1000 * 60;
    const minutes = Math.floor(diff / minute);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(diff / day);
    const months = Math.floor(days / 31);
    const years = Math.floor(months / 12);
    let message = '';
    if (years > 0 || months > 0) {
      message = `${monthNames[date2.getMonth()]} ${date2.getDate()}, ${date2.getFullYear()}`;
    } else if (days > 0) {
      message += days;
      if (days > 1) {
        message += ' days ago';
      } else {
        message += ' day ago';
      }
    } else if (hours > 0) {
      message += hours;
      if (hours > 1) {
        message += ' hours ago';
      } else {
        message += ' hour ago';
      }
    } else if (minutes > 0) {
      message += minutes;
      if (minutes > 1) {
        message += ' minutes ago';
      } else {
        message += ' minute ago';
      }
    }
    return message;
  }

  function getAnalysisBlock() {
    if (threat.needsAnalysis) {
      return (
        <div className="analysis-status needs-analysis" data-tip data-for={`analysis-status-${threat._id}`}>
          <i className="fas fa-exclamation-triangle" />
        </div>
      );
    }
    if (threat.analysis && threat.analysis.length > 0) {
      return (
        <div className="analysis-status analysis-complete" data-tip data-for={`analysis-status-${threat._id}`}>
          <i className="fas fa-diagnoses" />
        </div>
      );
    }
    return (
      <div className="analysis-status" data-tip data-for={`analysis-status-${threat._id}`}>
        <i className="fas fa-diagnoses" />
      </div>
    );
  }

  function getAnalysisStatus() {
    if (threat.needsAnalysis) {
      return 'Needs analysis';
    }
    if (threat.analysis && threat.analysis.length > 0) {
      return `${threat.analysis.length} analyses complete`;
    }
    return 'No analysis completed';
  }

  return (
    <div id={`malware-threat-${threat._id}`} className="MalwareThreatTile">
      <Row>
        <Col xs={12} md={4} className="malware-threat-title">
          {getAnalysisBlock()}
          <Link to={`/Threat/Malware/${threat._id}`}>{threat.name}</Link>
          <ReactTooltip id={`analysis-status-${threat._id}`}>
            <span>{getAnalysisStatus()}</span>
          </ReactTooltip>
        </Col>
        <Col xs={12} md={4} className="malware-threat-stats">
          <Row>
            <Col xs={12} className="malware-threat-stat">
              <span className="stat-label">First Seen: </span>
              {' '}
              {formatTime(threat.firstSeen)}
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="malware-threat-stat">
              <span className="stat-label">Last Seen: </span>
              {' '}
              {formatTime(threat.lastSeen)}
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={4} className="malware-threat-stats">
          <Row>
            <Col xs={12} className="malware-threat-stat">
              <span className="stat-label">Incidents: </span>
              {' '}
              {threat.incidentCount}
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="malware-threat-stat">
              <span className="stat-label">Unique Victims: </span>
              {' '}
              {threat.uniqueVictims}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

MalwareThreatTile.propTypes = {
  threat: PropTypes.object.isRequired,
};

export default MalwareThreatTile;
