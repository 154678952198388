import './MalwareHashDetails.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';

import LoadingOverlay from '../../Overlays/LoadingOverlay';
import ScanVendorResult from '../../ScanVendorResult';

import axios from '../../../utils/axios';

function getThreatDetails(md5) {
  return new Promise((resolve, reject) => {
    axios.get(`https://api.tat.wgtl.io/api/v1/threat/md5/${md5}`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for threats'));
      })
      .catch(() => reject(new Error('Error searching for threats')));
  });
}

function requestReport(md5) {
  return new Promise((resolve, reject) => {
    axios.get(`https://api.tat.wgtl.io/api/v1/threat/md5/${md5}/request`)
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for threats'));
      })
      .catch(() => reject(new Error('Error searching for threats')));
  });
}

class MalwareHashDetails extends React.Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      md5: match.params.md5,
      isSearching: true,
      isRequesting: false,
      threat: null,
    };
    this.requestFileReport = this.requestFileReport.bind(this);
  }

  componentDidMount() {
    const {
      md5,
    } = this.state;
    getThreatDetails(md5)
      .then((result) => {
        this.setState({
          isSearching: false,
          threat: result,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isSearching: false,
        });
      });
  }

  requestFileReport() {
    const { md5 } = this.state;
    this.setState({
      isRequesting: true,
    });
    requestReport(md5)
      .then((result) => {
        this.setState({
          isRequesting: false,
          threat: result,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isRequesting: false,
        });
      });
  }

  render() {
    const {
      isSearching,
      isRequesting,
      md5,
      threat,
    } = this.state;

    function formatPercentage(perc) {
      if (perc) {
        return `${perc.toFixed(2)}%`;
      }
      return 'Unknown';
    }

    function getLatestXref(reports) {
      if (reports.length === 0) {
        return reports;
      }
      reports.sort((a, b) => new Date(b.scanned_on) - new Date(a.scanned_on));
      return reports[0];
    }

    function getLatestAnalysis(reports) {
      if (reports.length === 0) {
        return null;
      }
      const filtered = reports.filter((o) => o.analysis_type === 'TC_REPORT');
      if (!filtered) {
        return null;
      }
      filtered.sort((a, b) => new Date(b.record_time) - new Date(a.record_time));
      return filtered[0];
    }

    function getIsGeneric() {
      if (threat.threatInfo) {
        if (threat.threatInfo.isGeneric === true) {
          return 'True';
        }
        if (threat.threatInfo.isGeneric === false) {
          return 'False';
        }
      }
      return 'Unknown';
    }

    let latestAnalysis;
    if (threat) {
      latestAnalysis = getLatestAnalysis(threat.rl.analysis);
    }
    console.log(latestAnalysis);

    return (
      <div id="MalwareHashDetails">
        {isSearching
          ? <LoadingOverlay />
          : ''}
        {threat
          ? (
            <div>
              <Row>
                <Col xs={12} className="threat-title">
                  <h1>{md5}</h1>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="threat-details-container">
                  <div className="threat-details-content">
                    <div className="inline-detail">
                      <span className="detail-label">Verdict: </span>
                      {' '}
                      {threat.rl.malware_presence}
                    </div>
                    <div className="inline-detail">
                      <span className="detail-label">Scanner Percentage: </span>
                      {' '}
                      {formatPercentage(threat.rl.scanner_percent)}
                    </div>
                    <div className="inline-detail">
                      <span className="detail-label">BitDefender: </span>
                      {' '}
                      {threat.bdName || 'Unknown'}
                    </div>
                    <div className="inline-detail">
                      <span className="detail-label">Panda: </span>
                      {' '}
                      {threat.pandaName || 'Unknown'}
                    </div>
                    <div className="inline-detail">
                      <span className="detail-label">Generic: </span>
                      {' '}
                      {getIsGeneric()}
                    </div>
                    {threat.downloadUrl
                      ? (
                        <div className="inline-detail right">
                          <a href={threat.downloadUrl}>
                            <i className="fas fa-cloud-download-alt download-icon" />
                          </a>
                        </div>
                      )
                      : ''}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={8} className="threat-details-container hashes">
                  <div className="threat-details-content">
                    <div className="threat-detail">
                      <span className="detail-label">SHA1: </span>
                      {threat.sha1 || 'Unknown'}
                    </div>
                    <div className="threat-detail">
                      <span className="detail-label">SHA256: </span>
                      {threat.sha256 || 'Unknown'}
                    </div>
                    <div className="threat-detail">
                      <span className="detail-label">SSDEEP: </span>
                      {threat.ssdeep || 'Unknown'}
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={4} className="threat-details-container info">
                  {threat.threatInfo
                    ? (
                      <div className="threat-details-content">
                        <div className="threat-detail">
                          <span className="detail-label">Platform: </span>
                          {threat.threatInfo.platform || 'Unknown'}
                        </div>
                        <div className="threat-detail">
                          <span className="detail-label">Subplatform: </span>
                          {threat.threatInfo.subplatform || 'Unknown'}
                        </div>
                        <div className="threat-detail">
                          <span className="detail-label">Type: </span>
                          {threat.threatInfo.threatType || 'Unknown'}
                        </div>
                        <div className="threat-detail">
                          <span className="detail-label">Family: </span>
                          {threat.threatInfo.familyName || 'Unknown'}
                        </div>
                      </div>
                    )
                    : ''}
                </Col>
              </Row>
              {latestAnalysis && latestAnalysis.tc_report.interesting_strings
                && latestAnalysis.tc_report.interesting_strings.length > 0
                ? (
                  <Row>
                    <Col xs={12} className="threat-details-container">
                      <div className="threat-details-content">
                        <h5>Interesting Strings</h5>
                        <div className="interesting-strings">
                          {latestAnalysis.tc_report.interesting_strings.map((string) => (
                            <div className="interesting-string">
                              <div className="interesting-string-type">
                                {string.category}
                              </div>
                              <ul>
                                {string.values.map((value) => (
                                  <li className="interesting-string-value">
                                    {value}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Col>
                  </Row>
                )
                : ''}
              {threat.rl && threat.rl.xref && threat.rl.xref.length > 0
                ? (
                  <Row>
                    <Col xs={12} className="threat-details-container">
                      <div className="threat-details-content">
                        <Row>
                          {getLatestXref(threat.rl.xref).results.map((result) => (
                            <Col xs={12} md={4}>
                              <ScanVendorResult result={result} />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                )
                : ''}
            </div>
          )
          : (
            <div>
              <Row>
                <Col xs={12} className="threat-title">
                  <h1>{md5}</h1>
                </Col>
              </Row>
              {isSearching
                ? ''
                : (
                  <Row>
                    <Col xs={12} className="no-threat-report">
                      No threat report available. Request one below.
                    </Col>
                  </Row>
                )}
              {isSearching
                ? ''
                : (
                  <Row>
                    <Col xs={12} className="no-threat-report">
                      <Button onClick={this.requestFileReport}>Request</Button>
                      {isRequesting
                        ? (
                          <div className="processing-request">
                            <i className="fas fa-spinner fa-spin requested-spinner" />
                          </div>
                        )
                        : ''}
                    </Col>
                  </Row>
                )}
            </div>
          )}
      </div>
    );
  }
}

MalwareHashDetails.propTypes = {
  match: PropTypes.object.isRequired,
};

export default MalwareHashDetails;
