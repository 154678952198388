import './AnalysisStatBlock.css';

import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import axios from '../../utils/axios';

function getFlaggedCount() {
  return new Promise((resolve, reject) => {
    axios.get('https://api.tat.wgtl.io/api/v1/threat/analysis/count')
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data.count);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for threats'));
      })
      .catch(() => reject(new Error('Error searching for threats')));
  });
}

function getCompletedCount() {
  return new Promise((resolve, reject) => {
    axios.get('https://api.tat.wgtl.io/api/v1/threat/analysis/completed/count')
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data.count);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for threats'));
      })
      .catch(() => reject(new Error('Error searching for threats')));
  });
}

class AnalysisStatBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flaggedCount: 0,
      completedCount: 0,
      isUpdating: false,
    };
  }

  componentDidMount() {
    getFlaggedCount()
      .then((count) => {
        this.setState({
          flaggedCount: count,
          isUpdating: false,
        });
      })
      .catch(() => {
        this.setState({
          isUpdating: false,
        });
      });
    getCompletedCount()
      .then((count) => {
        this.setState({
          completedCount: count,
          isUpdating: false,
        });
      })
      .catch(() => {
        this.setState({
          isUpdating: false,
        });
      });
  }

  render() {
    const {
      flaggedCount,
      completedCount,
      isUpdating,
    } = this.state;
    console.log(isUpdating);
    return (
      <div id="AnalysisStatBlock">
        <Row>
          <Col xs={12} sm={4} className="stat-item label">
            {flaggedCount > 0
              ? (
                <Link to="/Analysis/Queue" className="count-link">{flaggedCount}</Link>
              )
              : '0'}
            {' '}
            Needs Analysis
          </Col>
          <Col xs={12} sm={4} className="stat-item label">
            0 Stale Analysis
          </Col>
          <Col xs={12} sm={4} className="stat-item label">
            {completedCount > 0
              ? (
                <Link to="/Analysis/Recent" className="count-link">{completedCount}</Link>
              )
              : '0'}
            {' '}
            Analyized
          </Col>
        </Row>
      </div>
    );
  }
}

export default AnalysisStatBlock;
