import './DarkWebScan.css';

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import BreachDatabaseList from '../BreachDatabaseList';

export default function DarkWebScan() {
  return (
    <div id="DarkWebScan">
      <Row>
        <Col xs={12} className="page-title">
          <h1>Dark Web Scan Management</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="management-links">
          <Button className="add-breach-button" href="/DarkWebScan/Instructions">Add Breach</Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="dashboard-item">
          <BreachDatabaseList />
        </Col>
      </Row>
    </div>
  );
}
