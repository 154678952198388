import './PrimaryButton.css';

import * as React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

function PrimaryButton(props) {
  const {
    buttonText,
    onClick,
    disabled,
  } = props;
  return (
    <Button
      className="PrimaryButton"
      type="button"
      tabIndex="0"
      onClick={onClick}
      onKeyPress={onClick}
      disabled={disabled}
    >
      {buttonText}
    </Button>
  );
}

PrimaryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

PrimaryButton.defaultProps = {
  disabled: false,
};

export default PrimaryButton;
