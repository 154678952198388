import './ProcessBreachInstructions.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

function ProcessBreachInstructions(props) {
  const {
    breachId,
  } = props;
  return (
    <div id="ProcessBreachInstructions">
      <Row>
        <Col xs={12} className="dashboard-item">
          <h5>Next Steps: Process the breach</h5>
          <p>
            It looks like we haven&apos;t started processing this breach yet. To process this
            breach, make sure the pre-parsed files are located in the S3 bucket location saved
            in the breach details below. Next start one of the darkweb scan processing EC2
            instances and run the command below.
          </p>
          <p className="command-text">
            python3 /opt/darkwebscan/process.py
            {' '}
            {breachId}
          </p>
        </Col>
      </Row>

    </div>
  );
}

ProcessBreachInstructions.propTypes = {
  breachId: PropTypes.string.isRequired,
};

export default ProcessBreachInstructions;
