import './LoadingOverlay.css';

import * as React from 'react';

function LoadingOverlay() {
  return (
    <div id="LoadingOverlay">
      <div id="loading-overlay-content">
        <span><i className="fas fa-spinner fa-spin loading-spinner" /></span>
      </div>
    </div>
  );
}

export default LoadingOverlay;
