import './FileThreatTile.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
/*
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
*/

function FileThreatTile(props) {
  const {
    hash,
    threat,
  } = props;

  function getReportStatus() {
    if (threat) {
      if (threat.rl.malware_presence === 'MALICIOUS') {
        return (
          <div className="file-report-status">
            <div data-tip data-for={`report-status-${hash}`}>
              <i className="fas fa-circle report-found" />
            </div>
            <ReactTooltip id={`report-status-${hash}`}>
              <span>Reportorted as malicious</span>
            </ReactTooltip>
          </div>
        );
      }
      if (threat.rl.malware_presence === 'KNOWN') {
        return (
          <div className="file-report-status">
            <div data-tip data-for={`report-status-${hash}`}>
              <i className="fas fa-circle report-clean" />
            </div>
            <ReactTooltip id={`report-status-${hash}`}>
              <span>Reported as clean</span>
            </ReactTooltip>
          </div>
        );
      }
      return (
        <div className="file-report-status">
          <div data-tip data-for={`report-status-${hash}`}>
            <i className="fas fa-circle report-empty" />
          </div>
          <ReactTooltip id={`report-status-${hash}`}>
            <span>No report available</span>
          </ReactTooltip>
        </div>
      );
    }
    return (
      <div className="file-report-status">
        <div data-tip data-for={`report-status-${hash}`}>
          <i className="fas fa-circle report-none" />
        </div>
        <ReactTooltip id={`report-status-${hash}`}>
          <span>No report attempted</span>
        </ReactTooltip>
      </div>
    );
  }

  return (
    <div id={`malware-threat-${hash}`} className="FileThreatTile">
      <div className="file-report">
        {getReportStatus()}
        <div className="hash-link">
          <Link className="table-link" to={`/Threat/MD5/${hash}`}>{hash}</Link>
        </div>
        <div className="report-info">
          <span className="label">Bitdefender:</span>
          {' '}
          {threat && threat.bdName
            ? (
              threat.bdName
            )
            : 'Unknown'}
        </div>
        <div className="report-info">
          <span className="label">Panda:</span>
          {' '}
          {threat && threat.pandaName
            ? (
              threat.pandaName
            )
            : 'Unknown'}
        </div>
      </div>
    </div>
  );
}

FileThreatTile.propTypes = {
  hash: PropTypes.string.isRequired,
  threat: PropTypes.object,
};

FileThreatTile.defaultProps = {
  threat: null,
};

export default FileThreatTile;
