import './Search.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import ThreatSearchForm from '../Forms/ThreatSearchForm';
import SearchResultCategory from '../SearchResultCategory';

function Search(props) {
  const {
    searchResultsReceived,
    searchResults,
  } = props;
  return (
    <div id="Search">
      <Row>
        <Col xs={12} className="search-title">
          <h1>WGTAT Search</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="search-container">
          <ThreatSearchForm
            searchFilter={searchResults ? searchResults.search : ''}
            searchResultsReceived={searchResultsReceived}
          />
        </Col>
      </Row>
      {searchResults && searchResults.results && 'file' in searchResults.results
        ? (
          <Row>
            <Col xs={12} className="search-container malware-threats">
              <SearchResultCategory
                categoryName="File"
                searchTerm={searchResults.results.searchTerm}
                searchResult={searchResults.results.file}
              />
            </Col>
          </Row>
        )
        : ''}
      {searchResults
        && searchResults.results
        && searchResults.results.malwareThreats
        ? (
          <Row>
            <Col xs={12} className="search-container malware-threats">
              <SearchResultCategory
                categoryName="Malware"
                searchTerm={searchResults.results.searchTerm}
                searchResult={searchResults.results.malwareThreats}
              />
            </Col>
          </Row>
        )
        : ''}
    </div>
  );
}

Search.propTypes = {
  searchResultsReceived: PropTypes.func.isRequired,
  searchResults: PropTypes.object,
};

Search.defaultProps = {
  searchResults: null,
};

export default Search;
