import './AdvisoryDetailsView.css';

import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Row, Col } from 'react-bootstrap';
import PrimaryButton from '../../../Buttons/PrimaryButton';

export default function AdvisoryDetailsView(props) {
  const {
    cve,
    impact,
    status,
    cvssScore,
    cvssVector,
    summary,
    workaround,
    credits,
    references,
    products,
    affected,
    resolution,
    openEditProducts,
    user,
  } = props;

  const productObj = {};

  products.forEach((p) => {
    if (!(p.product_family in productObj)) {
      productObj[p.product_family] = [];
    }
    productObj[p.product_family].push(p.product_branch);
    productObj[p.product_family].sort();
  });

  return (
    <div id="AdvisoryDetailsView">
      <div id="AdvisoryDetailsViewContainer">
        <Row>
          <Col xs={12} className="advisory-details">
            <div className="advisory-details-content">
              <Row>
                <Col xs={12} md={2} className="detail-label">
                  CVE(s)
                </Col>
                <Col xs={12} md={10} className="detail-item">
                  {cve && cve.length && cve.join(', ')}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={2} className="detail-label">
                  Status
                </Col>
                <Col xs={12} md={10} className="detail-item">
                  {status}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={2} className="detail-label">
                  Impact
                </Col>
                <Col xs={12} md={10} className="detail-item">
                  {impact}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={2} className="detail-label">
                  CVSS Score
                </Col>
                <Col xs={12} md={10} className="detail-item">
                  {cvssScore}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={2} className="detail-label">
                  CVSS Vector
                </Col>
                <Col xs={12} md={10} className="detail-item">
                  {cvssVector}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={2} className="detail-label">
                  Credits
                </Col>
                <Col xs={12} md={10} className="detail-item">
                  {(credits && credits.length && credits.join(', ')) || ''}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="advisory-text-block">
            <h4>Summary</h4>
            <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
              {summary}
            </ReactMarkdown>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="advisory-text-block">
            <h4>Affected Versions</h4>
            <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
              {affected}
            </ReactMarkdown>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="advisory-text-block">
            <h4>Resolution</h4>
            <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
              {resolution}
            </ReactMarkdown>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="advisory-text-block">
            <h4>Workaround</h4>
            <ReactMarkdown className="markdown-preview" remarkPlugins={[remarkGfm]}>
              {workaround}
            </ReactMarkdown>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="advisory-product-section">
            <h4>Affected Products</h4>
            <div className="affected-products-content">
              {user && [1, 2, 6].includes(user.accountType)
                ? (
                  <PrimaryButton
                    buttonText="Edit"
                    onClick={openEditProducts}
                  />
                )
                : ''}
              {(productObj && Object.keys(productObj).map((f) => (
                <div className="product-family-block" key={f}>
                  <h5>{f}</h5>
                  <ul>
                    {productObj[f].map((p) => (
                      <li className="product-branch-block" key={p}>
                        {p}
                      </li>
                    ))}
                  </ul>
                </div>
              ))) || '' }
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="advisory-references-block">
            <h4>References</h4>
            <div className="advisory-references-list">
              <ul>
                {(references && references.length && references.map((e) => (
                  <li key={e}>{e}</li>
                ))) || ''}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

AdvisoryDetailsView.propTypes = {
  cve: PropTypes.array.isRequired,
  impact: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  cvssScore: PropTypes.string.isRequired,
  cvssVector: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  workaround: PropTypes.string.isRequired,
  credits: PropTypes.array.isRequired,
  references: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  affected: PropTypes.string.isRequired,
  resolution: PropTypes.string.isRequired,
  openEditProducts: PropTypes.func.isRequired,
  user: PropTypes.object,
};

AdvisoryDetailsView.defaultProps = {
  user: null,
};
