import './ProductList.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import IncidentTable from '../../IncidentTable';

export default function ProductList(props) {
  const {
    isSearching,
    products,
    errorMessage,
    editProduct,
  } = props;

  const columns = [{
    dataField: '_id',
    hidden: true,
    text: 'id',
  }, {
    dataField: 'product_family',
    text: 'Family',
    sort: true,
  }, {
    dataField: 'product_branch',
    text: 'Branch',
    formatter: (cell, row) => (
      <div
        role="button"
        className="edit-link"
        tabIndex={0}
        onClick={() => editProduct(row._id)}
        onKeyPress={() => editProduct(row._id)}
      >
        {cell}
      </div>
    ),
  }, {
    dataField: 'product_list',
    text: 'Products',
    formatter: (cell) => {
      if (!cell) { return ''; }
      return cell.join(', ');
    },
  }];

  const defaultSorted = [{
    dataField: 'product_family',
    order: 'asc',
  }];

  const noTableData = () => {
    if (isSearching) {
      return (<div className="loading">Loading</div>);
    }
    if (errorMessage) {
      return (<div className="no-data-label">{errorMessage}</div>);
    }
    return (<div className="no-data-label">No products found </div>);
  };

  return (
    <div id="ProductView">
      <Row>
        <Col xs={12} className="dashboard-item">
          <div id="ProductList">
            {isSearching
              ? (
                <div className="searching"><i className="fas fa-spinner fa-spin searching-spinner" /></div>
              )
              : ''}
            <div>
              <Row>
                <Col xs={12}>
                  <IncidentTable
                    incidents={products}
                    columns={columns}
                    keyField="_id"
                    noTableData={noTableData}
                    defaultSorted={defaultSorted}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

ProductList.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  editProduct: PropTypes.func.isRequired,
};
