import './AdvisoryVisibilityControls.css';

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

function AdvisoryVisibilityControls(props) {
  const {
    visibility,
    user,
    publishAdvisory,
    hideAdvisory,
    archiveAdvisory,
  } = props;

  return (
    <div id="AdvisoryVisibilityControls">
      <Row>
        <Col xs={12}>
          <div className="current-visibility">
            This advisory is currently
            { ' ' }
            <span className="visibility-value">{visibility}</span>
          </div>
        </Col>
      </Row>
      {user && (user.accountType === 1 || user.accountType === 6)
        ? (
          <Row>
            <Col xs={12}>
              <div className="visibility-controls">
                <Button
                  onClick={publishAdvisory}
                  type="submit"
                  className="visibility-control-button"
                  disabled={visibility === 'public'}
                >
                  Publish
                </Button>
                <Button
                  onClick={hideAdvisory}
                  type="submit"
                  className="visibility-control-button"
                  disabled={visibility === 'private'}
                >
                  Private
                </Button>
                <Button
                  onClick={archiveAdvisory}
                  type="submit"
                  className="visibility-control-button"
                  disabled={visibility === 'archived'}
                >
                  Archive
                </Button>
              </div>
            </Col>
          </Row>
        )
        : (
          <Row>
            <Col xs={12}>
              <div className="visibility-controls-non-admin">
                You do not have permissions to toggle advisory visibility
              </div>
            </Col>
          </Row>
        )}
    </div>
  );
}

AdvisoryVisibilityControls.propTypes = {
  visibility: PropTypes.string.isRequired,
  user: PropTypes.object,
  publishAdvisory: PropTypes.func.isRequired,
  hideAdvisory: PropTypes.func.isRequired,
  archiveAdvisory: PropTypes.func.isRequired,
};

AdvisoryVisibilityControls.defaultProps = {
  user: null,
};

export default AdvisoryVisibilityControls;
