import './LoadingMessage.css';

import React from 'react';

export default function LoadingMessage() {
  return (
    <div id="LoadingMessage">
      <div id="LoadingMessageContainer">
        <h3>
          Getting things ready...
        </h3>
        <div className="searching"><i className="fas fa-spinner fa-spin searching-spinner" /></div>
      </div>
    </div>
  );
}
