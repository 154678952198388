import './ProfileDropdown.css';

import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class ProfileDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClickLogout = this.handleClickLogout.bind(this);
    this.handleClickManageUsers = this.handleClickManageUsers.bind(this);
    this.openProfile = this.openProfile.bind(this);
    this.openChangePassword = this.openChangePassword.bind(this);
    this.openAuth = this.openAuth.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const { handleClose } = this.props;
    if (this.wrapperRef
        && !this.wrapperRef.current.contains(event.target)
        && event.target.tagName !== 'path'
        && event.target.id !== 'authbar-user-avatar'
        && event.target.id !== 'burger-menu-icon'
        && event.target.id !== 'burger-menu-wrapper') {
      handleClose();
    }
  }

  handleClickManageUsers() {
    const { handleClose, history } = this.props;
    history.push('/Users/Manage');
    handleClose();
  }

  handleClickLogout(event) {
    const { auth } = this.props;
    event.preventDefault();
    auth.logout();
  }

  openAuth(type) {
    const { handleClose, history } = this.props;
    handleClose();
    if (type === 'signup') {
      history.push('/Signup');
    } else if (type === 'login') {
      history.push('/Login');
    }
  }

  openProfile() {
    const { handleClose, history } = this.props;
    history.push('/Profile');
    handleClose();
  }

  openChangePassword() {
    const { handleClose, history } = this.props;
    history.push('/ChangePassword');
    handleClose();
  }

  render() {
    const {
      user,
      handleClose,
    } = this.props;
    // const profile = auth.getUserProfile();
    return (
      <Container className={process.platform === 'win32' ? 'ProfileMenu windows' : 'ProfileMenu'} ref={this.wrapperRef}>
        <Row className="profile-menu-item">
          <Col xs={12} id="profile-menu-dashboard" className="profile-menu-auth dashboard">
            <Link to="/Dashboard" onClick={handleClose}>
              <div><i className="fas fa-tachometer-alt profile-menu-item-icon" /></div>
              <div className="profile-menu-item-label">Dashboard</div>
            </Link>
          </Col>
        </Row>
        <Row className="profile-menu-item">
          <Col xs={12} id="profile-menu-dashboard" className="profile-menu-auth dashboard">
            <Link to="/Search" onClick={handleClose}>
              <div><i className="fas fa-search profile-menu-item-icon" /></div>
              <div className="profile-menu-item-label">Search</div>
            </Link>
          </Col>
        </Row>
        <Row className="profile-menu-item">
          <Col xs={12} id="profile-menu-dashboard" className="profile-menu-auth dashboard">
            <Link to="/DarkWebScan" onClick={handleClose}>
              <div><i className="fas fa-skull-crossbones profile-menu-item-icon" /></div>
              <div className="profile-menu-item-label">Dark Web Scan</div>
            </Link>
          </Col>
        </Row>
        {user && user.accountType === 1
          ? (
            <Row className="profile-menu-item">
              <Col xs={12} id="profile-menu-logout" className="profile-menu-auth logout" onClick={this.handleClickManageUsers}>
                <div><i className="fas fa-users profile-menu-item-icon" /></div>
                <div className="profile-menu-item-label">Manage Users</div>
              </Col>
            </Row>
          )
          : ''}
        {user
          ? (
            <Row className="profile-menu-item">
              <Col xs={12} id="profile-menu-logout" className="profile-menu-auth logout" onClick={this.openChangePassword}>
                <div><i className="fas fa-user profile-menu-item-icon" /></div>
                <div className="profile-menu-item-label">Change Password</div>
              </Col>
            </Row>
          )
          : ''}
        {user
          ? (
            <Row className="profile-menu-item">
              <Col xs={12} id="profile-menu-logout" className="profile-menu-auth logout" onClick={this.handleClickLogout}>
                <div><i className="fas fa-sign-in-alt profile-menu-item-icon" /></div>
                <div className="profile-menu-item-label">Logout</div>
              </Col>
            </Row>
          )
          : ''}
        {user
          ? ''
          : (
            <Row className="profile-menu-item">
              <Col xs={12} id="profile-menu-login" className="profile-menu-auth login" onClick={() => this.openAuth('login')}>
                <div><i className="fas fa-sign-in-alt profile-menu-item-icon" /></div>
                <div className="profile-menu-item-label">Login</div>
              </Col>
            </Row>
          )}
        {user
          ? ''
          : (
            <Row className="profile-menu-item">
              <Col xs={12} id="profile-menu-signup" className="profile-menu-auth signup" onClick={() => this.openAuth('signup')}>
                <div><i className="fas fa-user profile-menu-item-icon" /></div>
                <div className="profile-menu-item-label">Sign Up</div>
              </Col>
            </Row>
          )}
      </Container>
    );
  }
}

ProfileDropdown.propTypes = {
  auth: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};

ProfileDropdown.defaultProps = {
  user: null,
};

export default ProfileDropdown;
