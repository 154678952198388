import './AnalysisQueue.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import IncidentTable from '../IncidentTable';

import axios from '../../utils/axios';

function getAnalysisQueue() {
  return new Promise((resolve, reject) => {
    axios.get('https://api.tat.wgtl.io/api/v1/threat/analysis/list')
      .then((res) => {
        if (res.status === 200) {
          return resolve(res.data.threats);
        }
        if (res.status === 404) {
          return resolve([]);
        }
        return reject(new Error('Error searching for threats'));
      })
      .catch(() => reject(new Error('Error searching for threats')));
  });
}

class AnalysisQueue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching: true,
      threats: [],
    };
  }

  componentDidMount() {
    getAnalysisQueue()
      .then((threats) => {
        this.setState({
          isSearching: false,
          threats,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isSearching: false,
        });
      });
  }

  render() {
    const {
      isSearching,
      threats,
    } = this.state;

    const columns = [{
      dataField: '_id',
      hidden: true,
      text: 'id',
    }, {
      dataField: 'name',
      text: 'Name',
      formatter: (cell, row) => (
        <Link className="table-link" to={`/Threat/Malware/${row._id}`}>{cell}</Link>
      ),
    }, {
      dataField: 'firstSeen',
      text: 'First Seen',
    }, {
      dataField: 'lastSeen',
      text: 'Last Seen',
    }, {
      dataField: 'incidentCount',
      text: 'Incidents',
    }, {
      dataField: 'uniqueVictims',
      text: 'Victims',
    }];

    const noTableData = () => {
      if (isSearching) {
        return (<div className="loading">Loading</div>);
      }
      return (<div className="no-data-label">No threats in analysis queue</div>);
    };

    return (

      <div id="AnalysisRecent">
        <Row>
          <Col xs={12} className="search-title">
            <h1>Recent Analyses</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="dashboard-item">
            <div id="AnalysisQueue">
              {isSearching
                ? (
                  <div className="searching"><i className="fas fa-spinner fa-spin searching-spinner" /></div>
                )
                : ''}
              <div>
                <Row>
                  <Col xs={12}>
                    <IncidentTable
                      incidents={threats}
                      columns={columns}
                      keyField="_id"
                      noTableData={noTableData}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AnalysisQueue;
