import './AddUser.css';

import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Col,
  Row,
  Button,
} from 'react-bootstrap';

import axios from '../../../utils/axios';

function registerUser(firstName, lastName, username, email, password) {
  return new Promise((resolve, reject) => {
    const postData = {
      username,
      firstName,
      lastName,
      email,
      password,
    };
    axios.post('https://id.wgtl.io/createuser', postData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            return resolve({ success: true });
          }
          return reject(new Error(res.data.message));
        }
        return reject(new Error('Error creating analysis'));
      })
      .catch((error) => reject(error));
  });
}

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      isSubmitting: false,
      errorMessage: null,
    };
    this.changeUsername = this.changeUsername.bind(this);
    this.changeFirstName = this.changeFirstName.bind(this);
    this.changeLastName = this.changeLastName.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.submitRegistration = this.submitRegistration.bind(this);
  }

  changeUsername(event) {
    this.setState({
      username: event.target.value,
    });
  }

  changeFirstName(event) {
    this.setState({
      firstName: event.target.value,
    });
  }

  changeLastName(event) {
    this.setState({
      lastName: event.target.value,
    });
  }

  changeEmail(event) {
    this.setState({
      email: event.target.value,
    });
  }

  changePassword(event) {
    this.setState({
      password: event.target.value,
    });
  }

  submitRegistration(event) {
    const {
      firstName,
      lastName,
      email,
      password,
      username,
    } = this.state;
    const {
      userCreated,
    } = this.props;
    if (event) {
      event.preventDefault();
    }
    this.setState({
      isSubmitting: true,
      errorMessage: '',
    });
    registerUser(firstName, lastName, username, email, password)
      .then(() => {
        userCreated()
          .then(() => {
            this.setState({
              isSubmitting: false,
              username: '',
              firstName: '',
              lastName: '',
              email: '',
              password: '',
            });
          })
          .catch(() => {
            this.setState({
              isSubmitting: false,
              username: '',
              firstName: '',
              lastName: '',
              email: '',
              password: '',
            });
          });
      })
      .catch((error) => {
        console.log(error.message);
        const { message } = error;
        let errorMessage = message;
        if (errorMessage === 'Request failed with status code 409') {
          errorMessage = 'Email already exists';
        }
        this.setState({
          isSubmitting: false,
          errorMessage,
        });
      });
  }

  render() {
    const {
      isSubmitting,
      username,
      firstName,
      lastName,
      email,
      password,
      errorMessage,
    } = this.state;
    return (
      <div id="AddUser">
        <Row>
          <Col xs={12} className="add-user-section">
            <Form onSubmit={this.submitRegistration}>
              <Form.Row className="align-items-center">
                <Col className="form-col">
                  <Form.Control
                    id="username"
                    name="username"
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={this.changeUsername}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        this.submitRegistration(event);
                      }
                    }}
                  />
                </Col>
                <Col className="form-col">
                  <Form.Control
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={this.changeFirstName}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        this.submitRegistration(event);
                      }
                    }}
                  />
                </Col>
                <Col className="form-col">
                  <Form.Control
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={this.changeLastName}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        this.submitRegistration(event);
                      }
                    }}
                  />
                </Col>
                <Col className="form-col">
                  <Form.Control
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.changeEmail}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        this.submitRegistration(event);
                      }
                    }}
                  />
                </Col>
                <Col className="form-col">
                  <Form.Control
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={this.changePassword}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        this.submitRegistration(event);
                      }
                    }}
                  />
                </Col>
                <Col xs="auto" className="form-col">
                  <Button type="submit" id="submit-analysis-button">Submit</Button>
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="add-user-error-section">
            {isSubmitting
              ? (
                <div className="submit-pending">
                  <i className="fas fa-spinner fa-spin submit-pending-icon" />
                </div>
              )
              : ''}
            {errorMessage
              ? (
                <dic className="error-message">
                  {errorMessage}
                </dic>
              )
              : ''}
          </Col>
        </Row>
      </div>
    );
  }
}

AddUser.propTypes = {
  userCreated: PropTypes.func.isRequired,
};

export default AddUser;
