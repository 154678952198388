import './DisableBreachInstructions.css';

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

function DisableBreachInstructions(props) {
  const {
    isWorking,
    toggleBreach,
  } = props;
  return (
    <div id="DisableBreachInstructions">
      <Row>
        <Col xs={12} className="dashboard-item">
          <h5>Breach Management</h5>
          <p>
            This breach is enabled and searchable within the dark web scan database and
            notifications have been sent. If you need to quickly disable the breach, hit
            the disable button below.
          </p>
          <Button
            disabled={isWorking}
            onClick={toggleBreach}
            type="submit"
            id="toggle-breach"
          >
            Disable
          </Button>
          {isWorking
            ? (
              <div className="submit-pending">
                <i className="fas fa-spinner fa-spin submit-pending-icon" />
              </div>
            )
            : ''}
        </Col>
      </Row>

    </div>
  );
}

DisableBreachInstructions.propTypes = {
  isWorking: PropTypes.bool.isRequired,
  toggleBreach: PropTypes.func.isRequired,
};

export default DisableBreachInstructions;
