import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFoundPage() {
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>404 Not Found</h3>
      <p style={{ textAlign: 'center' }}>
        <Link to="/">Go to Home</Link>
      </p>
    </div>
  );
}
