import { v4 as uuidv4 } from 'uuid';

const jwt = require('jsonwebtoken');

const StorageService = (() => {
  let _service;

  function _getService() {
    if (!_service) {
      _service = this;
      window.localStorage.setItem('REQUESTING_SHARED_CREDENTIALS', Date.now().toString());
      window.localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS');
      window.addEventListener('storage', (event) => {
        const accessToken = _getAccessToken();
        const refreshToken = _getRefreshToken();
        const idToken = _getIdToken();
        const isPersist = _isPersistentAuth();
        const expiration = _getTokenExpiration();
        if (event.key === 'REQUESTING_SHARED_CREDENTIALS' && accessToken) {
          window.localStorage.setItem('CREDENTIALS_SHARING', JSON.stringify({
            tokenObj: {
              access_token: accessToken,
              id_token: idToken,
              refresh_token: refreshToken,
              expires_at: expiration,
            },
            remember_user: isPersist,
          }));
          window.localStorage.removeItem('CREDENTIALS_SHARING');
        }
      });
      return _service;
    }
    return _service;
  }

  function _setTokens(tokenObj, remember) {
    if (tokenObj && tokenObj.access_token && tokenObj.id_token && tokenObj.refresh_token) {
      const expiresAt = JSON.stringify((tokenObj.expires_in * 1000) + new Date().getTime());
      const profile = jwt.decode(tokenObj.id_token);
      sessionStorage.setItem('access_token', tokenObj.access_token);
      sessionStorage.setItem('id_token', tokenObj.id_token);
      sessionStorage.setItem('expires_at', expiresAt);
      sessionStorage.setItem('refresh_token', tokenObj.refresh_token);
      sessionStorage.setItem('user_role', profile.accountType);
      if (remember) {
        localStorage.setItem('access_token', tokenObj.access_token);
        localStorage.setItem('id_token', tokenObj.id_token);
        localStorage.setItem('expires_at', expiresAt);
        localStorage.setItem('refresh_token', tokenObj.refresh_token);
        localStorage.setItem('user_role', profile.accountType);
        localStorage.setItem('remember_user', true);
      }
    }
  }

  function _destroyTokens() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('id_token');
    sessionStorage.removeItem('expires_at');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('user_role');
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user_role');
    localStorage.removeItem('remember_user');
  }

  function _getAccessToken() {
    let accessToken = sessionStorage.getItem('access_token');
    if (!accessToken) {
      accessToken = localStorage.getItem('access_token');
    }
    return accessToken;
  }

  function _getRefreshToken() {
    let refreshToken = sessionStorage.getItem('refresh_token');
    if (!refreshToken) {
      refreshToken = localStorage.getItem('refresh_token');
    }
    return refreshToken;
  }

  function _getIdToken() {
    return sessionStorage.getItem('id_token');
  }

  function _isPersistentAuth() {
    return (localStorage.getItem('remember_user') === 'true');
  }

  function _getTokenExpiration() {
    let expiration = sessionStorage.getItem('expires_at');
    if (!expiration) {
      expiration = localStorage.getItem('expires_at');
    }
    return expiration;
  }

  function _getBrowserUUID() {
    let browserUUID = localStorage.getItem('browser_uuid');
    if (!browserUUID) {
      browserUUID = uuidv4();
      localStorage.setItem('browser_uuid', browserUUID);
    }
    return browserUUID;
  }

  function _getUserRole() {
    let role = sessionStorage.getItem('user_role');
    if (!role) {
      role = localStorage.getItem('user_role');
    }
    return role;
  }

  return {
    getService: _getService,
    getBrowserUUID: _getBrowserUUID,
    setTokens: _setTokens,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    getIdToken: _getIdToken,
    destroyTokens: _destroyTokens,
    isPersistentAuth: _isPersistentAuth,
    getUserRole: _getUserRole,
  };
})();
export default StorageService;
