import './Queue.css';

import React from 'react';
import { Row, Col } from 'react-bootstrap';

import AnalysisStatBlock from '../AnalysisStatBlock';
import AnalysisQueue from '../AnalysisQueue';

function Queue() {
  return (
    <div id="Queue">
      <Row>
        <Col xs={12} className="search-title">
          <h1>Threat Analysis Queue</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="dashboard-item">
          <AnalysisStatBlock />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="dashboard-item">
          <AnalysisQueue />
        </Col>
      </Row>
    </div>
  );
}

export default Queue;
