import axios from 'axios';
import StorageService from './StorageService';

const storageService = StorageService.getService();
const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = storageService.getAccessToken();
    if (token) {
      config.headers['x-auth'] = token;
    }
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use((response) => response, (error) => {
  const originalRequest = error.config;

  if (error.response.status === 401
      && originalRequest.url === 'https://id.wgtl.io/refresh'
  ) {
    return Promise.reject(error);
  }
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshToken = storageService.getRefreshToken();
    if (!refreshToken) {
      return Promise.reject(new Error('Not authenticated'));
    }
    const browserUUID = storageService.getBrowserUUID();
    const postData = {
      refresh_token: refreshToken,
      uuid: browserUUID,
    };
    return axios.post('https://id.wgtl.io/refresh', postData)
      .then((res) => {
        if (res.status === 200) {
          storageService.setTokens(res.data, storageService.isPersistentAuth());
          axios.defaults.headers['x-auth'] = res.data.access_token;
          originalRequest.headers['x-auth'] = res.data.access_token;
          return axios(originalRequest);
        }
        storageService.destroyTokens();
        return Promise.reject(new Error('Error refreshing authentication'));
      })
      .catch((refreshError) => {
        storageService.destroyTokens();
        return Promise.reject(refreshError);
      });
  }
  return Promise.reject(error);
});

export default axiosInstance;
