import './EnableBreachInstructions.css';

import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

function EnableBreachInstructions(props) {
  const {
    isWorking,
    toggleBreach,
  } = props;
  return (
    <div id="EnableBreachInstructions">
      <Row>
        <Col xs={12} className="dashboard-item">
          <h5>Next Steps: Verify and Enable the Breach</h5>
          <p>
            The breach data has finished processing. Next, verify the breach information below is
            correct and get a sign off from someone else. After verifying the information, you can
            hit the enable button below to enable the breach for searching.
          </p>
          <Button
            disabled={isWorking}
            onClick={toggleBreach}
            type="submit"
            id="toggle-breach"
          >
            Enable
          </Button>
          {isWorking
            ? (
              <div className="submit-pending">
                <i className="fas fa-spinner fa-spin submit-pending-icon" />
              </div>
            )
            : ''}
        </Col>
      </Row>

    </div>
  );
}

EnableBreachInstructions.propTypes = {
  isWorking: PropTypes.bool.isRequired,
  toggleBreach: PropTypes.func.isRequired,
};

export default EnableBreachInstructions;
