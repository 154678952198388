import './ConfirmOverlay.css';

import * as React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

function ConfirmOverlay(props) {
  const {
    accept,
    reject,
    title,
    message,
  } = props;

  function acceptButton(e) {
    e.preventDefault();
    accept();
  }

  function rejectButton(e) {
    e.preventDefault();
    reject();
  }

  return (
    <div id="ConfirmOverlay">
      <div id="overlay-content">
        <div className="confirm-box">
          <h3 className="confirm-title">
            {title}
          </h3>
          <div className="confirm-message">
            {message}
          </div>
          <div className="confirm-controls">
            <Button onClick={acceptButton()}>Accept</Button>
            <Button onClick={rejectButton()}>Cancel</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmOverlay.propTypes = {
  accept: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default ConfirmOverlay;
